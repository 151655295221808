import { Box, Typography} from '@mui/material';
import { useEffect, useRef } from 'react';
import './text.css'

export default function Text({text, setText, placeholder, type, fixed}) {
    
    const refTextarea = useRef()
    useEffect(() => {
        if(refTextarea && !fixed){
            const {scrollHeight, style} = refTextarea.current
            style.height = scrollHeight+"px"
        }
    }, [refTextarea, fixed])
    
    useEffect(() => {
        if(refTextarea && refTextarea.current.value !== ""){
            auto_grow(refTextarea.current)
        }
    }, [refTextarea, text])

    const changeHandle = ({target}) => {
        auto_grow(target)
        setText(target.value)
    }

    function auto_grow(target) {
        target.style.height = "5px";
        target.style.height = (target.scrollHeight)+"px";
    }

    return (
        <Box className="text flex col center w100">
            {fixed?
                <Typography 
                    variant="h2" 
                    component="h1" 
                    className={`tbold textFixed fontType_${type} w100`}>
                    {text}
                </Typography>
            :
                <textarea
                    className={`text_input fontType_${type} flex col center w100`}
                    placeholder={placeholder}
                    value={text}
                    // multiline={true}
                    ref={refTextarea}
                    onLoadedData={changeHandle}
                    onChange={changeHandle} 
                    style={{height:72}}       
                />
            }
           
        </Box>  
    );
}
import { Box, Typography } from '@mui/material';
import './findAJob.css'
import HeaderImage from '../../../components/headerImage/HeaderImage';
import BreadcrumbsCustomized from '../../../components/breadcrumbsCustomized/BreadcrumbsCustomized';
import SectionElevation from '../../../components/sectionElevation/SectionElevation';
import SectionElevationFindAJob from '../../../components/sectionElevationFindAJob/SectionElevationFindAJob';
import { useContext, useEffect, useState } from 'react';
import JobsCustom from '../../../components/jobsCustom/JobsCustom';
import JobsByLocation from '../../../components/jobsByLocation/JobsByLocation';
import Context from '../../../contexts/Context';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import JobsBySection from '../../../components/jobsBySection/JobsBySection';
import {Fade} from 'react-awesome-reveal';
import Jobs from '../jobs/Jobs';
import Job from '../job/Job';
import { clone } from '../../../services/serviceslocal';
import SectionImage from '../../../components/sectionImage/SectionImage';
import SectionOnlytext from '../../../components/sectionOnlytext/SectionOnlytext';
import SectionContactUs from '../../../components/sectionContactUs/SectionContactUs';
import Preloader from '../../../components/preloader/Preloader';


export default function FindAJob() {
    
    let navigate = useNavigate ();
    // const  {pathname} = useLocation()

    // useEffect(() => {
    //     if(pathname === "/jobs"){
    //         return(navigate('/find-a-job'))
    //     }
    //    if(pathname.startsWith("/jobs/")){
    //         return(navigate(pathname.replace('/jobs/', '/find-a-job/jobs/')))
    //     }
    //     console.log(pathname)
    // }, [pathname])
    
    const {siteContents, allJobs, onPathname} = useContext (Context)
    let {data} = siteContents.find(item => item.id === "find-a-job")
    const bannerSimple = data.find(item => item.type === "bannerSimple")  
    const dataSectionJob = data.filter(item => item.type === "sectionFindAJob")  

    const {data: dataContacUs} = siteContents.find(item => item.id === "contact-us")
    let contactFindAJob = []

    const contactSocialMedia = clone(dataContacUs.find(item => item.id === "contactSocialMedia"))
    contactSocialMedia.backgroundColor = "White"
    contactFindAJob = [...contactFindAJob, contactSocialMedia]

    const contactForTalent = clone(dataContacUs.find(item => item.id === "contactForTalent"))
    contactForTalent.backgroundColor = "Gray"
    contactFindAJob = [...contactFindAJob, contactForTalent]



    const [currentCategory, setCurrentCategory] = useState()
    const {jobs, job} = useParams() 

    const [currentPage, setCurrentPage] = useState(1)
    const [idArrayPagination, setIdArrayPagination] = useState()

    useEffect(() => {
        if(idArrayPagination){
            setCurrentPage(1)
        }
    }, [idArrayPagination])
    
    const selectItem = (value) =>{
        let path = onPathname === "/find-a-job" || onPathname === "/find-a-job/"?"jobs":onPathname
        let item1 = currentCategory === 1? "industry":currentCategory === 2?"locations":"division"
        navigate(`${path}?${item1}=${value.toLowerCase()}`)
    }

    return (
        <Box className='minhCont'>
            <Preloader showPreloader={allJobs.length === 0 && (job !== undefined || jobs !== undefined)} />
            {
                job?
                    <Job arrayJobs={allJobs}/>
                :
                jobs?
                    <Jobs 
                        arrayJobs={allJobs} 
                        currentPage={currentPage} 
                        setCurrentPage={setCurrentPage} 
                        setIdArrayPagination={setIdArrayPagination}
                    />
                :
                <Box className=" flex col center w100 minh100">
                    <BreadcrumbsCustomized description={dataSectionJob[0].body?.replace( /(<([^>]+)>)/ig, '')}/>
                    <HeaderImage 
                        // br={false}
                        background="primary"
                        animate
                        fixed
                        image={bannerSimple.media}                 
                        >
                        <Typography variant="h2" component="h1" className="tbold tcenter">
                            {bannerSimple.title}
                        </Typography>
                    </HeaderImage>
                    <SectionElevation >
                        <SectionElevationFindAJob onCategory={setCurrentCategory} currentCategory={currentCategory} dataSectionJob={dataSectionJob}/>
                    </SectionElevation>
                        <Box className={`sectionImage section flex col center w100`} sx={{background:"var(--primary)", mt:"-136px"}}>
                            {currentCategory !== undefined &&
                                <Box className={`sectionImage flex col w100 mw900 aicenter`} sx={{pt:"136px", pb:5}}>
                                    <Box sx={{p:2}}>
                                        <Typography variant="h3" gutterBottom component="h1" className="w100 tbold tl" color="text.white" >
                                            {dataSectionJob[currentCategory].title}
                                        </Typography>
                                        <Typography variant='subtitle1' className="w100 tbold tl" color="text.white"  dangerouslySetInnerHTML={ {__html: dataSectionJob[currentCategory].body}}>
                                        </Typography>
                                    </Box>
                                    {currentCategory === 0 &&
                                            <SectionElevation mw="mw900" relative>
                                                <JobsCustom arrayJobs={allJobs}/>
                                            </SectionElevation> 
                                    }
                                    {currentCategory === 1 &&
                                        <Fade>
                                            <JobsBySection arrayJobs={allJobs} fieldName={'TR1__Industry__c'} selectItem={selectItem}/> 
                                        </Fade>
                                    }
                                    {currentCategory === 2 &&
                                        <Fade>
                                            <JobsByLocation arrayJobs={allJobs} selectItem={selectItem}/> 
                                        </Fade>
                                    }
                                    {currentCategory === 3 &&
                                        <Fade>
                                            <JobsBySection arrayJobs={allJobs} fieldName={'TR1__Division__c'} selectItem={selectItem}/> 
                                        </Fade>                    
                                    }
                                </Box>
                            }   
                        </Box> 
                        {
                            contactFindAJob.map((item, index) => {
                                let {id, type} = item
                                return(
                                    type === "section" ?
                                        <SectionOnlytext key={id} data={item} /> 
                                    :type === "sectionImage"?
                                        <SectionImage key={id} data={item}/>
                                    :type === "sectionContact" &&
                                        <SectionContactUs key={id} data={item}/>
                                        )
                                    })
                        }
                </Box>
            }
        </Box>
    );
}


import { useContext, useState } from 'react';
import { Backdrop, Box, Button, IconButton, Typography } from '@mui/material';
import './article.css'
import HeaderImage from '../headerImage/HeaderImage'
import SourcesResources from '../sourcesResources/SourcesResources';
import PostArticleBy from '../postArticleBy/PostArticleBy';
import ImageSimple from '../imageSimple/ImageSimple';
import Iframe from '../iframe/Iframe';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { dateObjectToString, dateStringToObject, urlToString } from '../../services/serviceslocal';
import Context from '../../contexts/Context';
import RichEditor from '../richEditor/RichEditor';
import { ShareSharp } from '@mui/icons-material';
import Preloader from '../preloader/Preloader';

export default function Article({item, fixed=true, onCurrentArticle}) {

    const {allResources} = useContext(Context)
    const [currentAticle, setCurrentAticle] = useState(item?item:{title:"", media:false, textBody:[], sources:[], postBy:{Id:undefined, type:"Written"}})
    const {title, media, textBody, sources, postBy, fullDate} = currentAticle
    const {article} = useParams()
    let navigate = useNavigate();
    // console.log(currentAticle)
    useEffect(() => {
        if(!item && allResources.length !== 0){
             const param = urlToString(article).toLowerCase()
            // const found = allResources.find(article => article.title.toLowerCase() === param)
            const found = allResources.find(article => article.title.toLowerCase() === param || article.title.toLowerCase().includes(param))
            if(found){
                // console.log(found)
                setCurrentAticle(found)
                if(onCurrentArticle){
                    onCurrentArticle(found)
                }
            }else{
                // alert("no match")
                navigate("../resources")
            }
        }
    }, [article, item, onCurrentArticle, allResources, navigate])


    const share = () =>{
        navigator.share({
            title: `${title} | ASAP Staffing Services Miami`, 
            url: window.location.href, 
            text: `${title} | ASAP Staffing Services Miami`, 
        })
    }
    
    return (
       title === ""?
       <Box style={{minHeight:"100vh"}}>
            <Preloader showPreloader={title === ""} />
       </Box>
    :
    // title !== "" &&
        <Box className="article flex col aicenter w100 bodyContent minhCont">
            <HeaderImage
                image={media}
                fixed={fixed}
                animate
                isArticle
            >
                <Typography variant="h2" component="h1" className="tbold tcenter mw1000">
                {title} 
                </Typography>
            </HeaderImage>

            <Box p={2} className="w100 mw1000">
                
                <Typography  color="primary" paragraph className="textarea tj">
                    {fullDate && dateObjectToString(dateStringToObject(fullDate))} 
                </Typography>
                
                {textBody?.map((item) => {
                    return(
                        item.type === "text"? 
                        <RichEditor
                            key={item.id} 
                            item={item} 
                            textBody={textBody}
                            fixed={fixed}
                        />
                        :item.type === "image"? 
                            <ImageSimple
                                key={item.id} 
                                item={item} 
                                textBody={textBody}
                                fixed={fixed}
                            />
                        :
                            <Iframe
                                key={item.id} 
                                item={item} 
                                textBody={textBody}
                                fixed={fixed}
                            />
                    )})
                }
            </Box> 

            <SourcesResources 
                sources={sources}
                fixed={fixed}
            />
{/* 
            <Box className="flex col aicenter mw1000 w100 flex center wrapper-share-article-responsive" sx={{pb:'16px'}}>
                <Button
                    onClick={() =>share()}
                    variant='text'
                    sx={{my:1, textTransform:"none", borderRadius:"50px", px:4}} 
                    startIcon={<ShareSharp/>}
                >
                    Share
                </Button>
            </Box> */}

            <Box className="flex col aicenter w100" style={{background:"var(--gray)", position:"relative"}}>
                
                <Box className="flex col aicenter mw1000 w100 wrapper-share-article flex center">
                    <IconButton size='large' onClick={() =>share()} className="share-article" title="Share">
                        <ShareSharp color='primary'/>
                    </IconButton>
                </Box>

                <PostArticleBy 
                    postBy={postBy}
                    fixed={fixed}
                />
            </Box>
        </Box>
    );
}
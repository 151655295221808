import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import './modalAlert.css'
import { forwardRef } from 'react';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalAlert({modalActive, setModalActive, children, title, onClick, textCancel, textAction="OK", closeExternal, fullScreen, sx, className="", maxWidth, dialogActions}) {
  
    const handleClose = () => {
        setModalActive(false);
        if(onClick){
        onClick()
        }
    };
  
    const noClose = () => {
    };

    return (
        <div>
            <Dialog
                onClose={closeExternal?handleClose:noClose}
                className={className}
                fullScreen={fullScreen}
                name="modalAlert"
                open={modalActive}
                TransitionComponent={Transition}
                fullWidth={true}
                aria-describedby="alert-dialog-slide-description"
                maxWidth={maxWidth}
            >
                <DialogTitle>{title}</DialogTitle>
                <DialogContent className="flex center" sx={sx}>
                    {children && children}
                </DialogContent>
                {dialogActions?
                    dialogActions
                :
                    <DialogActions>
                        {textCancel && <Button color="secondary" onClick={()=>setModalActive(false)}>{textCancel}</Button>}
                        <Button onClick={handleClose}>{textAction}</Button> 
                    </DialogActions>
                }   
            </Dialog>
        </div>
    );
}
import { useRef, useState } from 'react';
import { Box, Button, Checkbox, Typography } from '@mui/material';
import './siteContentsComponents.css'
import { fileToBase64 } from '../../services/serviceslocal';
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import { useEffect } from 'react';

export default function MediaEditor({data}) {
    
    // image & video
    const inputImageRef = useRef()
    const [hasMedia, setHasMedia] = useState(data?data.media.active:false)
    const [mediaType, setMediaType] = useState(data?data.media.type:"")
    const [mediaUrl, setMediaUrl] = useState(data?data.media.url:"")
    

    useEffect(() => {
        data.media.active = hasMedia
        data.media.type = mediaType
        data.media.url = mediaUrl
    }, [data, hasMedia, mediaType, mediaUrl])
    
    const imageHandler = ({target}) => {
        if(target.files.length > 0 ){
            let file = target.files[0]       
            if(file){
                if(file.type.includes("image")){
                    setMediaType("image")
                    return(fileToBase64(file).then(base64 => (setMediaUrl(base64)))) 
                }
                if(file.type.includes("video")){
                    setMediaType("video")
                    return(fileToBase64(file).then(base64 => (setMediaUrl(base64)))) 
                }
            return
            } 
            alert("Incompatible file format") 
            setMediaType("")
            setHasMedia(false)
        }
    } 


    return (
        <Box className="siteContents-item flex col w100 aicenter" sx={{py:0.5}}>
            <Box className="flex row w100 aicenter">
                <Typography variant="body2" component="span" sx={{mr:1}} className="Montserrat tbold">
                    {data.type === "sectionImage" || data.type === "section" || data.type === "sectionContact"?"Image":"Background image"} 
                </Typography>
                <Checkbox sx={{p:0}} icon={<CheckBoxOutlineBlank fontSize='small'/>} checkedIcon={<CheckBox fontSize='small'/>} checked={hasMedia} onChange={(e, value)=>{setHasMedia(value); if(!value){setMediaUrl(""); setMediaType("")}}} />
            </Box>
            {hasMedia &&
                <Box className={`${data.type === "sectionImage" || data.type === "sectionContact"?"containerImage":""} flex row w100 jccenter`}>
                    <Box className="flex col jccenter w100">
                        
                        {(data.type !== "sectionImage" && data.type !== "sectionContact") &&
                            <Button sx={{p:0, minHeight:150}} onClick={() =>{inputImageRef.current.click()}}>
                                {!mediaType &&  
                                    <Typography variant="body2" component="span" sx={{mr:1}} className="Montserrat tbold">
                                        Add Multimedia
                                    </Typography>
                                }
                                {mediaType ==="image" && <img className="siteContents-bannerImage" src={mediaUrl} alt={"sectionImage"}  width="640" height="360"/>}
                                {mediaType ==="video" && <video className="siteContents-bannerVideo" src={mediaUrl} alt={"sectionImage"}  width="640" height="360" controls/>}
                            </Button>
                        }
                         
                        {(data.type === "sectionImage" || data.type === "sectionContact") && 
                            <Button sx={{display:"contents"}}  onClick={() =>{inputImageRef.current.click()}}>                           
                                {mediaType ==="image" && <img className="containerImage_image" src={mediaUrl} alt={"sectionImage"}  width="640" height="360"/>}
                                {mediaType ==="video" && 
                                    <Box className="containerImage_image flex center">
                                        {/* <video src={mediaUrl} alt={"sectionImage"} width="640" height="360" style={{aspectRatio:"inherit"}} controls controlsList='nodownload'/> */}
                                        {/* <video src={mediaUrl} alt={"sectionImage"} width="640" height="360" style={{width:"auto", height:"100%"}} controls controlsList='nodownload'/> */}
                                        <video src={mediaUrl} alt={"sectionImage"} width="640" height="360" style={{width:"auto", height:"100%"}} loop autoPlay muted playsInline/>
                                    </Box>
                                }
                                <Box className="containerImage_background flex center">
                                    {!mediaType &&  
                                        <Typography variant="body2" component="span" sx={{mr:1, zIndex:1, color:"var(--white)"}} className="Montserrat tbold">
                                            Add Multimedia
                                        </Typography>
                                    }
                                </Box>
                            </Button>
                        }
                        <input
                            type="file"
                            ref={inputImageRef}
                            onChange={imageHandler} 
                            style={{display:"contents"}}
                        />
                    </Box>
                </Box>
            }
        </Box> 
    );
}
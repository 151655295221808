import { useContext } from 'react';
import { Box, Typography } from '@mui/material';
import './contactUs.css'
import HeaderImage from '../../../components/headerImage/HeaderImage';
import BreadcrumbsCustomized from '../../../components/breadcrumbsCustomized/BreadcrumbsCustomized';
import Context from '../../../contexts/Context';
import { orderArray } from '../../../services/serviceslocal';
import SectionOnlytext from '../../../components/sectionOnlytext/SectionOnlytext';
import SectionContactUs from '../../../components/sectionContactUs/SectionContactUs';

export default function ContactUs() {

    const {siteContents} = useContext (Context)

    const {data} = siteContents.find(item => item.id === "contact-us")

    return (
        <Box className=" flex col center w100 minh100">
            <BreadcrumbsCustomized />
            {
                orderArray(data, "position").map(item => {
                    let {id, type} = item
                    if(type === "sectionContact"){
                        let prevBackgroundColor = data.find(prevItem => prevItem.position === item.position - 1).backgroundColor
                        item.backgroundColor = prevBackgroundColor === "White"?"Gray":"White"
                    }
                    return(
                        type === "bannerSimple"?
                            <HeaderImage key={id}
                                animate
                                fixed
                                image={item.media}                
                                >
                                <Typography variant="h2" component="h1" className="tbold tcenter">
                                    {item.title}
                                </Typography>
                            </HeaderImage>
                        :type === "section"?
                            <SectionOnlytext key={id} data={item} /> 
                        :type === "sectionContact" &&
                            <SectionContactUs key={id} data={item} contactUs/>
                    )
                })
            }   
        </Box>
    );
}


import { useEffect, useRef, useState } from 'react';
import { Box, IconButton } from '@mui/material';
import './iframe.css'
import { Close} from '@mui/icons-material';

export default function Iframe({item, setTextBody, textBody, fixed}) {
    
    const iframeRef = useRef()
    const {id, body} = item
    const [inFocus, setInFocus] = useState(false)
    const [frame, setFrame] = useState()
    
    useEffect(() => {
        if(!fixed && !frame && !body){
            setFrame(prompt("Ingresa el Iframe que desea agregar", `example: <iframe src="url" title="description"></iframe>`))
        }
    }, [frame, fixed, body])


    const handleDelete = () => {
        setTextBody( prevState => {
            const result = prevState.filter(item => item.id !== id);
            return (result)
          })    
    }

    useEffect(() => {
        if(!fixed && iframeRef && body){
            iframeRef.current.innerHTML = body
            addFrame(body.replaceAll(`"`, `'`))
        }
    }, [iframeRef, body, fixed]);
    

    useEffect(() => {
        if(!fixed){

            if(frame === null || frame === `example: <iframe src="url" title="description"></iframe>`){
                return(handleDelete())
            }
            if(frame){
                iframeRef.current.innerHTML = frame
                addFrame(frame.replaceAll(`"`, `'`))
                // getDimentions()
            }
        }
    }, [frame, fixed])

    const addFrame = (frame) => {
        const result = textBody.filter(item => item.id === id);
        result[0].body = frame
    }

    
    return (
        <Box
            sx={{ py: 1}}
            onFocus={()=>{setInFocus(true)}} 
            onMouseEnter={() => setInFocus(true)}       
            onClick={() => setInFocus(true)}       
            onBlur={()=>{setInFocus(false)}}        
            onMouseLeave={() => setInFocus(false)}   
            className={`iframe flex row center ${!fixed && inFocus?"imageSimple_hover":""}`}   
        >
            <Box ref={iframeRef} style={{display:"contents"}}/>
            
            {!fixed  &&
                <Box className="closeIframe flex col">
                    <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleDelete}
                    >
                        <Close />
                    </IconButton>
                </Box>
            }
        </Box>  
    );
}
import { useContext } from 'react';
import { NotificationAdd} from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import './newsletters.css'
import ButtonAction from '../buttonAction/ButtonAction';
import Context from '../../contexts/Context';

export default function Newsletters() {

    const {siteContents} = useContext(Context)
    const {data} = siteContents.find(item => item.id === "miscellaneous")
    const newsletters = data.find(item => item.id === "newsletters")

    return(
        <Box className= "100 newsletters">
            <Box borderBottom={1} sx={{mb:1}}>
                <Typography variant="h5" component="h2">
                    {newsletters.title} 
                </Typography>
            </Box>
            <Box>
                <Typography sx={{mb:2}} variant="body2" component="p" className="w100 tj"  dangerouslySetInnerHTML={ {__html: newsletters.body}}/>
                <ButtonAction
                    color="white" 
                    variant="contained" 
                    startIcon={<NotificationAdd color="primary"/>} 
                    sx={{borderRadius:"50px", mx:"auto"}}
                    action={newsletters.button.action}
                >
                    <Typography sx={{color:"var(--primary)", textTransform:"initial" }} variant="body2" component="span" className="tbold">
                        {newsletters.button.title}
                    </Typography>
                </ButtonAction>
            </Box> 
        </Box>
    )
}
import {Box, Button, IconButton} from '@mui/material';
import './headerImage.css'
import sources from '../../assets/images/sources.jpg'
import asapDefault from '../../assets/images/asap-default.png'
import { Add, DeleteSweep } from '@mui/icons-material';
import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { fileToBase64 } from '../../services/serviceslocal';


export default function HeaderImage({br = true, children, image, setImage, fixed, animate, background="white", isArticle}) {
    const {pathname} = useLocation()
    const inputImageRef = useRef()
    const imageRef = useRef()
    const containerImageRef = useRef()
    const [animationStart, setAnimationStart] = useState(false)
    const [mediaLoading, setMediaLoading] = useState(true)

    useEffect(() => {
        if(animate){
            setAnimationStart(false)
            setTimeout(() => {
                setAnimationStart(true)
                // console.log("start animation")
            }, 100);
        }
    }, [pathname, animate]);

 

    const imageHandler = ({target}) => {
        if(target.files.length > 0 ){
            let file = target.files[0]       
            if(file){
                if(file.type.includes("image")){
                    fileToBase64(file).then(base64 => (setImage({url:base64, type:"image"})))
                    return 
                }
                if(file.type.includes("video")){
                    fileToBase64(file).then(base64 => (setImage({url:base64, type:"video"}))) 
                    return
                }
            return
            } 
            alert("Incompatible file format") 
            setImage(false)
        }
    }


    return (
        <Box className="headerImage flex col center mwmax" sx={{background:`var(--${background})`}} >
            <Box className={`headerImage flex col center w100 ${br?"headerImageBorderRadius":""} ${animate?"headerImageAnimate":""} ${animationStart?"animationStart":""}`} ref={containerImageRef}>
                <Box className={`containerHeaderImage flex col center ${mediaLoading?"containerHeaderImage-Loading":""}`}>
                    {!image && <img alt="banner" src={isArticle?sources:asapDefault} ref={imageRef}/> }
                    {image?.type === "image" &&
                        <img 
                            decoding="async"
                            ref={imageRef} 
                            src={image.url} 
                            alt="banner" 
                            width="640"
                            height="360"
                            onLoad={({isTrusted}) => setMediaLoading(!isTrusted)}
                            onError={({isTrusted})=> setMediaLoading(isTrusted)}
                        />
                    }
                    {image?.type === "video" && 
                        <video 
                            src={image.url} 
                            ref={imageRef} 
                            alt="banner"
                            width="640"
                            height="360"
                            autoPlay
                            loop
                            muted
                            preload="metadata"
                            playsInline
                            onLoadedData={({isTrusted}) => setMediaLoading(!isTrusted)}
                            onError={({isTrusted})=> setMediaLoading(isTrusted)}
                        />
                    }
                </Box>

                <Box className="btnChangeImage">
                    {!fixed && 
                        <Button 
                            variant="text" 
                            color="white" 
                            startIcon={<Add />} 
                            onClick={() =>{inputImageRef.current.click()}}
                        >
                            Add Media
                        </Button>
                    }  
                    <input 
                        ref={inputImageRef}
                        style={{display:"contents"}}
                        type="file" 
                        onChange={imageHandler} 
                    />
                </Box>
                {!fixed && 
                    <Box className="btnDeleteImage">
                        <IconButton  
                            color="white" 
                            onClick={() => {setImage(false)}}
                        >
                            <DeleteSweep/>
                        </IconButton>
                    </Box>
                }  
                <Box className="headerText flex col center w100">
                    {children}
                </Box>
            </Box>
        </Box>
    );
}
import { Article, ChromeReaderMode } from '@mui/icons-material';
import { Box, Button, ButtonGroup, useMediaQuery, Typography} from '@mui/material';
import ModalAlert from '../modalAlert/ModalAlert';
import uuid from 'react-uuid'

export default function ModalTypeSection({modalActive, setModalActive, data}) {

    const matches = useMediaQuery("(min-width:600px)");

    const emptySection = {
        "id":uuid(),
        "position": data.data.length + 1,
        "type": "section",
        "showTitle":true,
        "title": `Section ${data.data.length + 1}`,
        "body": "",
        "button": {
            "active": false,
            "title": "",
            "action": ""
        },
        "backgroundColor": "Auto",
        "media": {
            "active": false,
            "type": "",
            "url": ""
        }
    }

    const handleClick = (value) => {
        // console.log(data.data.length)
        emptySection.type = value
        // data.data[emptySection.title] = emptySection
        data.data.push(emptySection)
        // console.log(data.data)
        setModalActive(false)
    }
    const options = [
        {text:"Text only", icon: <Article color="primary" fontSize="large" sx={{m:0.5}}/>, type:"section"},
        {text:"Text with media", icon: <ChromeReaderMode color="primary" fontSize="large" sx={{m:0.5}}/>, type:"sectionImage"},
    ]

return(
    <ModalAlert modalActive={modalActive} setModalActive={setModalActive} title="Select type Section" textAction="Cancel">
        <ButtonGroup orientation={`${matches ? `horizontal` : `vertical`}`} variant="text" aria-label="text button group" color="secondary" fullWidth>       
            {options.map(item => {
                return(
                    <Button key={item.text} onClick={()=>handleClick(item.type)} color="secondary" className="flex col center w100"  sx={{padding:2, height:"auto", borderRadius:4 }}>
                        <Box sx={{mt:"auto"}}>
                            {item.icon}
                        </Box>
                        <Typography paragraph className="tcenter" variant="body2" component="h2" color="dark">
                            {item.text}
                        </Typography>
                    </Button>
                )
                }) 
            }
        </ButtonGroup>
  </ModalAlert>
)
}
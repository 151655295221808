import { useContext, useState } from 'react';
import { AppBar, Box, Toolbar, Button, IconButton } from '@mui/material';
import { Close, Menu } from '@mui/icons-material';
import logoAsap from '../../assets/images/logoAsap.png'
import { NavLink } from 'react-router-dom';
import './navbar.css'

import NavBarSubMenu from '../navBarSubMenu/NavBarSubMenu';
import Context from '../../contexts/Context';
import NavBarSubMenuUser from '../navBarSubMenuUser/NavBarSubMenuUser';
import { orderArray } from '../../services/serviceslocal';

export default function NavBar({onPathname}) {

    const {typeClient, siteContents} = useContext(Context)
    
    let services = siteContents.find(item => item.id === "services")
    let arrayServices = orderArray(services.data.filter(item => item.type === "service"), "position")

    let aboutUs = siteContents.find(item => item.id === "about-us")
    let arrayAboutUs = aboutUs.data.filter(item => item.type === "sectionImage")
    
    const isPathname = (word) => {
        if(onPathname){
            return(onPathname.toLowerCase().includes(word))
        }
    }

    const [navBarActive, setNavBarActive] = useState(false)

    const toggleMenu = () => {
        setNavBarActive(!navBarActive)
    }
    const closeMenu = () => {
        setNavBarActive(false)
    }

    // const [modalActive, setModalActive] = useState(false)
    
    return (
        <Box sx={{ flexGrow: 1}} className={`navBar ${navBarActive?"navBarActive":""}`}>
            <AppBar  enableColorOnDark={true} position="fixed" color="white" sx={{py:0.5, display:"flex", flexDirection:"row", justifyContent:"center"}}>
                <Toolbar variant="dense" sx={{width:"100%", maxWidth:"2450px"}}>
                    <Box style={{flexGrow: 1}} className="flex">
                        <NavLink to={`/${typeClient}`}>
                            <Box className="flex col center" sx={{width:"max-content"}}>
                                <img 
                                    alt="ASAP Staffing Services"
                                    src={logoAsap} 
                                    height="50"
                                    width="142"
                                    className='logoAsap noSelect'
                                />
                            </Box>
                        </NavLink>
                    </Box>
                    <Box className="main_items flex row">
                        <NavLink to={`/${typeClient}`} className="main_items_home">
                            <Button name="home" disableFocusRipple={true} className="main_items_home_button" color={onPathname === "/" || onPathname === `/${typeClient}`?"primary":"inherit"} onClick={closeMenu}>
                                Home
                            </Button>
                        </NavLink>

                        <NavLink to="/find-a-job">
                            <Button disableFocusRipple={true} color={isPathname("/find-a-job")?"primary":"inherit"} onClick={closeMenu}>
                                Find a Job
                            </Button>
                        </NavLink>

                        <NavBarSubMenu 
                            array={arrayServices} 
                            fullData={services}
                            color={isPathname("/services")?"primary":"inherit"} 
                            closeMenu={closeMenu} 
                            navBarActive={navBarActive}
                        />

                        <NavBarSubMenu 
                            array={arrayAboutUs}
                            fullData={aboutUs}
                            color={isPathname("/about-us")?"primary":"inherit"} 
                            closeMenu={closeMenu} 
                            navBarActive={navBarActive}
                        />

                        <NavLink to="/resources">
                            <Button disableFocusRipple={true} color={isPathname("/resources")?"primary":"inherit"} onClick={closeMenu}>
                                Resources
                            </Button>
                        </NavLink>
                        
                        <NavLink to="/contact-us">
                            <Button color={isPathname("/contact-us")?"primary":"inherit"} onClick={closeMenu}>
                                Contact Us
                            </Button>
                        </NavLink>

                        <NavBarSubMenuUser
                            color={isPathname("/cms") || isPathname("/admin")?"primary":"inherit"} 
                            closeMenu={closeMenu} 
                            navBarActive={navBarActive}
                        />

                        {/* <Button onClick={()=>{closeMenu(); setModalActive(true)}} sx={{minWidth:"initial"}}  color="inherit">
                            <AccountCircle color="secondary"/>
                        </Button> */}
                    </Box>
                    
                    <Box className='main_btn flex row' >
                        <IconButton
                            onClick={toggleMenu}
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ ml: 2 }}
                        >
                            {navBarActive?<Close/>:<Menu /> }
                        </IconButton>
                    </Box>
                </Toolbar>
            </AppBar>
            {/* <ModalSesion modalActive={modalActive} setModalActive={setModalActive}/> */}
        </Box>
    );
}


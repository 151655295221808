import { useContext, useEffect, useRef, useState } from 'react';
import './siteContentsComponents.css'
import { Box, Typography } from '@mui/material';
import Card from '../card/Card';
import RichEditorSiteContents from './richEditorSiteContents/RichEditorSiteContents';
import ButtonEditor from './ButtonEditor';
import MediaEditor from './MediaEditor';
import TitleEditor from './TitleEditor';
import SubtitleEditor from './subtitleEditor';

export default function TermsEditor({data, dataParent, setTitleParent}) {

    return (
        <Card className="flex col center">
            <Box className="siteContents-cardSection flex w100 aicenter">
                {/* <TitleEditor data={data} required/> */}
                <TitleEditor data={data} required isBannerService={true} dataGrandpa={dataParent} setTitleGrandpa={setTitleParent}/>
                {data.type !== "sectionFindAJob" && <MediaEditor data={data}/>} 
                {data.subtitle && <SubtitleEditor data={data} title="subtitle" />}
                {data.button && <ButtonEditor data={data}/>}    
                <Box className="siteContents-item flex col w100">
                    <Typography variant="body2" component="span" sx={{mr:1}} className="Montserrat tbold">
                        Body
                    </Typography>
                    <Box className="siteContents-data flex col aicenter w100" sx={{background:"var(--gray)",py:0.5}}>    
                        <RichEditorSiteContents data={data}/>
                    </Box>
                </Box>
            </Box>
        </Card>     
    );
}
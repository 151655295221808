import { useEffect, useState } from 'react';
import {Backdrop, LinearProgress, Typography} from '@mui/material';
import { Box } from '@mui/system';
import './backdropLoading.css'

export default function BackdropLoading({openBackDrop, sync}) {

    const [isOpen, setIsOpen] = useState(false)
    const [text, setText] = useState("")

    useEffect(() => {
        setText(openBackDrop && sync?`Synchronizing ${openBackDrop}`:openBackDrop?`Uploading ${openBackDrop}`:"Ready")  
        if(openBackDrop){
            setIsOpen(true)
        }else{
            setTimeout(() => setIsOpen(false), 2000)
        }
    }, [openBackDrop])
    
    return (
        <Backdrop
            className="backdropLoading"
            sx={{ background:"#000000db", color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isOpen}
        >
            <Box className="flex center col">
                <Typography className="tcenter" color="white" variant="h3" component="h1"  >
                    {text}
                </Typography>
                    <LinearProgress sx={{my:1, minWidth:"80vw"}} />
                <Typography className="tcenter" color="white"  >
                    Please wait
                </Typography>
            </Box>
        </Backdrop>
    );
}
import { useContext, useEffect, useRef, useState } from 'react';
import { Autocomplete, Box, Chip, TextField, Typography } from '@mui/material';
import './siteContentsComponents.css'

import Card from '../card/Card';
import Context from '../../contexts/Context';


export default function SectionElevationCompanyEditor({data}) {
    const {siteContents} = useContext(Context)

    const siteContentsServices = siteContents.find(item => item.id === "services")
    const arrayServices = siteContentsServices.data.filter(service => service.type === "service")

    const [item1, setItem1] = useState(data.services[0])
    const [item2, setItem2] = useState(data.services[1])
    const [item3, setItem3] = useState(data.services[2])
    
    useEffect(() => {
        data.services[0] = item1
        data.services[1] = item2
        data.services[2] = item3
    }, [ data, item1, item2, item3,])
    

    const array = [
        {value:item1, setValue:setItem1},
        {value:item2, setValue:setItem2},
        {value:item3, setValue:setItem3},
    ]

    const getNameService = (serviceId) => {
        if(serviceId){
            let find = arrayServices.find(item => item.id === serviceId)
            return(find.title)
        }
    } 

    const getId = (name) => {
        let find = arrayServices.find(item => item.title === name)
        return(find.id)
    } 

    return (
        arrayServices &&
        <Card className="flex row center" >
            <Box className="siteContents-cardSection flex w100 aicenter">
                <Box className="flex row w100 aicenter">                      
                    <Typography variant="h6" component="span" className="Montserrat w100">
                        {data.title}
                    </Typography>
                </Box>
                <Box className="flex col center w100">
                    {item1 && item2 && item3 && array.map((item, index) =>{
                        return(
                            <Box className="siteContents-item flex col  w100" key={index}>
                                <Typography variant="body2" component="span" sx={{mr:1}} className="Montserrat tbold">
                                    Service {index + 1}
                                </Typography>
                                <Autocomplete
                                    className="inputKeywords"
                                    value={getNameService(item.value)}
                                    id="inputKeywords"
                                    options={arrayServices.map((option) => option.title)}
                                    onChange={(event, value) => item.setValue(getId(value))}
                                    onClose={() => {}}
                                    renderTags={(value, getTagProps) =>
                                        value.map((option, index) => (
                                            <Chip variant="filled" label={option} {...getTagProps({ index })} />
                                        ))
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            className="siteContents-input"
                                            {...params}
                                            variant="standard"
                                            // label="service"
                                        />
                                    )}
                                />
                            </Box>
                        )
                    })

                    }
                </Box>
            </Box>
        </Card>
    );
}
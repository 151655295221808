import { Box } from '@mui/material'
import { useContext, useState } from 'react'
import Context from '../../contexts/Context'
import './sectionFeaturedIcons.css'
import asapDefault from '../../assets/images/asap-default.png'

export default function SectionFeaturedIcons(){
    const {siteContents} = useContext(Context)
    const {data} = siteContents.find(item => item.id === "miscellaneous")
    const {medias} = data.find(item => item.id === "featuredIcons")
    const [mediaLoading, setMediaLoading] = useState(true)
    
    return (
            <Box className="containerFeaturedIcons flex row aicenter w100 mw1000" sx={{py:3}}>
                {
                    medias.map((media, index) =>{
                        return(
                            <Box key={index} className="featuredIcon flex center">
                                <img alt="featuredIcon" src={media.url} className="featuredIcon-image"  onLoad={({isTrusted}) => setMediaLoading(!isTrusted)} onError={({isTrusted})=> setMediaLoading(isTrusted)}/>
                                {mediaLoading && <img alt="featuredIcon" src={asapDefault} className="mediaDefault"/>}
                            </Box>
                        )
                    })
                }
           </Box>
    )
}


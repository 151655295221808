import { useContext, useRef, useState } from 'react';
import { Avatar, Box, Button, Divider, FormControl, IconButton, InputLabel, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, MenuItem, Select, Typography } from '@mui/material';
import './files.css'
import Card from '../../../components/card/Card';
import Context from '../../../contexts/Context';
import SubBarSearch from '../../../components/subBarSearch/SubBarSearch';
import { useEffect } from 'react';
import { sendDataToAPI, sendJsonToAPI } from '../../../services/requestsToAPI';
import { Add, ContentCopy, Delete, Folder } from '@mui/icons-material';
import { fileToBase64, getDateString } from '../../../services/serviceslocal';
import uuid from 'react-uuid'
import BackdropLoading from '../../../components/backdropLoading/BackdropLoading';
import { getData } from '../../../services/getData';
import ModalAlert from '../../../components/modalAlert/ModalAlert';

export default function Files() {

    const {files, setFiles} = useContext(Context)
    const [arrayFiles, setArrayFiles] = useState([])
    const [modalDeleteActive, setModalDeleteActive] = useState(false)
    const [fileToDelete, setFileToDelete] = useState(false)


    useEffect(() => {
      if(fileToDelete){
        setModalDeleteActive(true)
      }else(
        setModalDeleteActive(false)
      )
    }, [fileToDelete])
    
    useEffect(() => {
        if(!modalDeleteActive){
            setFileToDelete(false)
        }
    }, [modalDeleteActive])
        


    useEffect(() => {
        if(files.length === 0){
            getData("file/getFiles").then(res =>  setFiles(res))
        }else{
            setArrayFiles(files)
        }
    }, [files])

    
    const [isLoading, setIsLoading] = useState(false)
    const inputFileRef = useRef()

    const openInput = () => {
        inputFileRef.current.click()
        document.body.onfocus = function () { setTimeout(checkOnCancel, 100);}
    }

    const checkOnCancel = () =>{
        if(inputFileRef.current?.value?.length === 0){
        }
        if(inputFileRef.current?.value?.length > 0){
            document.body.onfocus = null
            imageHandler(inputFileRef.current)
        }
    }  
    
    const imageHandler = ({files}) => {
        if(files.length > 0 ){
            let file = files[0]       
            if(file){
                let id = uuid()
                fileToBase64(file).then(base64 => uploadFile(file.name, base64, id))
            return
            } 
        }
    }

    const uploadFile = async (fileName, base64, id) => {
        setIsLoading("Media File")
        let data = {id:id, name:fileName}
        data.url = await sendDataToAPI("/file/updateFile", {id, base64:base64, collection:"files"})
        // console.log(data.url)
        // console.log(data)
        data.fullDate = getDateString()
        let response = await sendJsonToAPI("/file/addFile", data)
        // console.log(response)
        if(response){
            setIsLoading(false)
            setArrayFiles([...arrayFiles, data])
            // inputFileRef.current.input.value = null
        }
    }

    const deleteFile = async (idFile) => {
        let response = await sendJsonToAPI("/file/deleteFile", {id:idFile})
        if(response){
            const newArray = files.filter(item => item.id !== idFile)
            setFiles(newArray)
        }
    }

    return (

        <Box className="flex col w100 aicenter" sx={{pb:2}} id="files">
            <SubBarSearch
                title="Files"
                noSearch
            />
            <Box className="containerResources flex col aicenter w100 mw900" sx={{px:1}}>
                <Card className="flex col center wrap">
                    <List className='w100'>
                        {arrayFiles.map(item => {
                            return(
                                <ListItemButton sx={{p:0}} key={item.id}>
                                    <ListItem
                                        className="files_itemList"
                                        secondaryAction={
                                            <Box className='flex row center'>
                                                <IconButton onClick={() => navigator.clipboard.writeText(item.url)} aria-label="Copy Url"><ContentCopy/> </IconButton>
                                                {/* <IconButton onClick={() => console.log(navigator)} aria-label="Copy Url"><ContentCopy/> </IconButton> */}
                                                <IconButton onClick={() => setFileToDelete(item)} edge="end" aria-label="delete"><Delete/> </IconButton>
                                                {/* <IconButton onClick={() => deleteFile(item.id)} edge="end" aria-label="delete"><Delete/> </IconButton> */}
                                            </Box>
                                        }
                                    >
                                        <ListItemAvatar onClick={() => window.open(item.url, '_blank')}>
                                            <Avatar>
                                                <Folder />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText 
                                            onClick={() => window.open(item.url, '_blank')} 
                                            sx={{overflowWrap: "anywhere"}} 
                                            primary={item.name}
                                            secondary={item.fullDate}
                                        />
                                    </ListItem>
                                </ListItemButton>   
                            )
                        })}

                        {arrayFiles.length > 0 && <Divider sx={{mb:3}}/>}

                        <ListItem className='flex col'>
                            <Button 
                                sx={{ml:"auto",  borderRadius:"50px", pl:"24px"}}
                                onClick={()=>openInput()}
                                variant="contained"
                                endIcon={<Add/>}
                                >
                                Add Files
                            </Button>
                        </ListItem>
                        <input 
                            ref={inputFileRef}
                            style={{display:"contents"}}
                            type="file"
                        />
                    </List>
                   
                </Card>
                <BackdropLoading openBackDrop={isLoading}/>
                <ModalAlert
                        modalActive={modalDeleteActive} 
                        setModalActive={setModalDeleteActive}
                        title="Delete File"
                        textCancel="Cancel"
                        textAction="Delete"
                        maxWidth="sm"
                        onClick={() => deleteFile(fileToDelete.id)}
                    >
                        <Typography> Are you sure you want to delete: "{fileToDelete.name}"?</Typography>
                    </ModalAlert>
            </Box>

        </Box>
    );
}
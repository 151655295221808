import { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { useParams } from 'react-router';
import './resources.css'
import ArticleVisor from '../articleVisor/ArticleVisor';
import ArticleEditor from '../articleEditor/ArticleEditor';
import Article from '../../../components/article/Article';

export default function Resources() {
    const {option} = useParams() 

    const [currentPage, setCurrentPage] = useState(1)
    const [idArrayPagination, setIdArrayPagination] = useState()

    useEffect(() => {
        if(idArrayPagination){
            setCurrentPage(1)
        }
    }, [idArrayPagination])

    return (
        <Box className="resources flex col aicenter w100">
            {option === "article-editor"?
                <ArticleEditor/>
            :option === "article"?
                <Box className="articleEditor flex col aicenter w100">
                    <Article/>
                </Box>
            :
                <ArticleVisor 
                    subBarSearch 
                    cms
                    currentPage={currentPage} 
                    setCurrentPage={setCurrentPage} 
                    setIdArrayPagination={setIdArrayPagination}    
                />
            }
        </Box>
    );
}
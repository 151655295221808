import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/analytics";


var firebaseConfig = {
    apiKey: "AIzaSyBWXFtgCXOeCk6lfrz-iG9E5FUPMby7Iv4",
    authDomain: "asapstaffingservices-proposal.firebaseapp.com",
    projectId: "asapstaffingservices-proposal",
    storageBucket: "asapstaffingservices-proposal.appspot.com",
    messagingSenderId: "958765902305",
    appId: "1:958765902305:web:60eba24c5a432636250aa0",
    measurementId: "G-G3LYQNCDF2"
};

// Initialize Firebase
export const fb = firebase.initializeApp(firebaseConfig);
export const providerFirebase = fb
export const db = fb.firestore();
export const storage = fb.storage();
export const auth = fb.auth();
export const provider = new firebase.auth.GoogleAuthProvider();
export const analyticsFB = firebase.analytics();



export const eventAnalytics = (nameAction) =>{
    analyticsFB.logEvent(nameAction, {
        date: new Date().toLocaleDateString()+' - '+new Date().toLocaleTimeString()
    });
}

import './siteContentsComponents.css'
import { Box, Typography } from '@mui/material';
import Card from '../card/Card';
import RichEditorSiteContents from './richEditorSiteContents/RichEditorSiteContents';
import ButtonEditor from './ButtonEditor';
import TitleEditor from './TitleEditor';
import ItemEditorFromArray from './ItemEditorFromArray';

export default function SectionNewslettersEditor({data, setData}) {
    
    return (
        <Card className="flex col center">

            <Box className="siteContents-cardSection flex w100 aicenter">
                <Box className="flex row w100 aicenter">                      
                        <Typography variant="h6" component="span" className="Montserrat w100">
                            {data.title}
                        </Typography>
                </Box>
                <TitleEditor data={data}/>
                <Box className="siteContents-data flex col aicenter w100" sx={{background:"var(--gray)",py:0.5}}>
                    <RichEditorSiteContents onlyText={data.type === "section"} data={data}/>
                </Box>
                <ButtonEditor data={data}/> 
                <Box className="siteContents-item flex col w100 jccenter w100">

                <Typography variant="body2" component="span" sx={{mr:1}} className="Montserrat tbold">
                    Interests
                </Typography>
                {data.categories.map((item, index) => {
                    return(
                        <ItemEditorFromArray key={index} dataItem={item}/> 
                        )
                    })
                }     
                </Box>
            </Box>
        </Card>


                    
    );
}
import { forwardRef, useState } from "react";
import Swal from 'sweetalert2';
import './modalSendResume.css'
import { Dialog, DialogContent, DialogTitle, Slide, Box, Button, Typography} from "@mui/material";
import FormSendResume from "./FormSendResume";
import { sendDataToAPI } from "../../services/requestsToAPI";
import mailSending from '../../assets/images/mail-sending.gif';
//import mailSend from '../../assets/images/mail-send.gif'
import mailSend from '../../assets/images/isa.jpg';
import { eventAnalytics } from "../../services/firebaseConfig";

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalSendResume({ modalActive, setModalActive, titleJob, idJob, numberJob }) {
    
    const steps = [titleJob?`Apply for ${titleJob} (${numberJob})`:"Complete your information"];
    const [currentForm, setCurrentForm] = useState(0);
    const [checkActive, setCheckActive] = useState(false);
    const final = currentForm === steps.length;
    const inicio = currentForm === 0;
    const [contactInformation, setContactInformation] = useState()
    const [sendingMessage, setSendingMessage] = useState(false)
    const [emptyResumeFile, setEmptyResumeFile] = useState(false)
    let applicationId = "";

    const resetForm = () => {
        setCheckActive(false)
        setContactInformation()
    }

    const handleClose = () => {
        resetForm()
        setCurrentForm(0)
        setModalActive(false);
    };

    const handleBack = () => {
        if (inicio) {
            return handleClose();
        }
        setCurrentForm((prevCurrentForm) => prevCurrentForm - 1);
    };
  
    
    const handleNext = () => {
        if(validateForm(contactInformation)){
            if (!checkActive && inicio) {
                return setCurrentForm(steps.length);
            }
            if (final) {
                sendMessage("/email/sendresume", contactInformation)
                
                resetForm()
                return(handleClose())
            }
            setCurrentForm((prevCurrentForm) => prevCurrentForm + 1);
        }  
    };

    const validateForm = (object) => {
        for (const key in object) {
            var item = object[key];
            if(item === ""){
                let thisInput = document.getElementById(`${key}Input`)
                thisInput.placeholder = `Please enter ${key}`
                thisInput.focus()
                return
            }
            if(key === "fileInput" && !item){
                setEmptyResumeFile(true)
                return
            }
        }
        return(true)
    }

    const handlerChange = (setState, currentTarget) => {
        let {value, id} = currentTarget
        document.getElementById(id).placeholder = ""
        setState(value)
    }

    const FormFinal = () => {
        return (
            <Box className="flex col w100 center" sx={{ p: 1 }}>
                <img
                    style={{minHeight:"120px"}}
                    width={120}
                    height={"auto"}
                    src={sendingMessage?mailSending:mailSend}
                    alt="message"
                />
                <Typography sx={{ mt: 2, mb: 1, textAlign:"center" }} variant="h6">
                Do not exit out from the website a window will pop up with a link to your interview with ISA.
                <br />
                <br />
                Also, you will find the link sent to the email address you provided in the form. Please check your spam folder.
                <br />
                <br />
                If you have not received the link, please apply again and ensure the email address is correct.
                </Typography>               
                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                    <Box sx={{ flex: "1 1 auto" }} />
                </Box>
            </Box>
        );
    };

    const registerEventAnalytics = () => {
        if(titleJob){
            eventAnalytics("New App General")
            eventAnalytics(`New App ${numberJob}`)
        }else{
            eventAnalytics("New App Candidate Pool")
        }
    }

    const sendMessage = async (param, data) => {
        if(titleJob){
            data.titleJob = titleJob
            data.numberJob = numberJob
            data.idJobSalesforce = idJob
        }
        setSendingMessage(true)
        data.fileBase64 = data.fileInput.base64.split(",")[1]
        // data.fileBase64 = "data64"
        data.fileExt = data.fileInput.file.name.split(".").pop().toLowerCase()
        const response = await sendDataToAPI(param, data)

        console.log(response);

        if('application' in response && response.application){
            applicationId = response.application;
        }

        if(response.status === 200){
            Swal.fire({
                title: 'Thank You For Applying!',
                html: '<p>Here is the link to your interview:</p> <a style="color:red" href="https://asapstaffingservices.my.site.com/AIOD/isa?app_id='+applicationId+'" target="_blank">Click Here</a>',
                icon: 'success',
                confirmButtonColor: "red",
                confirmButtonText: 'I will interview later'
            })
            registerEventAnalytics()
            setTimeout(() => {  
                setSendingMessage(false)
            }, 2000);
        }else{
            setTimeout(() => {
                setSendingMessage(false)  
            }, 4000);
        }
    }

    return (
        <div>
            <Dialog
                open={modalActive}
                TransitionComponent={Transition}
                // keepMounted
                className="modalSendResume"
                maxWidth="md"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{final ? sendingMessage?"Sending message":"Message sent" : steps[currentForm]}</DialogTitle>
                <DialogContent className="flex center">
                    <Box sx={{ width: "75%", minWidth: "40vw" }}>
                        <Box className="flex col w100" sx={{ p: 1 }}>
                            {currentForm === 0 ? 
                                <FormSendResume
                                    modalActive={modalActive} 
                                    handlerChange={handlerChange} 
                                    contactInformation={contactInformation} 
                                    setContactInformation={setContactInformation} 
                                    checkActive={checkActive} 
                                    setCheckActive={setCheckActive}
                                    emptyResumeFile={emptyResumeFile} 
                                    setEmptyResumeFile={setEmptyResumeFile}
                                />  
                            :
                                <FormFinal />
                            }
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                            <Button
                                color="inherit"
                                disabled={final}
                                onClick={handleBack}
                                sx={{ mr: 1, opacity: final ? 0 : 1 }}
                            >
                                Back
                            </Button>
                        <Box sx={{ flex: "1 1 auto" }} />

                        <Button onClick={handleNext} disabled={final && sendingMessage} >
                            {final
                            ? "Ok"
                            : checkActive && currentForm === 0
                            ? "Next"
                            : "Send Resume"}
                        </Button>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
        </div>
    );
}

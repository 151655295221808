import { useEffect, useRef, useState } from "react";
import { Box, Button, IconButton, TextField, Typography } from "@mui/material";
import { AccountCircle, CloudDone, Delete, Email, InsertDriveFile, Laptop, Phone, Work, } from "@mui/icons-material";
import { fileToBase64 } from "../../services/serviceslocal";


export default function FormSendResume({ handlerChange, contactInformation, setContactInformation, emptyResumeFile, setEmptyResumeFile}) {
        
    const [name, setName] = useState(contactInformation?contactInformation.name:"")
    const [email, setEmail] = useState(contactInformation?contactInformation.email:"")
    const [phone, setPhone] = useState(contactInformation?contactInformation.phone:"")
    const [profession, setProfession] = useState(contactInformation?contactInformation.profession:"")

    const refInputFile = useRef()
    const [fileInput, setFileInput] = useState(false);

    useEffect(() => {
        setContactInformation({name, email, phone, profession, fileInput})
    }, [name, email, phone, profession, fileInput])


    const getFile = ({target}) => {
        if(target.files.length > 0 ){
            setEmptyResumeFile(false)
            let file = target.files[0] 
            fileToBase64(file).then(base64 => {setFileInput({"file":file, "base64":base64})})  
        }
    }

    return (
        <Box className="flex col w100">
            <Box className="flex row" sx={{ alignItems: "center", my: 1 }}>
                <AccountCircle sx={{ color: "action.active", mr: 1, my: 0.5 }} />
                <TextField          
                    className="w100"
                    label="Full name"
                    size="small"
                    value={name}
                    onChange={({currentTarget}) => {handlerChange(setName, currentTarget)}}
                    id="nameInput"
                />
            </Box>
            <Box className="flex row" sx={{ alignItems: "center", my: 1 }}>
                <Email sx={{ color: "action.active", mr: 1, my: 0.5 }} />
                <TextField
                    sx={{ p: 0 }}
                    className="w100"
                    label="Email address"
                    size="small"
                    variant="outlined"
                    value={email}
                    onChange={({currentTarget}) => {handlerChange(setEmail, currentTarget)}}
                    id="emailInput"
                />
            </Box>
            <Box className="flex row" sx={{ alignItems: "center", my: 1 }}>
                <Phone sx={{ color: "action.active", mr: 1, my: 0.5 }} />
                <TextField
                    className="w100"
                    label="Phone Number"
                    size="small"
                    variant="outlined"
                    value={phone}
                    onChange={({currentTarget}) => {handlerChange(setPhone, currentTarget)}}
                    id="phoneInput"
                />
            </Box>
            <Box className="flex row" sx={{ alignItems: "center", my: 1 }}>
                <Work sx={{ color: "action.active", mr: 1, my: 0.5 }} />
                <TextField
                    className="w100"
                    label="Profession"
                    size="small"
                    variant="outlined"
                    value={profession}
                    onChange={({currentTarget}) => {handlerChange(setProfession, currentTarget)}}
                    id="professionInput"
                />
            </Box>
            <Box className="flex row" sx={{ alignItems: "center", my: 1}}>
                 <InsertDriveFile sx={{ color: "action.active", mr: 1, my: 0.5 }} />
                 <Box className={`flex col center containerFileResume w100 ${fileInput?"containerFileResumeOn":""} ${emptyResumeFile?"emptyResumeFile":""}`}>
                    {fileInput?
                        <>
                            <IconButton>
                                <CloudDone color="primary" fontSize="large"/>
                            </IconButton>
                            <Typography className="tcenter" variant="body2" color="dark">
                                {fileInput.file.name}
                            </Typography>
                            
                        </>
                    :
                        <>
                            <Typography 
                                className="tcenter" 
                                variant="body2" 
                                color={emptyResumeFile?"primary":"dark"}
                            >
                                Drop Resume here
                            </Typography>
                            <Typography 
                                className="tcenter" 
                                variant="body2" 
                                color={emptyResumeFile?"primary":"dark"}
                            >
                                or
                            </Typography>

                            <Button startIcon={<Laptop/>} variant="outlined" size="small" sx={{borderRadius:"50px", p:"6px 22px"}} >
                                Browse File
                            </Button>
                        </>
                    }
                    <input 
                        ref={refInputFile} 
                        type="file" 
                        className="inputFileResume"
                        onChange={getFile}
                        accept=".doc,.docx,application/msword,image/*,.pdf,video/*"
                        id="fileInput"                           
                    />   
                    {fileInput && 
                        <Box className="iconClose">
                            <IconButton onClick={()=> {setFileInput()}}>
                                <Delete fontSize="small"/>
                            </IconButton>
                        </Box>
                    }      
                </Box>
            </Box>
        </Box>  
     );
}

import { CheckBox, CheckBoxOutlineBlank, Search } from '@mui/icons-material';
import { Autocomplete, Box, Button, Checkbox, Chip, IconButton, TextField } from '@mui/material';
import './jobsCustom.css'
import { useNavigate } from 'react-router';
import { useState, useContext } from 'react';
import Context from '../../contexts/Context';
import { useEffect } from 'react';
import { separeArray } from '../../services/serviceslocal';

export default function JobsCustom({arrayParamsKeywords, arrayParamslocations, keyLocation, arrayJobs}) {
    const {onPathname} = useContext(Context)
    let navigate = useNavigate();

    const jobsByJobNumber = [...new Set(arrayJobs.map((item) => item.TR1__Job_Number__c))].filter(item => item !== null)
    const jobsByName = [...new Set(arrayJobs.map((item) => item.Title_Website))].filter(item => item !== null)
    const jobsByDivision = [...new Set(arrayJobs.map((item) => item.TR1__Division__c))].filter(item => item !== null)
    const jobsByIndustry = [...new Set(arrayJobs.map((item) => item.TR1__Industry__c))].filter(item => item !== null)
    const arrayJoinAll =[ ...jobsByJobNumber, ...jobsByName, ...jobsByDivision, ...jobsByIndustry].sort()
    const arrayJobsByAll = [...new Set(arrayJoinAll)];
    


    // const arrayCountry = [...new Set(arrayJobs.map((item) => item.Country__c))].filter(item => item !== null)
    // const arrayCity = [...new Set(arrayJobs.map((item) => item.TR1__City__c))].filter(item => item !== null)
    // const arrayStateArea = [...new Set(arrayJobs.map((item) => item.TR1__State_Area__c))].filter(item => item !== null)
    // const arrayJoinLocation = [...arrayCountry,...arrayCity, ...arrayStateArea].sort()
    // const arraylocations = [...new Set(arrayJoinLocation)];
    const arrayCountry = [...new Set(arrayJobs.map((item) => item.Country__c))].filter(item => item !== null).sort()
    const arrayCity = [...new Set(arrayJobs.map((item) => item.TR1__City__c))].filter(item => item !== null).sort()
    const arrayStateArea = [...new Set(arrayJobs.map((item) => item.TR1__State_Area__c))].filter(item => item !== null).sort()
    const arrayJoinLocation = [...arrayCountry,...arrayCity, ...arrayStateArea].sort()
    const arraylocations = [...new Set(separeArray(arrayJoinLocation).sort())];
    
    const [keywords, setKeywords] = useState(arrayParamsKeywords?arrayParamsKeywords:[])
    const [keylocations, setKeylocations] = useState(arrayParamslocations?arrayParamslocations:[])
    

    useEffect(() => {
        if(keyLocation){
            if(arrayParamsKeywords && !arrayParamsKeywords.length){
                setKeywords([])
            }
            if(arrayParamslocations && !arrayParamslocations.length){
                setKeylocations([])
            }
        }
    }, [keyLocation, arrayParamsKeywords,arrayParamslocations ])
    
    const formatQueryString = (key, arrayParameters) => {
        let arrayKeys = arrayParameters.map(element => {
            return(`${key}=${element.toLowerCase()}`)
        });
        return(arrayKeys.join('&'))     
    }

    const submitHandle= () => {       
        let item1 = keywords.length > 0?`?${formatQueryString("keywords", keywords)}`:""
        let item2 = keylocations.length > 0?`${keywords.length > 0?"&":"?"}${formatQueryString("locations", keylocations)}`:""
        let path = onPathname === "/find-a-job" || onPathname === "/find-a-job/"?"jobs":onPathname
        // let item1 = keywords.length > 0?`?keywords=${keywords}`:""
        // let item2 = keylocations.length > 0?`${keywords.length > 0?"&":"?"}locations=${keylocations}`:""
        //revisar
        navigate(`${path}${item1}${item2}`)
    }

    return (
        <Box className="jobsCustom w100 flex row" sx={{py:2}}>
            <Box className="containerInput flex row">
                <Autocomplete
                    className="inputKeywords"
                    multiple
                    value={keywords}
                    id="inputKeywords"
                    options={arrayJobsByAll.map((option) => option)}
                    onChange={(event, value) => setKeywords(value)}
                    freeSolo
                    onClose={() => {}}
                    renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                            <Chip variant="filled" label={option} {...getTagProps({ index })} />
                        ))
                    }
                    renderInput={(params) => (
                        <TextField
                            className="w100"
                            {...params}
                            variant="standard"
                            label="Job title, industry, keywords"
                            placeholder="Add"
                        />
                    )}
                />

                <Autocomplete
                    multiple
                    id="inputLocations"
                    className="inputLocations"
                    value={keylocations}
                    options={arraylocations}
                    disableCloseOnSelect
                    onChange={(event, value) => setKeylocations(value)}
                    getOptionLabel={(option) => option}
                    renderOption={(props, option, { selected }) => (
                        <li {...props}>
                        <Checkbox
                            icon={<CheckBoxOutlineBlank fontSize="small" />}
                            checkedIcon={<CheckBox fontSize="small" />}
                            style={{ marginRight: 8 }}
                            checked={selected}
                        />
                        {option}
                        </li>
                    )}
                    renderInput={(params) => (
                        <TextField 
                            className="w100"
                            {...params} 
                            variant="standard"
                            label="Location" 
                            placeholder="Location" 
                        />
                    )}
                />
            </Box>
            <Box className="jobsCustom_button">
                {/* <Button variant="contained" size="large">
                    Find a job
                </Button> */}
                <IconButton 
                    color="white" 
                    size='large'
                    onClick={()=>{submitHandle()}}                    
                >
                    <Search/>
                </IconButton>
            </Box>        
        </Box>
    );
}
import './siteContentsComponents.css'
import { Box, IconButton } from '@mui/material';
import Card from '../card/Card';
import RichEditorSiteContents from './richEditorSiteContents/RichEditorSiteContents';
import ButtonEditor from './ButtonEditor';
import MediaEditor from './MediaEditor';
import BackgroundColorEditor from './BackgroundColorEditor';
import TitleEditor from './TitleEditor';
import { ChromeReaderMode, DragIndicator, Article, Delete } from "@mui/icons-material";

export default function SectionEditor({data, setData}) {
    
    const reloadData = () => {
        setData(prevState => {
            let newArray = [] 
            prevState.forEach((section, index) => {
                if(section.id === data.id){
                    return(newArray.push(data))
                }
                newArray.push(section)
            })
            return(newArray)
        })
    }
    const deleteSection = () => {
        setData(prevState =>{
            const arrayDelete = prevState.filter(item => item.id !== data.id)
            return(arrayDelete)
        } )
    }
    return (
        <Card className="flex col center">
            <Box className="flex row w100" >
                <IconButton aria-label="Text only" 
                    onClick={() => {data.type = "section"; reloadData()}}
                    >
                    <Article color={data.type === "section"?"primary":"initial"}/>
                </IconButton>
                <IconButton aria-label="Text with media"
                    onClick={() => {data.type = "sectionImage"; reloadData()}}
                >
                    <ChromeReaderMode  color={data.type === "sectionImage"?"primary":"initial"}/>
                </IconButton>
                {/* <IconButton aria-label="Drag" sx={{mx:"auto"}}
                    // onClick={handleDelete}
                >
                    <DragIndicator/>
                </IconButton> */}
                <IconButton aria-label="Delete" onClick={() => deleteSection()}>
                    <Delete />
                </IconButton>
            </Box>
            <Box className="siteContents-cardSection flex w100 aicenter">
                <TitleEditor data={data}/>
                <Box className="siteContents-data flex col aicenter w100" sx={{background:"var(--gray)",py:0.5}}>
                    <RichEditorSiteContents onlyText={data.type === "section"} data={data}/>
                </Box>
                <ButtonEditor data={data}/>   
                {data.type !== "section" && <MediaEditor data={data}/>}  
                <BackgroundColorEditor data={data}/>
            </Box>
        </Card>


                    
    );
}
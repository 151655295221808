import { useState } from 'react';
import { Box, Input, Typography } from '@mui/material';
import './siteContentsComponents.css'
import { useEffect } from 'react';


export default function SubtitleEditor({data, isBody, title}) {
    // const [subtitle, setSubitle] = useState(data?isBody?data.body:data.subtitle:"")
    const [subtitle, setSubitle] = useState(data && title?data[title]:data?isBody?data.body:data.subtitle:"")

    useEffect(() => {
        if(title){
            data[title] = subtitle
            return
        }
        if(isBody){
            data.body = subtitle      
        }else{
            data.subtitle = subtitle 
        }
    }, [title, isBody, subtitle, data])
    
    
    return (
        <Box className="siteContents-item flex col  w100">
            <Box className="flex row w100 aicenter">
                <Typography variant="body2" component="span" sx={{mr:1, textTransform:"capitalize"}} className="Montserrat tbold">
                    {title?title:isBody?"Body":"Subtitle"}
                </Typography>
            </Box>
            <Input
                type='text'
                className="siteContents-input"
                placeholder={title?title:isBody?"Body":"Subtitle"}
                fullWidth
                disableUnderline
                value={subtitle}
                onChange={(e)=> {setSubitle(e.target.value)}}
            />
        </Box>          
    );
}
import { useContext, useEffect, useRef, useState } from 'react';
import { Box, Divider, Input, Typography } from '@mui/material';
import './siteContentsComponents.css'
import Card from '../card/Card';
import ButtonEditor from './ButtonEditor';
import TitleEditor from './TitleEditor';
import SubtitleEditor from './subtitleEditor';

export default function SectionElevationTalentEditor({data}) {

    return (
        <Card className="flex row center">
            <Box className="siteContents-cardSection flex w100 aicenter">
                <Box className="flex row w100 aicenter">                      
                    <Typography variant="h6" component="span" className="Montserrat w100">
                        {data.title}
                    </Typography>
                </Box>
                {data.items.map(item => {
                    return(
                        <Box key={item.position} className="flex col center w100">
                            <Typography variant="span" component="span" className="Montserrat w100" sx={{mt:1}}>
                                Shortcut {item.position}
                            </Typography>
                            <TitleEditor data={item}/>
                            <SubtitleEditor data={item} isBody/>
                            <ButtonEditor data={item} />
                           {item.position === 1 && <Divider variant="middle" className="w100" sx={{my:3}} />}
                        </Box>
                    )
                })
            }

            </Box>
        </Card>        
    );
}
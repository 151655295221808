import React, { useState, useEffect, useRef } from "react";
import { ContentState, convertFromHTML, convertFromRaw, Editor, EditorState, RichUtils } from "draft-js";
import "draft-js/dist/Draft.css";
import './richEditor.css'
import { Button, IconButton } from "@mui/material";
import { Box } from "@mui/system";

import { FormatListBulleted, FormatListNumbered, DoubleArrow, Close, Title, Code, FormatQuote, FormatUnderlined, FormatBold, FormatItalic, FormatClear, DragIndicator } from '@mui/icons-material';

function RichEditor({item, setTextBody, textBody, fixed}) {

    const editor = useRef(null);
    const {id, body=""} = item
    
    const emptyContentState = convertFromRaw({
        entityMap: {},
        blocks: [
            {
                text: "",
                key: "foo",
                type: "unstyled",
                entityRanges: [],
            },
        ],
    });
    const [editorState, setEditorState] = useState(EditorState.createWithContent(emptyContentState));

    useEffect(() => {
        // setEditorState(EditorState.createWithContent(ContentState.createFromText(body)));
        if(body){
            setEditorState(EditorState.createWithContent(
                ContentState.createFromBlockArray(convertFromHTML(body)))
            );
        }
      }, []);

    function focusEditor() {
        editor.current.focus();
    }

    
    // const insertDataBodyToEditor = () => {
    //     if(editor && body){
    //     editor.current.editor.innerHTML = body.replaceAll(`"`, `'`)
    //     }
    // }

    // useEffect(() => {
    //     focusEditor();
    //     insertDataBodyToEditor()
    // }, []);

    // useEffect(() => {
    //   if(editor && body){
    //     console.log("esto lo hace")
    //     editor.current.editor.innerHTML = body
    //     addTextBody(body.replaceAll(`"`, `'`))
    //   }
    // }, [editor, body, fixed]);


    const addTextBody = (newTextBody) => {
        const result = textBody.filter(item => item.id === id);
        result[0].body = newTextBody
    }

    useEffect(() => {
        if(editor){
        addTextBody(editor.current.editor.innerHTML.replaceAll(`"`, `'`))
        }
    }, [editorState, editor])


    // actions element
    const handleDelete = () => {
    setTextBody( prevState => {
        const result = prevState.filter(item => item.id !== id);
        return (result)
        })    
    }

    const StyleButton = (props) => {
        let onClickButton = (e) => {
        e.preventDefault();
        props.onToggle(props.style);
        };
        return <Button sx={{lineHeight: 0}} className="richEditor-btn" variant="text" color="secondary" onMouseDown={onClickButton} >{props.label}</Button>;
    };

    //BlockStyleControls Component
    const BLOCK_TYPES = [
        { label: <Title sx={{ fontSize: "17px" }}/>, style: "unstyled***" },
        // { label: "P", style: "blockquote" },
        { label: "H1", style: "header-one" },
        { label: "H2", style: "header-two" },
        { label: "H3", style: "header-three" },
        { label: "H4", style: "header-four" },
        { label: "H5", style: "header-five" },
        { label: "H6", style: "header-six" },
        { label: <FormatListBulleted sx={{ fontSize: "16px" }}/>, style: "unordered-list-item" },
        { label: <FormatListNumbered sx={{ fontSize: "16px" }}/>, style: "ordered-list-item" },
        // { label: <Code/>, style: "code-block" },
    ];

    const BlockStyleControls = (props) => {
        return (
        <Box className="flex">
            {BLOCK_TYPES.map((type) => (
            <StyleButton
                key={type.style}
                label={type.label}
                onToggle={props.onToggle}
                style={type.style}
            />
            ))}
        </Box>
        );
    };

    //InlineStyleControls Component
    const INLINE_STYLES = [
        { label: <FormatBold sx={{ fontSize: "17px" }}/>, style: "BOLD" },
        { label: <FormatItalic sx={{ fontSize: "17px" }}/>, style: "ITALIC" },
        { label: <FormatUnderlined sx={{ fontSize: "17px", marginTop:"auto"}}/>, style: "UNDERLINE" },
        { label: <Code sx={{ fontSize: "17px" }}/>, style: "CODE" },
    ];
    const InlineStyleControls = (props) => {
        return (
        <Box className="flex">
            {INLINE_STYLES.map((type) => (
            <StyleButton
                key={type.style}
                label={type.label}
                onToggle={props.onToggle}
                style={type.style}
            />
            ))}
        </Box>
        );
    };

    const onInlineClick = (e) => {
        let nextState = RichUtils.toggleInlineStyle(editorState, e);
        setEditorState(nextState);
    };

    const onBlockClick = (e) => {
        let nextState = RichUtils.toggleBlockType(editorState, e);
        setEditorState(nextState);
    };



    return (
        <Box onClick={focusEditor} className={`richEditor w100 ${!fixed?"richEditor-edit":""} bodyContent`}>
            {!fixed &&
                <Box className="richEditor-constrols-wrapper flex w100 row aicenter">
                    <Box className="richEditor-constrols flex w100 row">
                        <BlockStyleControls onToggle={onBlockClick} />
                        <InlineStyleControls onToggle={onInlineClick} />
                    </Box>
                    <Box className="richEditor-close">
                        <IconButton
                            aria-label="toggle close"
                            onClick={handleDelete}
                        >
                            <Close />
                        </IconButton>
                    </Box>
                    {/* <Box className="dragIndicator">
                        <IconButton
                            aria-label="drag"
                        >
                            <DragIndicator />
                        </IconButton>
                    </Box> */}
                </Box>
            }
            <div className="richEditor-input">
                <Editor
                    readOnly={fixed}
                    ref={editor}
                    editorState={editorState}
                    onChange={(editorState) => setEditorState(editorState)}
                />
            </div>
        </Box>
    );
}

export default RichEditor;

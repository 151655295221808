import { forwardRef, useState } from "react";
import "./modalMessage.css";
import { Dialog, DialogContent, DialogTitle, Slide, Box, Button, Typography} from "@mui/material";
import FormContactInformation from "./FormContactInformation";
import FormAboutThePosition from "./FormAboutThePosition";
import { sendDataToAPI, sendMailToAPI } from "../../services/requestsToAPI";
import { eventAnalytics } from "../../services/firebaseConfig";

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalMessage({ modalActive, setModalActive }) {

    const steps = ["Contact Information", "About the Position"];
    const [currentForm, setCurrentForm] = useState(0);
    const [checkActive, setCheckActive] = useState(false);
    const final = currentForm === steps.length;
    const inicio = currentForm === 0;
    const [contactInformation, setContactInformation] = useState()
    const [aboutThePosition, setAboutThePosition] = useState()
    const [sendingMessage, setSendingMessage] = useState(false)

    
    const resetForm = () => {
        setCheckActive(false)
        setContactInformation()
        setAboutThePosition()
    }

    // const handleClickOpen = () => {
    //     setModalActive(true);
    // };


    const handleClose = () => {
        resetForm()
        setCurrentForm(0)
        setModalActive(false);
    };

    const handleBack = () => {
        if (inicio) {
            return handleClose();
        }
        setCurrentForm((prevCurrentForm) => prevCurrentForm - 1);
    };
  
    const handleNext = () => {
        if(validateForm(contactInformation)){
            if (!checkActive && inicio) {
                // console.log("envia primer formulario")
                sendMessage("/email/sendinfo", contactInformation)
                return setCurrentForm(steps.length);
            }
            if(checkActive && currentForm === steps.length - 1){
                if(validateForm(aboutThePosition)){
                    setCurrentForm((prevCurrentForm) => prevCurrentForm + 1);
                    sendMessage("/email/hirentalent", {...contactInformation, ...aboutThePosition})
                    // return(console.log("envia segundo formulario"))
                }
                return
            }
            if (final) {
                // console.log("este es el final")
                resetForm()
                return(handleClose())
            }
            setCurrentForm((prevCurrentForm) => prevCurrentForm + 1);
        }  
    };

    const validateForm = (object) => {
        for (const key in object) {
            var item = object[key];
            if(key !== "message" && item === ""){
                let thisInput = document.getElementById(`${key}Input`)
                thisInput.placeholder = `Please enter ${key}`
                thisInput.focus()
                return
            }
        }
        return(true)
    }

    const handlerChange = (setState, currentTarget) => {
        let {value, id} = currentTarget
        document.getElementById(id).placeholder = ""
        setState(value)
    }

    const FormFinal = () => {
        return (
            <Box className="flex col w100 center" sx={{ p: 1 }}>
                <img
                    style={{minHeight:"120px"}}
                    width={120}
                    height={"auto"}
                    src={
                        sendingMessage?
                            "https://c.tenor.com/q-zZSTX6jSIAAAAC/mail-download.gif"
                        :
                            "https://cliply.co/wp-content/uploads/2021/03/372103860_CHECK_MARK_400px.gif"
                        }
                    alt="message"
                />
                <Typography sx={{ mt: 2, mb: 1, textAlign:"center" }} variant="h6">
                    One of our representatives will contact you shortly
                </Typography>
                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                    <Box sx={{ flex: "1 1 auto" }} />
                </Box>
            </Box>
        );
    };


    const sendMessage = (param, data) => {
        setSendingMessage(true)
        sendDataToAPI(param, data).then(response => {
            // console.log(response)
            if(response.status === 200){
                eventAnalytics("New Client Message")
                setSendingMessage(false)
            }else{
                setTimeout(() => {
                    setSendingMessage(false)  
                }, 4000);
            }
        })
    }


    return (
        <div>
            <Dialog
                open={modalActive}
                TransitionComponent={Transition}
                // keepMounted
                className="modalMessage"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{final ? sendingMessage?"Sending message":"Message sent" : steps[currentForm]}</DialogTitle>
                <DialogContent className="flex">
                    <Box sx={{ width: "100%", minWidth: "50vw" }}>
                        <Box className="flex col w100" sx={{ p: 1 }}>
                            {currentForm === 0 ? 
                                <FormContactInformation
                                    modalActive={modalActive} 
                                    handlerChange={handlerChange} 
                                    contactInformation={contactInformation} 
                                    setContactInformation={setContactInformation} 
                                    checkActive={checkActive} 
                                    setCheckActive={setCheckActive}
                                />  
                            :currentForm === 1 ?
                                <FormAboutThePosition
                                    modalActive={modalActive} 
                                    handlerChange={handlerChange} 
                                    aboutThePosition={aboutThePosition} 
                                    setAboutThePosition={setAboutThePosition} 
                                /> 
                            :
                                <FormFinal />
                            }
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                        <Button
                            color="inherit"
                            disabled={final}
                            onClick={handleBack}
                            sx={{ mr: 1, opacity: final ? 0 : 1 }}
                        >
                            Back
                        </Button>
                        <Box sx={{ flex: "1 1 auto" }} />

                        <Button onClick={handleNext} disabled={final && sendingMessage} >
                            {final
                            ? "Ok"
                            : checkActive && currentForm === 0
                            ? "Next"
                            : "Send Message"}
                        </Button>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
        </div>
    );
}

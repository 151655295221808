
export const handleWrite = (body, data, id) => {
    const result = body.filter(item => item.id === id);
    result[0].text = data
}


//url jobs
export const stringToUrl = (string) => {
    return(string.split(" ").join("+").split('&').join("%26").split("/").join("%85").toLowerCase())
}

export const urlToString = (url) => {
    let string = url.replaceAll('%85', '/').split("+").join(" ").split('%26').join("&").split("%20").join(" ")  
    // let string = url.replaceAll('kk', '/').split("+").join(" ").split("%20").join(" ").split("   ").join(" - ")  
    return(string)
    // return(url.split("-").join(" ").split("kk").join("*").split("%20").join(" ").split("   ").join(" - "))
}


export const clone = (any) =>{
    return(JSON.parse(JSON.stringify(any)))
}

export const scrollToElementById = (idElement) => {
    let elementActive = document.getElementById(idElement)
    // elementActive.scrollIntoView({ block : 'start',  behavior: 'smooth' })
    // elementActive.scrollIntoView({ block: 'end',  behavior: 'smooth' })
    const y = elementActive.getBoundingClientRect().top + window.pageYOffset - 58;
    window.scrollTo({top: y, behavior: 'smooth'});
}

export const scrollTop = (smooth) => {
    let appLocal = document.getElementById("appLocal")
        if(smooth){
            appLocal.scrollTo({top: 0, behavior: 'smooth'});
            return
        }
    appLocal.scrollTo({top: 0});
    window.scrollTo(0, 1)
}

export const getDateString = () => {
    let newDate = new Date();
    let date = newDate.toDateString()
    let hour = newDate.getHours()
    let minutes = `${newDate.getMinutes()<10?'0':''}${newDate.getMinutes()}`
    let seconts = `${newDate.getSeconds()<10?'0':''}${newDate.getSeconds()}`
    let dateString = `${date} ${hour}:${minutes}:${seconts}`
    return(dateString)
}
export const dateStringToObject = (dateString) => {
    let arrayDate = (dateString.split(' '))
    let dateObject = {
        day:arrayDate[0], 
        month:arrayDate[1],
        date:arrayDate[2],
        year:arrayDate[3],
        time:arrayDate[4]
    }
    return(dateObject)
}

export const dateObjectToString = (dateObject) => {
    let {day, month, date, year} = dateObject
        return(`${day} ${month} ${date} ${year}`)
}
    
export const dateToTimestamp = (dateString) => {
    return(Date.parse(dateString));
}



export const sortDescendant = (array, value) => {
    const cloneArray = [...array]
    const collator = new Intl.Collator('en');
    function SortArray(y, x){
        return collator.compare(dateToTimestamp(x[value]) , dateToTimestamp(y[value]));
    }
    return( cloneArray.sort(SortArray));
}

// YYYY-DD-AA
export const formatDate = (date) => {
    let d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

        return [year, month, day].join('-');
}


export const formatHour = (date) => {
    let d = new Date(date)
    return(`${d.getHours()}-${d.getMinutes()}`)
}

// getDaysPassed("2022-06-01")
export const getDaysPassed = (dateString) => {
    let date1 = new Date(dateString).getTime()
    let differenceInTime = Date.now() - date1
    var differenceInDays = differenceInTime / (1000 * 3600 * 24);
    return(Math.round(differenceInDays))
  }

export const orderArrayDesc = (array, value) => {
    const cloneArray = [...array]
    const collator = new Intl.Collator('en');
    function SortArray(y, x){
        return collator.compare(x[value] , y[value]);
    }
    return( cloneArray.sort(SortArray));
}
export const orderArray = (array, value) => {
    const cloneArray = [...array]
    const collator = new Intl.Collator('en');
    function SortArray(y, x){
        return collator.compare( y[value], x[value]);
    }
    return( cloneArray.sort(SortArray));
}

//orderNumber example 1,5,10
export const arrayNewOrder = (array, value) => {
    return(array.sort((a, b) => {return a[value] - b[value]}))
}

// location
export const separeArray = (array) => {
    let arrayNoNull = array.filter(item => item)
    let arrayTemp = []
    arrayNoNull.forEach(element => {
        if(element.includes('/')){
            element.split("/").forEach(item =>  (arrayTemp.push(normalize(item.trim().toUpperCase()))))
            return;     
        }
        if(element.includes(',')){
            element.split(",").forEach(item =>  (arrayTemp.push(normalize(item.trim().toUpperCase()))))
            return;     
        }
            arrayTemp.push(normalize(element.trim().toUpperCase()))
        });
    return([...new Set(arrayTemp)])
}

export const normalize = (str) => {
    return(str.normalize("NFD").replace(/[\u0300-\u036f]/g, ""))
}

export const replaceDash = (str) => {
    let string = str.replaceAll('-', ' ')  
    return(string)
}


// export const separeString = (string) => {
//     let arrayTemp = []
//         if(string.includes('/')){
//             string.split("/").forEach(item =>  (arrayTemp.push(item.trim())))
//             return;     
//         }
//         if(string.includes(',')){
//             string.split(",").forEach(item =>  (arrayTemp.push(item.trim())))
//             return;     
//         }
//         arrayTemp.push(string.trim())
//         console.log([...new Set(arrayTemp)])
//     return([...new Set(arrayTemp)])
// }

export const updateTitleWeb = (add) => {
    // const title = "ASAP Staffing Services Miami"
    // document.title = add?`${add}  |  ${title}`:title
    // updateMetas({data:"askl"})
}

export const updateMetas = (data = {}) => {
    const titleFixed = "ASAP Staffing Services Miami";
    const urlFixed = 'https://asapstaffingservices.com/';
    const imageFixed = 'https://asapstaffingservices.com/logoAsap-square.png';
  
    const {
      title = titleFixed,
      description = 'ASAP Staffing Services Miami is a branch of The ASAP Group of Companies, an International and privately owned staffing firm founded in 2000. One of the largest and most reputable staffing companies in the Americas, we operate 12 offices with more than 3,000 employees assigned to our distinguished client portfolio.',
      image = imageFixed,
      url = urlFixed
    } = data;
  
    console.log(url)
    document.title = `${title}  |  ${titleFixed}`;
    document.head.querySelector('meta[name="description"]').content = description;
    document.head.querySelector('link[rel="canonical"]').setAttribute('href', url);
    
    // Google / Search Engine Tags
    document.head.querySelector('meta[itemprop="name"]').content = title;
    document.head.querySelector('meta[itemprop="description"]').content = description;
    document.head.querySelector('meta[itemprop="image"]').content = image;
    
    // Facebook Meta Tags
    document.head.querySelector('meta[property="og:title"]').content = title;
    document.head.querySelector('meta[property="og:site_name"]').content = titleFixed;
    document.head.querySelector('meta[property="og:description"]').content = description;
    document.head.querySelector('meta[property="og:image"]').content = image;
    document.head.querySelector('meta[property="og:url"]').content = url;
    
    // Twitter Meta Tags
    document.head.querySelector('meta[name="twitter:title"]').content = title;
    document.head.querySelector('meta[name="twitter:description"]').content = description;
    document.head.querySelector('meta[name="twitter:image"]').content = image;
  };

  export const getMetas = (data = {}) => {
    const titleFixed = "ASAP Staffing Services Miami";
    const urlFixed = 'https://asapstaffingservices.com/';
    const imageFixed = 'https://asapstaffingservices.com/logoAsap-square.png';

    const {
        title = titleFixed,
        description = 'ASAP Staffing Services Miami is a branch of The ASAP Group of Companies, an International and privately owned staffing firm founded in 2000. One of the largest and most reputable staffing companies in the Americas, we operate 12 offices with more than 3,000 employees assigned to our distinguished client portfolio.',
        image = imageFixed,
        url = window.location.href,
    } = data;

    return {
        title: data ? `${title}  |  ${titleFixed}` : titleFixed,
        description: description,
        url: data ? url : urlFixed,
        // Google / Search Engine Tags
        google_name: data ? `${title}  |  ${titleFixed}` : titleFixed,
        google_description: description,
        google_image: image ? image : imageFixed,
        // Facebook Meta Tags
        og_title: data ? `${title}  |  ${titleFixed}` : titleFixed,
        og_site_name: titleFixed,
        og_description: description,
        og_image: image ? image : imageFixed,
        og_url: data ? url : urlFixed,
        // Twitter Meta Tags
        twitter_title: data ? `${title}  |  ${titleFixed}` : titleFixed,
        twitter_description: description,
        twitter_image: image ? image : imageFixed,
    };
};

// export const fileToBase64 = (filename, filepath) => {
//     return new Promise(resolve => {
//       var file = new File([filename], filepath);
//       var reader = new FileReader();
//       // Read file content on file loaded event
//       reader.onload = function(event) {
//         resolve(event.target.result);
//       };
      
//       // Convert data to base64 
//       reader.readAsDataURL(file);
//     });
//   };
// export const fileToBase64 = (file) => {
//         // var reader = new FileReader();
//         // reader.readAsDataURL(file)
//         // reader.onload = function await () {
//         //     let base64 = reader.result;
//         //     console.log(base64.split(",")[1])
//         //     return;
//         // }
//   };
export const getBlob = async (file) => {
    const response = await fetch(file);
    const multimedia = await response.blob();
    return(multimedia)
}

const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});

export async function fileToBase64(file) {
   return(await toBase64(file));
}


   // function downloadPDF(pdf) {
        //recibe // downloadPDF(data.fileInput.base64.split(",")[1])
    //     const linkSource = `data:application/pdf;base64,${pdf}`;
    //     const downloadLink = document.createElement("a");
    //     const fileName = "abc.pdf";
    //     downloadLink.href = linkSource;
    //     downloadLink.download = fileName;
    //     downloadLink.click();}


export const getBrowser = () =>{
    let userAgent = navigator.userAgent;
    let browserName;
    
    if(userAgent.match(/chrome|chromium|crios/i)){
        browserName = "chrome";
    }else if(userAgent.match(/firefox|fxios/i)){
        browserName = "firefox";
    }  else if(userAgent.match(/safari/i)){
        browserName = "safari";
    }else if(userAgent.match(/opr\//i)){
        browserName = "opera";
    } else if(userAgent.match(/edg/i)){
        browserName = "edge";
    }else{
        browserName="other";
    }
    
    return(browserName)        
}


// jobs

// url numberJob
export const jobNumberToUrl = (string) => {
    return(`${string.replaceAll(' ', '').toLowerCase()}`)
}
export const jobNumberToString = (string) => {
    return(`${string.replaceAll('jn-', 'jn -').toLowerCase()}`)
}

export const getJNfromUrlOld = (linkAsapOld) => {
    if(linkAsapOld.includes("jn-")){
        let indexJN = linkAsapOld.indexOf("jn-");
        let indexEnd = linkAsapOld.length;
        let jobNumber = linkAsapOld.substring(indexJN, indexEnd);
        return(jobNumber)
    }else{
        return(false)
    }
}


export const getJNandTitleFromTitleExt = (externalJobTitle) => {
    if(externalJobTitle.includes(" (JN")){
        let indexJN = externalJobTitle.indexOf(" (JN");
        let indexEnd = externalJobTitle.length -1;
        let title = externalJobTitle.substring(0, indexJN);
        let jobNumber = externalJobTitle.substring(indexJN + 2, indexEnd);
        return({title, jobNumber})
    }else{
        return({title:externalJobTitle,jobNumber:false})
    }
}

export const remplaceTitle = (arrayJobs) => {
    if(arrayJobs){
        const arrayTitle = arrayJobs.map(item => {
            if(item.TR1__External_Job_Title__c){
                item.Title_Website = getJNandTitleFromTitleExt(item.TR1__External_Job_Title__c).title
            }else{
                item.Title_Website = item.Job_Public_Name__c
            }
            return(item)
        })
        return(arrayTitle)
    }
}

export const titleStringJobToUrl = (string) => {
    return(string.split("-").join("+").split(" ").join("-").split('&').join("%26").split("/").join("%85").toLowerCase())
}

export const titleUrlJobToString = (url) => {
    let string = url.replaceAll('%85', '/').replaceAll('%20', '&').split("-").join(" ").split("+").join("-")  
    // let string = url.replaceAll('kk', '/').split("+").join(" ").split("%20").join(" ").split("   ").join(" - ")  
    return(string)
    // return(url.split("-").join(" ").split("kk").join("*").split("%20").join(" ").split("   ").join(" - "))
}


export const getDeleteStylesChildrens = (element) => {
  if(element.childNodes){
    element.childNodes.forEach(item => {
      item.style = ""
      if(item.innerText === ""){
        item.remove()
      }
      getDeleteStylesChildrens(item)
    });
  }
}
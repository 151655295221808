import { useEffect, useContext, useState } from 'react';
import {Box} from '@mui/material';
import { useParams } from 'react-router';
import MenuAside from '../../../components/menuAside/MenuAside';
import './cms.css'
import Resources from '../resources/Resources';
import Employees from '../employees/Employees';
import Account from '../account/Account';
import { useNavigate } from "react-router-dom";
import { updateMetas } from '../../../services/serviceslocal';
import Context from '../../../contexts/Context';
import SiteContents from '../siteContents/SiteContents';
import SyncData from '../syncData/SyncData';
import Subscribers from '../subscribers/Subscribers';
import Files from '../files/Files';
import Crypto from '../crypto/Crypto';
import Reports from '../reports/Reports';
import HelmetComponent from '../../../components/helmetComponent/HelmetComponent';


export default function Cms() {

    const {user, siteContents} = useContext(Context)
    const hasContent = siteContents.length > 0
    const [helmetData, setHelmetData] = useState()
    useEffect(() => {
            updateMetas({title:user.email})
            setHelmetData({title:user.email})
        }, [user])


    const {category} = useParams() 
    const navigate = useNavigate()

    useEffect(() => {
        if(!category){
            hasContent?
                navigate("/cms/resources")
            :   navigate("/cms/sync-data")
        }
    }, [category])

    return (
        <Box className="cms flex row">
            {helmetData && <HelmetComponent data={helmetData}/>}
            <MenuAside category={category} hasContent={hasContent}/>
            <Box className="containerCms flex col aicenter">
                {category === "resources" && <Resources/>}
                {category === "site-contents" && <SiteContents/>}
                {category === "employees" && <Employees/>}
                {category === "sync-data" && <SyncData />}
                {category === "subscribers" && <Subscribers />}
                {category === "files" && <Files />}
                {category === "account" && <Account />}
                {category === "crypto" && <Crypto />}
                {category === "reports" && <Reports />}
            </Box>
        </Box>
    );
}
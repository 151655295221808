import { useContext, useEffect, useRef, useState } from 'react';
import { Box, Button, useMediaQuery} from '@mui/material';
import './articleEditor.css'
import HeaderImage from '../../../components/headerImage/HeaderImage';
import { Code, Image, ShortText } from '@mui/icons-material';
import uuid from 'react-uuid'
import SourcesResources from '../../../components/sourcesResources/SourcesResources';
import PostArticleBy from '../../../components/postArticleBy/PostArticleBy';
import Text from '../../../components/text/Text';
import FabButton from '../../../components/fabButton/FabButton';
import { useNavigate, useParams } from 'react-router-dom';
import { urlToString, getDateString, formatDate, formatHour, sortDescendant } from '../../../services/serviceslocal'
import Context from '../../../contexts/Context';
import { sendDataToAPI, sendJsonToAPI } from '../../../services/requestsToAPI';
import { v4 as uuidv4 } from 'uuid';
import BackdropLoading from '../../../components/backdropLoading/BackdropLoading';
import ListDragArticle from '../../../components/listDragArticle/ListDragArticle';

export default function ArticleEditor() {

    const {setAllResources, allResources} = useContext(Context)
    const {article} = useParams() 
    const navigate = useNavigate()
    // const [articleToEdit, setArticleToEdit] = useState()

    const [title, setTitle] = useState("")
    const [media, setMedia] = useState(false)
    const [textBody, setTextBody] = useState([])
    const [sources, setSources] = useState([])
    const [postBy, setPostBy] = useState({id:undefined, type:"Written"})
    const [fixed, setFixed] = useState(false)
    const [idArticle, setIdArticle] = useState(false)
    const maxWidth450 = useMediaQuery("(max-width:450px)");

    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if(article){
            var result = allResources.find(item => item.title.toLowerCase() === urlToString(article) || item.title.toLowerCase().includes(urlToString(article)));
            if(result){
               setIdArticle(result.id)
               setTitle(result.title)
               setMedia(result.media)
               setTextBody(result.textBody)
               setSources(result.sources)
               setPostBy(result.postBy)
            }
        }
    }, [article]);


    const handleAdd = (type) => {
        if(!textBody){
            setTextBody([{type:type, body:"", id:uuid()}])    
        }else{
            setTextBody([...textBody, ...[{type:type, body:"", id:uuid()}]])
        }
    }

    const onClickFatFabButton = (action) => {
        if(action === "Create Article"){
            return(createArticle())
        }
        if(action === "Preview"){
            return(setFixed(!fixed))
        }
    }
    
    
    const createArticle = async () => {
        let data = { title, media, textBody, sources, postBy}
        data.fullDate = getDateString()
        data.active = true
        data.id = idArticle?idArticle:`${formatDate(getDateString())}-${formatHour(getDateString())}-${uuidv4()}` 
        
        // console.log(idArticle)
        // upload Cover
        if(media && media.url.includes('base64')){
            setIsLoading("Media File")
            data.media.url = await sendDataToAPI("/resources/uploadImage", {base64:media.url, id:`${data.id}/${data.id}`})
        }
        
        // upload Media File
        for (const [index, item] of textBody.entries()) {
            if(item.type === "image" && item.body.url.includes('base64')){
                setIsLoading("Media File")
                textBody[index].body.url = await sendDataToAPI("/resources/uploadImage", {base64:item.body.url, id:`${data.id}/${item.id}`})
            }
        }

        // upload Article
        setIsLoading("Article")
        
        let response = idArticle?await sendJsonToAPI("/resources/updateArticle", data):await sendJsonToAPI("/resources/addarticle", data)
        // console.log(response.error)
        if(response){
            setIsLoading(false)
            setAllResources(prevState => {
                const arrayfiltered = prevState.filter(item => item.id !== data.id)
                return(sortDescendant([...arrayfiltered, data], "fullDate"))
            }) 
            // setAllResources(prevState => [...prevState, data]) 
            setTimeout(() => navigate("/cms/resources"), 2000)        
        }
    }


    return (
        <Box className="articleEditor flex col aicenter w100">
            <HeaderImage
                image={media}
                setImage={setMedia}
                fixed={fixed}
                isArticle
            >
                <Text 
                    text={title} 
                    setText={setTitle}
                    placeholder="Title"
                    type="title"
                    fixed={fixed}
                />
            </HeaderImage>
            <BackdropLoading openBackDrop={isLoading}/>

            <Box p={2} className="w100 mw1000">
                <ListDragArticle
                    textBody={textBody}
                    setTextBody={setTextBody}
                    fixed={fixed}
                />
                {!fixed &&
                <>
                    <Button 
                        startIcon={<ShortText/>} 
                        color="secondary"
                        onClick={() => handleAdd("text")}
                    >
                        {!maxWidth450 &&"Add"} Text
                    </Button>
                    <Button 
                        startIcon={<Image/>} 
                        color="secondary"
                        onClick={() => handleAdd("image")}
                    >
                        {!maxWidth450 &&"Add"} Media
                    </Button>
                    <Button 
                        startIcon={<Code/>} 
                        color="secondary"
                        onClick={() => handleAdd("code")}
                    >
                        {!maxWidth450 &&"Add"} Iframe
                    </Button>
                </>
                }
            </Box> 

            <SourcesResources 
                sources={sources}
                setSources={setSources}
                fixed={fixed}
            />
            
            <Box className="flex col aicenter w100" style={{background:"var(--gray)"}}>
                <PostArticleBy 
                    postBy={postBy}
                    setPostBy={setPostBy}
                    fixed={fixed}
                />
            </Box>
            <FabButton handleClick={onClickFatFabButton} />
        </Box>
    );
}
import { useState } from 'react';
import {Box, SpeedDial, SpeedDialAction, SpeedDialIcon} from '@mui/material';
import { Article, Share, FileDownloadDone, Save, AddBox, ArrowBack, Code} from '@mui/icons-material';


export default function FabButtonEditor({handleClick, data}) {

    const actions = [
        { icon: <Code />, name: 'Generate Code' },
        { icon: <Save />, name: 'Save changes' },
        { icon: <AddBox />, name: 'Add section' },
        { icon: <ArrowBack />, name: 'back' },
    ];

    const items = !data?actions.filter(item => item.name !== 'Add section'):actions
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <Box sx={{ height:320, transform:'translateZ(0px)', flexGrow:1, position:"fixed", bottom:"16px", left: "2rem", zIndex:10 }}>
            <SpeedDial
                ariaLabel="SpeedDial uncontrolled open example"
                sx={{ position: 'absolute', bottom: 16, left: 16 }}
                icon={<SpeedDialIcon />}
                onClose={handleClose}
                onOpen={handleOpen}
                open={open}
            >
                {items.map((action) => (
                    <SpeedDialAction
                        key={action.name}
                        icon={action.icon}
                        tooltipTitle={action.name}
                        onClick={() =>{handleClick(action.name);handleClose()}}
                    />
                ))
                }
            </SpeedDial>
            </Box>
    );
}
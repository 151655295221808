import {Box, IconButton, Stack, Switch, Typography} from '@mui/material';
import './cardArticle.css'
import { Delete, Edit, Visibility } from '@mui/icons-material';
import Card from '../card/Card';
import { useState, useContext } from 'react';
import Context from '../../contexts/Context';
import { dateStringToObject, stringToUrl } from '../../services/serviceslocal';
// import { useNavigate, useLocation } from 'react-router-dom';
import sources from '../../assets/images/sources.jpg'
import { sendJsonToAPI } from '../../services/requestsToAPI';
import ModalAlert from '../modalAlert/ModalAlert';
import { getData } from '../../services/getData';

export default function CardArticle({article, cms}) {

    const {navigateTo, setAllResources} = useContext(Context);
    // const {date, title, image, body, postedBy, arraySourceLink} = article 
    const {id, active, title, media=false, fullDate} = article 

    const {date, month, year} = dateStringToObject(fullDate)

    const [articleActive, setArticleActive] = useState(active)
    const [modalDeleteActive, setModalDeleteActive] = useState(false)

    const handleChecked = async ({target}) => {
        let response = await sendJsonToAPI("/resources/activeArticle", {id: id, active: target.checked})
        if(response){
            setArticleActive(!articleActive)
        }
    }
    const [mediaLoading, setMediaLoading] = useState(true)

    return (
        <Card className="cardArticle row w100" button={!cms} onClick={() =>navigateTo(stringToUrl(title))}>
            <Box className="imageArticle flex center">
                <div className="imageArticle_background"/>
                <div className="imageArticle_date flex col">
                    <Typography
                    className='text-date month-date Montserrat'
                        // variant="body1"
                        component="h5"
                        align="right"
                    >
                        {month}
                    </Typography>
                    <Typography
                        className='text-date day-date Montserrat'
                        // variant="h5"
                        component="h5"
                        align="right"
                    >
                        {date}
                    </Typography>
                    <Typography
                        className='text-date year-date Montserrat'
                        // variant="body2"
                        component="h5"
                        align="right"
                    >
                        {year}
                    </Typography>
                </div>

                <Box className="imageArticle_image flex center noSelect">
                    {(mediaLoading || !media) && <img className="mediaDefault" src={sources} alt={title?title:"asap default"}  width="640" height="360"/>}
                    {media.type === "image" && 
                        <img loading='lazy' src={media.url} alt={title?title:"sectionImage"}  width="640" height="360" onLoad={({isTrusted}) => setMediaLoading(!isTrusted)} onError={({isTrusted})=> setMediaLoading(isTrusted)}/>
                    }                
                    {media.type === "video" && 
                        <video poster={sources}  src={media.url} alt={title?title:"sectionImage"} width="640" height="360" autoPlay loop muted playsInline onLoadedData={({isTrusted}) => setMediaLoading(!isTrusted)} onError={({isTrusted})=> setMediaLoading(isTrusted)}/>
                    }
                </Box>
            </Box>
            <Box className="bodyCard flex center" sx={{mx:2}}>
                <Typography
                    variant="h5"
                    component="h2"
                    // sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
                    sx={{ flexGrow: 1 }}
                >
                    {title}
                </Typography>
            </Box>
            {cms &&
                <> 
                    <Switch 
                        className="card-switch" 
                        label="checkACardrticle" 
                        size="small"
                        checked={articleActive} 
                        onChange={handleChecked}
                    />
                    <Box className="actionCard flex center">
                        <Stack
                            // direction={{ xs: 'column', sm: 'row' }}
                            direction='row'
                            spacing={{ xs: 1, md: 1 }}
                        >
                            <IconButton onClick={() =>setModalDeleteActive(true)}><Delete /></IconButton>
                            <IconButton onClick={() =>navigateTo("article/" + stringToUrl(title))}><Visibility/></IconButton>
                            <IconButton onClick={() =>navigateTo("article-editor/" + stringToUrl(title))}><Edit/></IconButton>
                        </Stack>
                    </Box>
                    <ModalAlert 
                        modalActive={modalDeleteActive} 
                        setModalActive={setModalDeleteActive}
                        title="Delete Article"
                        textCancel="Cancel"
                        textAction="Yes, Delete"
                        maxWidth="sm"
                        onClick={async() => {
                            await sendJsonToAPI("/resources/deleteArticle", {id:article.id})
                            getData("resources").then(resources => setAllResources(resources));
                        }}
                    >
                         <Typography> Are you sure you want to delete: "{title}"?</Typography>
                    </ModalAlert>
                </>
            }
        </Card>
    );
}
import { Box, Typography } from '@mui/material';
import './section.css'

export default function Section({children, title, sx, mw="mw1000", nmh}) {
   
    return (
        <Box className={`section flex col center w100 bodyContent ${mw} ${nmh?"section_nmh":""}` } sx={sx}>
            <Box className="flex col w100"  sx={{padding:2, paddingY:5}}>
                {title && 
                    <Typography variant="h3" gutterBottom component="h1" className="tbold tl" color="black" >
                        {title}
                    </Typography>
                }
                {children}
            </Box>
        </Box>
    );
}
import { useContext, useEffect, useRef, useState } from 'react';
import './siteContentsComponents.css'
import { Box, IconButton } from '@mui/material';
import Card from '../card/Card';
import MediaEditor from './MediaEditor';
import BackgroundColorEditor from './BackgroundColorEditor';
import TitleEditor from './TitleEditor';
import { Delete } from "@mui/icons-material";
import ButtonEditorFromArray from './ButtonEditorFromArray';
import SubtitleEditor from './subtitleEditor';

export default function SectionContactEditor({data, setData}) {

    const isLocation = data.id === "contactLocation"


    const deleteSection = () => {
        setData(prevState =>{
            const arrayDelete = prevState.filter(item => item.id !== data.id)
            return(arrayDelete)
        } )
    }
    return (
        <Card className="flex col center">
            <Box className="flex row w100" >
                <IconButton aria-label="Delete" onClick={() => deleteSection()}>
                    <Delete />
                </IconButton>
            </Box>
            <Box className="siteContents-cardSection flex w100 aicenter">
                <TitleEditor data={data}/>
                <SubtitleEditor data={data}/>
                {isLocation &&
                    <>
                        <SubtitleEditor data={data} title="link_map"/>
                        <SubtitleEditor data={data} title="iframe"/>
                    </>
                }
                {data.buttons.map((item, index) => {
                    return(
                        <ButtonEditorFromArray key={index} dataButtom={item}/>
                    )
                })
                } 
                                   
                <MediaEditor data={data}/>  
                <BackgroundColorEditor data={data}/>
            </Box>
        </Card>


                    
    );
}
import { Box, Typography,Input } from '@mui/material';
import { useEffect, useState } from 'react';

import './siteContentsComponents.css'
export default function ItemEditorFromArray({dataItem, dataParent}) {

    const [category, setCategory] = useState(dataItem.category || "")
    const [label, setLabel] = useState(dataItem.label || "")

    useEffect(() => {
        // console.log({category:category, label:label})
        dataItem.category = category
        dataItem.label = label
    }, [dataItem, category, label])

    return ( 
        <Box className="flex col">
            <Box className="flex row w100 aicenter">
                <Typography variant="body2" component="span" sx={{mr:1}} className="Montserrat tbold">
                    {category.replaceAll("-", " ")}
                </Typography>
            </Box>
            <Box className="flex center row w100" sx={{mt:0.5}}>
                <Input
                    placeholder="Add Label"
                    variant="standard" 
                    fullWidth
                    value={label} 
                    onChange={({target}) => setLabel(target.value)}
                    disableUnderline 
                    className="siteContents-input" 
                />
            </Box>
        </Box>

    );
}


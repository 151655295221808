
import { useEffect, useRef, useState } from 'react';
import { ClickAwayListener, Grow, Paper, Popper, MenuItem, MenuList, Stack, Button, Typography, useMediaQuery } from '@mui/material';
import { ChevronRight, KeyboardArrowDown, KeyboardArrowRight } from '@mui/icons-material';
import { NavLink, useNavigate } from 'react-router-dom';
import './navBarSubMenu.css'

export default function NavBarSubMenu({color, closeMenu, fullData, array}) {
    // const {name, linkBase, items} = item
    const anchorRef = useRef(null);
    const md = useMediaQuery("(max-width:800px)");
    
    const navigate = useNavigate()
    const [open, setOpen] = useState(false);
    const [enterSubMenu, setEnterSubMenu] = useState(false);


    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
        return;
        }

        setOpen(false);
        closeMenu()
    };

    const handleClickSubItem = (e, title) => {
        handleClose(e)
        const intermedie = fullData.id === "services" || title.toLowerCase().includes("team")?"/":"#"
        const path = title.toLowerCase().includes("team")?"meet-our-team":title.split(" ").join("-").toLowerCase()
        navigate(`${fullData.href}${intermedie}${path}`)
    
    }
    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
        event.preventDefault();
        setOpen(false);
        } else if (event.key === 'Escape') {
        setOpen(false);
        }
    }

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open])

    const handleClickNavLink = (e) => {
        if(open){
            e.preventDefault()
        }
        closeMenu(); 
        setOpen(false)
    }

    return (
        <NavLink to={fullData.href} onClick={handleClickNavLink}>
            <Stack 
                direction="row" 
                spacing={2} 
                sx={{zIndex:1}} 
                onMouseLeave={() => {!enterSubMenu && setOpen(false)}}
            >
                <Button
                    onContextMenu={(e) => e.preventDefault()}
                    ref={anchorRef}
                    fullWidth
                    disableFocusRipple={true}
                    color={color}
                    onClick={handleClickNavLink}
                    className="navBar-subMenu"
                    endIcon={
                        md?false
                        :!open?<KeyboardArrowDown onMouseEnter={() => setOpen(true)} onClick={() => setOpen(true)}/>
                        :<KeyboardArrowRight/>
                    }
                >
                        <Typography 
                            className="Montserrat tbold" 
                            variant="body2" 
                            onClick={() => navigate(fullData.href)}
                        >
                            {fullData.title}
                        </Typography>
                </Button>
                <Popper
                    open={md?false:open}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    placement="bottom-start"
                    transition
                    disablePortal
                    onMouseEnter={() => {setEnterSubMenu(true)}}
                    onMouseLeave={() => {setOpen(false);setEnterSubMenu(false)}}
                >
                {({ TransitionProps, placement }) => (
                    <Grow
                        className="grow"
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                            placement === 'bottom-start' ? 'left top' : 'left bottom',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList
                                    autoFocusItem={open}
                                    onKeyDown={handleListKeyDown}
                                >
                                    {
                                        array.map((item) => {
                                            return(                 
                                                <MenuItem 
                                                    key={item.id} 
                                                    onClick={(e) => handleClickSubItem(e, item.title)}
                                                    className="Montserrat"
                                                > 
                                                    <ChevronRight color="primary"/>
                                                    {item.title}
                                                </MenuItem>
                                            )
                                        }) 
                                    }
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
                </Popper>
            </Stack>
        </NavLink>
    );
}

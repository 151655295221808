import { useContext, useEffect, useRef, useState } from 'react';
import { Box, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import './siteContentsComponents.css'
export default function BackgroundColorEditor({data}) {
    
    const [background, setBackground] = useState(data?data.backgroundColor:"Auto")

    useEffect(() => {
        data.backgroundColor = background
    }, [data, background])
    
    return (
        <Box className="siteContents-item flex col w100 jccenter" sx={{py:0.5}}>
            <Typography variant="body2" component="span" sx={{mr:1}} className="Montserrat tbold">
                Background Color
            </Typography>
            <RadioGroup
                value={background}
                onChange={(e, value)=> setBackground(value)}
                row 
                aria-labelledby="select-Background" 
                name="radio-buttons-group" 
            >
                <FormControlLabel 
                    value="Auto" 
                    control={<Radio size='small' sx={{p:0.5, ml:1}}/>} 
                    label={<Typography variant="body2" component="span" sx={{mr:0.5}} className="Montserrat">Auto</Typography>}
                />
                <FormControlLabel 
                    value="Gray" 
                    control={<Radio size='small' sx={{p:0.5, ml:1}}/>} 
                    label={<Typography variant="body2" component="span" sx={{mr:0.5}} className="Montserrat">Gray {data.type === "sectionImage" && "(image Left)"}</Typography>}
                />
                <FormControlLabel 
                    value="White" 
                    control={<Radio size='small' sx={{p:0.5, ml:1}}/>} 
                    label={<Typography variant="body2" component="span" sx={{mr:0.5}} className="Montserrat">White  {data.type === "sectionImage" && "(image Rigth)"}</Typography>} 
                />
            </RadioGroup>
        </Box>     
                    
    );
}

import { Box, IconButton, Dialog, DialogActions, Slide, DialogContent } from "@mui/material";
import "./modalTeamCardImage.css";
import { forwardRef} from "react";
import { Close } from "@mui/icons-material";


const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalTeamCardImage({ image, modalActive, setModalActive }) {

    const handleClose = () => {
        setModalActive(false);
    };

    
    return (
        <div>
            <Dialog
                open={modalActive}
                TransitionComponent={Transition}
                className="modalTeamCardImage"
                fullWidth={true}
                maxWidth="sm"
                onClose={handleClose}
            >
                <IconButton 
                        className="buttonClose"
                        // sx={{position:'fixed', right:"10px", top:"10px"}}
                        onClick={handleClose}
                    >
                        <Close color="coralTree"/>
                    </IconButton>
                <DialogContent className="flex col" >
                    <img src={image} alt="" className="noSelect"/>
                </DialogContent>
            </Dialog>
        </div>
    );
    }

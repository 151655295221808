import { useState, forwardRef, useEffect, useRef } from "react";
import { Button, Dialog, DialogActions, DialogContent, Slide, Autocomplete, Box, IconButton, Input, TextField, Switch, Typography} from "@mui/material";
import { Close, Delete, Image, LinkedIn, Mail, Phone } from "@mui/icons-material";
import ModalTeamCardEditForm from "../modalTeamCardEditForm/ModalTeamCardEditForm";
import "./modalTeamCardEdit.css";
import TeamCard from "../teamCard/TeamCard";

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalTeamCardEdit({ item, modalActive, setModalActive, cms }) {

    const inputImageRef = useRef()
    const [name, setName] = useState(item.Name);
    const [active, setActive] = useState(item.IsActive);
    const [position, setPosition] = useState(item.Title);
    const [img, setImg] = useState(item.MediumPhotoUrl);
    const [deparment, setDeparment] = useState(item.Deparment);
    const [bio, setBio] = useState(item.AboutMe);
    const [email, setEmail] = useState(item.Email)
    const [linkedIn, setLinkedIn] = useState(item.LinkedIn_Profile__c)
    const [phone, setPhone] = useState(item.Phone)
    const [modalActiveForm, setModalActiveForm] = useState(false);
    const [configContact, setConfigContact] = useState(false);
    

    const imageHandler = (e) => {
        const {files} =  e.target
        const file = files[0]
        if(file){
            if(file.type.includes("image")){
                const imageFile = new FileReader()
                imageFile.readAsArrayBuffer(file)

                imageFile.onload = function() {
                    const blob = new Blob([imageFile.result])
                
                    // url for download
                    // const url = URL.createObjectURL(blob, {type: "image/png"});
                    const url = URL.createObjectURL(blob, {type:file.type});
                    setImg(url)
                }
            }else{alert("El logo debe ser formato de Imagen"); setImg(false)}
        }else(setImg(false))  
    } 


    const openModalForm = (item, setItem) => {
        // console.log("open modal")
      return(<ModalTeamCardEditForm item={item} setItem={setItem}/>)
    }

    const handleClose = () => {
        setModalActive(false);
    };

    const cancel = () => {
        setName(item.name)
        setPosition(item.position)
        setImg(item.img)
        setDeparment(item.deparment)
        setBio(item.bio)
        setModalActive(false);
        handleClose()
    }
    const saveChanges = () => {
        // console.log(JSON.stringify({name, active, position, img, deparment, bio, email, linkedIn, phone}))
        // console.log(JSON.stringify(bio))
    }

    return (
        <div>
            <Dialog
                open={modalActive}
                fullWidth={true}
                maxWidth="md"
                TransitionComponent={Transition}
                className="modalTeamCardEdit"
            >
                <DialogActions className="flex row" >

                    <Switch
                        checked={active} 
                        size="small"
                        // sx={{position:"absolute", left:"15px", bottom:2, zIndex:1}}
                        onChange={({target}) => setActive(target.checked)}
                    />
                    <IconButton 
                        sx={{marginLeft:"auto"}}
                        onClick={handleClose}
                    >
                        <Close/>
                    </IconButton>     
                </DialogActions>
                
                <DialogActions sx={{p:0}} className="modalTeamCard_teamCard_out">
                    <TeamCard item={item} btnDisable/>  
                </DialogActions>

                {item.AboutMe &&
                    <DialogContent className="flex col" >
                        <Box className="w100 flex center col" sx={{minWidth: "50vw" }}>
                            <Box className="modalTeamCard_teamCard_into">
                                <TeamCard  item={item} btnDisable />
                            </Box>
                            <Box className="mw900" sx={{p:2, mt:2}} >
                                <Typography className="section_text tj" color="dark">
                                    {item.AboutMe}
                                </Typography>
                            </Box>
                        </Box>
                    </DialogContent >
                }
                <DialogActions>
                    <IconButton color={email?"primary":"secondary"} size="large" onClick={()=> setModalActiveForm(true)} >
                        <Mail/>
                    </IconButton>
                    <IconButton color={linkedIn?"primary":"secondary"} size="large" onClick={()=> setModalActiveForm(true)}>
                        <LinkedIn/>
                    </IconButton>
                    <IconButton color={phone?"primary":"secondary"} size="large" onClick={()=> setModalActiveForm(true)}>
                        <Phone/>
                    </IconButton>  
                     
                </DialogActions>
                <ModalTeamCardEditForm 
                    item={item}
                    contact={configContact} 
                    setContact={setConfigContact} 
                    modalActive={modalActiveForm} 
                    setModalActive={setModalActiveForm}
                />
                 <DialogActions>
                    <Button color="secondary" variant="text"  startIcon={<Delete/>} sx={{marginRight:"auto"}}>Delete</Button>
                    <Button onClick={cancel} color="secondary">Cancel</Button>
                    <Button onClick={saveChanges} >Save Changes</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
    }

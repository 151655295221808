import { useRef, useEffect } from 'react';
import './sectionOnlytext.css'
import { Box, Typography } from '@mui/material';
import Section from '../section/Section';
import ButtonAction from '../buttonAction/ButtonAction';


export default function SectionOnlytext({data}) { 
    
    const {backgroundColor, button, body, media,  position,  showTitle,  title, type } = data 

    const bodySectionRef = useRef(null)

    useEffect(() => {
        if(bodySectionRef){
            bodySectionRef.current.innerHTML = body
        }
    }, [bodySectionRef, body])
    
    return (
        <Section>
            {title && showTitle &&
                <Typography 
                    variant="h3" 
                    gutterBottom 
                    component="h1"  
                    color="black" 
                    sx={{cursor:"default"}}
                >
                    {`${title}`}
                </Typography>
            }
            <Typography ref={bodySectionRef} className="sectionOnlytext_body section_text tj" color="dark" >
                {body}
            </Typography>
            
            {button.active &&
                <Box sx={{m:"auto", mt:2}}>
                    <ButtonAction variant="contained" size="large" sx={{mt:2, borderRadius:"50px"}} action={button.action}>
                        {button.title}
                    </ButtonAction>
                </Box>
            }
        </Section>
    );
}


import { Helmet } from 'react-helmet-async';
import { getMetas } from '../../services/serviceslocal';

export default function HelmetComponent({ data = false }) {
    const content = getMetas(data);

    return (
        <Helmet>
            <title>{content.title}</title>
            <meta name="description" content={content.description} />
            {/* Google / Search Engine Tags */}
            <meta itemprop="name" content={content.google_name} />
            <meta itemprop="description" content={content.google_description} />
            <meta itemprop="image" content={content.google_image} />
            {/* Facebook Meta Tags */}
            <meta property="og:title" content={content.og_title} />
            <meta property="og:site_name" content={content.og_site_name} />
            <meta property="og:description" content={content.og_description} />
            <meta property="og:image" content={content.og_image} />
            <meta property="og:url" content={content.og_url} />
            <meta property="og:type" content="website" />
            {/* Twitter Meta Tags */}
            <meta name="twitter:title" content={content.twitter_title} />
            <meta name="twitter:description" content={content.twitter_description} />
            <meta name="twitter:image" content={content.twitter_image} />
            <meta name="twitter:card" content="summary_large_image" />
            <link rel="canonical" href={content.url} />
        </Helmet>
    );
}

import { useState } from 'react';
import { Box } from '@mui/material';
import { useParams } from 'react-router';
import './employees.css'
import Team from '../../../components/team/Team';

export default function Employees() {
    const {option} = useParams() 

    return (
        <Box className="resources flex col aicenter w100"style={{background:"var(--white)"}}>
            <Team cms/>
        </Box>
    );
}
import { useContext, useEffect } from 'react';
import './aboutUs.css'
import { Box, Typography } from '@mui/material';
import HeaderImage from '../../../components/headerImage/HeaderImage';
import BreadcrumbsCustomized from '../../../components/breadcrumbsCustomized/BreadcrumbsCustomized';
import Context from '../../../contexts/Context';
import SectionImage from '../../../components/sectionImage/SectionImage';
import { useLocation } from 'react-router-dom';
import { orderArray, scrollToElementById, scrollTop } from '../../../services/serviceslocal';
import SectionOnlytext from '../../../components/sectionOnlytext/SectionOnlytext';

export default function AboutUs() {
    const {siteContents} = useContext (Context)
    let {data} = siteContents.find(item => item.id === "about-us")

    const {key, hash} = useLocation()
    
    useEffect(() => {
        if(hash){
            scrollToElementById(hash.substring(1))
        }else{
            scrollTop(true) 
        }
    }, [key, hash])
    
    return (
        <Box className="aboutUs flex col center w100 minh100">
            <BreadcrumbsCustomized />
             
            {
                orderArray(data, "position").map(item => {
                    let {id, type} = item
                    if(type === "sectionImage"){
                        let prevBackgroundColor = data.find(prevItem => prevItem.position === item.position - 1).backgroundColor
                        item.backgroundColor = prevBackgroundColor === "White"?"Gray":"White"
                    }
                    return(
                        type === "bannerSimple"?
                            <HeaderImage key={id}
                                animate
                                fixed
                                image={item.media}                
                                >
                                <Typography variant="h2" component="h1" className="tbold tcenter">
                                    {item.title}
                                </Typography>
                            </HeaderImage>
                        :type === "section"?
                            <SectionOnlytext key={id} data={item} /> 
                        :type === "sectionImage" &&
                            <SectionImage key={id} data={item} titleLink/>
                    )
                })
            }   
        </Box>
    );
}


import { useState } from 'react';
import { Avatar, Box, IconButton, Typography } from '@mui/material';
import './sectionContactUs.css'
import ButtonAction from '../buttonAction/ButtonAction';
import { LocalPhone, Article, Place, FacebookOutlined, Twitter, Instagram, LinkedIn } from '@mui/icons-material';
import asapDefault from '../../assets/images/asap-default.png'

export default function SectionContactUs({data, classTitle="tcenter", gutterBottom=false}) {
   
    const {id, backgroundColor, subtitle, button, media, showTitle, title, buttons} = data 

    const [mediaLoading, setMediaLoading] = useState(true)

    const icons = {
        "facebook": <FacebookOutlined/>,
        "twitter": <Twitter/>,
        "instagram": <Instagram/>,
        "linkedin": <LinkedIn/>,
        "place": <Place/>,
        "phone": <LocalPhone/>,
        "article":<Article/>
    }

    return (
        <Box className={`sectionImage section${backgroundColor==="Auto"?"White":backgroundColor} flex col center w100 bodyContent`} id={title?title.split(" ").join("-").toLowerCase():""}>
            <Box className={`sectionImage flex col center w100 mw1200`}>
                
                {/* <Box className="containerImage flex col jccenter w100">
                    {media.type === "image" && <img loading='lazy' className="containerImage_image" src={media.url} alt={title?title:"sectionImage"}  width="640" height="360"/>}
                    {media.type === "video" && <video className="containerImage_image" src={media.url} alt={title?title:"sectionImage"}  width="640" height="360" playsInline/>}
                    <div className="containerImage_background"></div>
                </Box> */}
                <Box className={`containerImage flex col jccenter w100 noSelect ${mediaLoading?"containerImage_mediaLoaded":""}`}>
                    <Box className="containerImage_image flex center">
                        {(mediaLoading || !media) && <img className="mediaDefault" src={asapDefault} alt={title?title:"asap default"}  width="640" height="360"/>}
                        {media.type === "image" && 
                            <img loading='lazy' src={media.url} alt={title?title:"sectionImage"}  width="640" height="360" onLoad={({isTrusted}) => setMediaLoading(!isTrusted)} onError={({isTrusted})=> setMediaLoading(isTrusted)}/>
                        }                
                        {media.type === "video" && 
                            <video poster={asapDefault}  src={media.url} alt={title?title:"sectionImage"} width="640" height="360" autoPlay loop muted playsInline onLoadedData={({isTrusted}) => setMediaLoading(!isTrusted)} onError={({isTrusted})=> setMediaLoading(isTrusted)}/>
                        }
                    </Box>
                    <div className="containerImage_background"></div>
                </Box>

                <Box className="sectionImage_body flex col w100 mw1000 " sx={{paddingY:3}}>
                    <Box className="flex col sectionImage_body_wrap"  sx={{padding:2}}>
                        {title && showTitle &&
                            <Typography 
                                variant="h3" 
                                gutterBottom={gutterBottom} 
                                component="h1" 
                                className={`sectionImage_body_title tbold ${classTitle}`} 
                                color="black" 
                                sx={{cursor:"default"}}
                            >
                                {title}
                            </Typography>
                        }

                        <Typography variant="h5" gutterBottom component="h2" className="Roboto section_text tbold tcenter" color="dark" >
                            {subtitle}
                        </Typography>
                      
                        {data.children && data.children}

                        {buttons &&
                            <Box mt={4} className="flex center row wrap"> 
                                {buttons.map(item => {
                                    return(
                                        <Box key={item.title} className={`${id === "contactLocation"?"flex col center":"contents"}`} >
                                            <ButtonAction
                                                className={`bounce flex center row ${id === "contactLocation"?"button-contactUsLocation":""}`} 
                                                sx={{m:1, borderRadius:"50px", minWidth:id === "contactSocialMedia" || id === "contactLocation"?"initial":"220px", justifyContent:"start", p:"8px"}}  
                                                action={item.action}
                                            >
                                                <Avatar sx={{ width: 50, height: 50, background:"var(--primary)" }}>
                                                    {icons[item.icon]}
                                                </Avatar>
                                                {(id !== "contactSocialMedia" && id !== "contactLocation") && 
                                                    <Typography 
                                                        className="tbold tcenter section_text" 
                                                        color="primary" 
                                                        sx={{my:1, ml:1, cursor:"pointer", whiteSpace: "initial"}}
                                                    >
                                                        {item.title}
                                                    </Typography>
                                                }                                
                                            </ButtonAction>
                                            {id === "contactLocation" && 
                                                <ButtonAction
                                                    sx={{borderRadius:"50px"}}
                                                    action={item.action}
                                                    className="pointer"
                                                    disableRipple
                                                >
                                                    <Typography 
                                                        className="tbold tcenter section_text" 
                                                        color="primary" 
                                                        sx={{my:1, ml:1, cursor:"pointer", whiteSpace: "initial"}}
                                                        >
                                                        {item.title}
                                                    </Typography>
                                                </ButtonAction>
                                            }       
                                        </Box>
                                        )
                                    }) 
                                }
                                   
                            </Box>
                        }
                    </Box>
                </Box>            
               
            </Box>
        </Box>
    );
}
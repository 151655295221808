import { useContext, useEffect, useRef, useState } from 'react';
import { Box, Input, Typography } from '@mui/material';
import './siteContentsComponents.css'
import Card from '../card/Card';
import MediaEditor from './MediaEditor';
import ButtonEditor from './ButtonEditor';
import TitleEditor from './TitleEditor';
import SubtitleEditor from './subtitleEditor';

export default function BannerEditor({data, simple}) {
    
    const [subtitle, setSubtitle] = useState(data?data.subtitle:"")
    
    useEffect(() => {
      data.subtitle = subtitle
    }, [data, subtitle])
    
    return (
        <Card className="flex row center">
            <Box className="siteContents-cardSection flex w100 aicenter">
                <Box className="flex row w100 aicenter">                      
                    {/* <Typography variant="h6" component="span" className="Montserrat w100" contentEditable suppressContentEditableWarning="true"> */}
                    <Typography variant="h6" component="span" className="Montserrat w100">
                        Banner
                    </Typography>
                </Box>
                <Box className="flex col center w100">
                    <TitleEditor data={data} required={simple}/>
                    {!simple && <SubtitleEditor data={data} />}
                    {!simple && <ButtonEditor data={data}/>}
                    <MediaEditor data={data}/>
                </Box>
            </Box>
        </Card>        
    );
}
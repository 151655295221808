import { useEffect, useRef, useState } from 'react';
import { Box, IconButton } from '@mui/material';
import './imageSimple.css'
import { Close} from '@mui/icons-material';
import { fileToBase64 } from '../../services/serviceslocal';

export default function ImageSimple({item, setTextBody, textBody, fixed}) {
    const {id, body} = item
    const [imageArticle, setImageArticle] = useState(body)
    const [inFocus, setInFocus] = useState(false)
    const inputImageRef = useRef()

    useEffect(() => {
        if(inputImageRef && !imageArticle ){
            openInput()
        }
    }, [inputImageRef, imageArticle])
    
    
    const openInput = () => {
        if(!fixed){
            inputImageRef.current.click()
            document.body.onfocus = function () { setTimeout(checkOnCancel, 100); };
        }
    }

    
    const checkOnCancel = () =>{
        if(inputImageRef.current?.value?.length === 0){
            // alert('You clicked cancel')
            return(handleDelete())
        }
        if(inputImageRef.current?.value?.length > 0){
            // alert('You selected a file') 
            document.body.onfocus = null
            imageHandler(inputImageRef.current)
        }
    }    

    const handleDelete = () => {
        setTextBody( prevState => {
            const result = prevState.filter(item => item.id !== id);
            return (result)
          })    
    }

    const imageHandler = ({files}) => {
        if(files.length > 0 ){
            let file = files[0]       
            if(file){
                if(file.type.includes("image")){
                    fileToBase64(file).then(base64 => addImage({url:base64, type:"image"}))
                    return 
                }
                if(file.type.includes("video")){
                    fileToBase64(file).then(base64 => addImage({url:base64, type:"video"})) 
                    return
                }
            return
            } 
            alert("Incompatible file format")
            setImageArticle(false)
            handleDelete()
        }
    }

    const addImage = (data) => {
        setImageArticle(data)
        const result = textBody.find(item => item.id === id);
        result.body = data
    }

    
    return (
        <Box
            sx={{ py: 1}}
            onFocus={()=>{setInFocus(true)}} 
            onMouseEnter={() => setInFocus(true)}       
            onClick={() => setInFocus(true)}       
            onBlur={()=>{setInFocus(false)}}        
            onMouseLeave={() => setInFocus(false)}   
            className={`imageSimple flex row center ${!fixed && inFocus?"imageSimple_hover":""}`}   
        >
            <input 
                ref={inputImageRef}
                style={{display:"contents"}}
                type="file" 
                // onChange={imageHandler} 
            />
            {imageArticle.type === "image" &&
                <img 
                    className="w100 mw1000"
                    alt="media file" 
                    src={imageArticle.url} 
                    onClick={()=>openInput()}
                />
             }
            {imageArticle.type === "video" &&
                <video 
                    controls
                    className="w100 mw1000"
                    alt="media file" 
                    src={imageArticle.url} 
                    onClick={()=>openInput()}
                    controlsList='nodownload'
                    playsInline
                />
             }
            {/* {!fixed && inFocus && */}
            {!fixed  &&
            <>
                <Box className="imageClose">
                    <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleDelete}
                    >
                        <Close />
                    </IconButton>
                </Box>
                {/* <Box className="dragIndicator">
                    <IconButton
                        aria-label="drag"
                    >
                        <DragIndicator />
                    </IconButton>
                </Box> */}
            </>
            }
        </Box>  
    );
}
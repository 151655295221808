import { Box, Typography } from '@mui/material';
import './resources.css'
import HeaderImage from '../../../components/headerImage/HeaderImage';
import BreadcrumbsCustomized from '../../../components/breadcrumbsCustomized/BreadcrumbsCustomized';
import ArticleVisor from '../../cms/articleVisor/ArticleVisor'
import { useParams } from 'react-router-dom';
import { useContext, useEffect } from 'react';
import Article from '../../../components/article/Article';
import { useState } from 'react';
import Context from '../../../contexts/Context';

export default function Resources() {

    const {article} = useParams() 
    const {siteContents} = useContext (Context)
    const {data} = siteContents.find(item => item.id === "resources")
    let resourcesBanner = data.find(item => item.id === "a85ba8b-25db-ef5a-6828-8308021f21a0")
    let resourcesSectionImage = data.find(item => item.id === "70cbd7-86e4-f2c1-6-5071f80a3oa5")
    const [currentArticle, setcurrentArticle] = useState();

    const onCurrentArticle = (item) => {
        setcurrentArticle(item)
    }

    useEffect(() => {
        if(!article){
            setcurrentArticle()
        }
    }, [article]);

    const [currentPage, setCurrentPage] = useState(1)
    const [idArrayPagination, setIdArrayPagination] = useState()

    useEffect(() => {
        if(idArrayPagination){
            setCurrentPage(1)
        }
    }, [idArrayPagination])

    return (
        <Box className=" flex col iccenter w100 minh100 ">
            <BreadcrumbsCustomized image={currentArticle?.media?.type === "image" && currentArticle?.media?.url} title={currentArticle?currentArticle.title:false} description={currentArticle?currentArticle?.textBody[0]?.body.replace( /(<([^>]+)>)/ig, ''):resourcesSectionImage?.body.replace( /(<([^>]+)>)/ig, '')}/>
               
                <Box className="minhCont">
                {!article? 
                    <>
                        <HeaderImage 
                            animate
                            fixed
                            image={resourcesBanner.media}                
                            >
                            <Typography variant="h2" component="h1" className="tbold tcenter">
                                {resourcesBanner.title}
                            </Typography>
                        </HeaderImage>
                        
                        <ArticleVisor 
                            barSearch  
                            currentPage={currentPage} 
                            setCurrentPage={setCurrentPage} 
                            setIdArrayPagination={setIdArrayPagination}
                        />
                    </>
                : 
                <Article onCurrentArticle={onCurrentArticle}/>
                }
            </Box>
        </Box>
    );
}


import { forwardRef, useRef, useContext, useState, useEffect } from 'react';
import {Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Skeleton, Slide} from '@mui/material';
import { Link } from '@mui/material';
import './modalMap.css'
import Context from '../../contexts/Context';
import { Box } from '@mui/system';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


export default function ModalMap({modalActive, setModalActive}) {
  const {siteContents} = useContext (Context)
  const {data: dataContacUs} = siteContents.find(item => item.id === "contact-us")
  const {iframe, link_map} = dataContacUs.find(item => item.id ===  "contactLocation")

  const [iframeIsLoad, setIframeIsLoad] = useState(false)
  
  const refIframeMap = useRef()
  const handleClose = () => {
    setModalActive(false);
    setIframeIsLoad(false)
  };

  return (
    <div>
      <Dialog
        open={modalActive}
        TransitionComponent={Transition}
        // keepMounted
        fullWidth={true}
        maxWidth="md"
        onClose={handleClose}
        className="modalSendResume"
        aria-describedby="alert-dialog-slide-description"
      >
        <Box className="modalMap">
          <DialogTitle>Use Google's location service?</DialogTitle>
          <DialogContent className="flex center" sx={{p:0, overflow:"hidden"}}>
            {!iframeIsLoad &&
              <Box height={300} className="flex center w100" sx={{zIndex:10, position:"absolute"}}>
                  <CircularProgress/>
              </Box>
            }

              <iframe 
                  onLoad={({isTrusted})=>{setIframeIsLoad(isTrusted)}}
                  id="myFrame"
                  ref={refIframeMap}
                  src={iframe.split(" ")[1].slice(5, -1)}            
                  title="map" 
                  className='iframeMap'
                  allowFullScreen 
                  loading="lazy"
              />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="black" >Back</Button>
            <Link href={link_map} underline="none" target="_blank">
                <Button onClick={handleClose} >Go to location</Button>
            </Link>
            
          </DialogActions>
        </Box>
      </Dialog>
    </div>
  );
}
import { NavLink } from 'react-router-dom';
import {Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Divider, IconButton, Toolbar} from '@mui/material';
import { ChevronLeft, CloudDownload, Folder, Inventory, Inventory2, ManageAccountsRounded, Menu, Password, PersonPin, ViewAgenda} from '@mui/icons-material';
import './menuAside.css'

export default function MenuAside({category, hasContent}) {


    const itemsMenu = hasContent?
        [
            {id:"resources", title:"Resources", icon: <Folder/>},
            {id:"site-contents", title:"Site Contents", icon:<ViewAgenda/> },
            {id:"sync-data", title:"Sync Data", icon:<CloudDownload/> },
            {id:"subscribers", title:"Subscribers", icon:<PersonPin/> },
            {id:"files", title:"Files", icon:<Inventory/> }
        ]
    :
        [
            {id:"site-contents", title:"Site Contents", icon:<ViewAgenda/> },
            {id:"sync-data", title:"Sync Data", icon:<CloudDownload/> },
        ]
    
    const itemsSubMenu = [
        // {id:"crypto", title:"Crypto", icon: <Password/>},
        {id:"account", title:"My Account", icon: <ManageAccountsRounded/>},
    ]
    const closeMenuAside = () => {
        document.getElementsByClassName("menuAside")[0].classList.remove("menuAside_active");
    }

    return (
        <>
            <Toolbar className=" flex" sx={{position:"absolute", top:"0", zIndex:5}}>
                    <IconButton
                        className="menuAside_btn"
                        onClick={()=>{document.getElementsByClassName("menuAside")[0].classList.add("menuAside_active");}} 
                        sx={{ml:"auto"}}
                        color={category === "employees"?"secondary":"white"}
                    >
                        <Menu/>
                    </IconButton>           
                </Toolbar>
            <Box elevation={2} className={`menuAside w100`} sx={{zIndex:6}}>
                <Toolbar className="w100 flex">
                    <IconButton
                        className="menuAside_btn"
                        onClick={()=>{closeMenuAside()}} 
                        sx={{ml:"auto"}}
                        color="secondary"
                    >
                        <ChevronLeft/>
                    </IconButton>           
                </Toolbar>
                
                <nav aria-label="Menu">
                    <List>
                        {itemsMenu.map(item =>{
                            return(
                                <ListItem key={item.id}disablePadding>
                                    <NavLink to={`/cms/${item.id}`} onClick={()=>{closeMenuAside()}} >
                                        <ListItemButton selected={category === item.id}>
                                            <ListItemIcon>{item.icon}</ListItemIcon>
                                            <ListItemText primary={item.title} />
                                        </ListItemButton>
                                    </NavLink>
                                </ListItem>
                            )
                        })}
                    </List>
                </nav>
                <Divider />
                <nav aria-label="Submenu">
                    <List>
                        {itemsSubMenu.map(item =>{
                            return(
                                <ListItem key={item.id}disablePadding>
                                    <NavLink to={`/cms/${item.id}`} onClick={()=>{closeMenuAside()}} >
                                        <ListItemButton selected={category === item.id}>
                                            <ListItemIcon>{item.icon}</ListItemIcon>
                                            <ListItemText primary={item.title} />
                                        </ListItemButton>
                                    </NavLink>
                                </ListItem>
                            )
                        })}
                    </List>
                </nav>
            </Box>
        </>
    );
}
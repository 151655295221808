import { useEffect, useState } from "react";
import { Box, TextField } from "@mui/material";
import { BusinessOutlined, Help, HowToReg, InsertComment, LocationOn, Work } from "@mui/icons-material";
import PopoverHelp from "../popoverHelp/PopoverHelp";


export default function FormAboutThePosition({ handlerChange, aboutThePosition, setAboutThePosition }) {
        
    const [company, setCompany] = useState(aboutThePosition?aboutThePosition.company:"")
    const [position, setPosition] = useState(aboutThePosition?aboutThePosition.position:"")
    const [positionType, setPositionType] = useState(aboutThePosition?aboutThePosition.positionType:"")
    const [location, setLocation] = useState(aboutThePosition?aboutThePosition.location:"")
    const [comments, setComments] = useState(aboutThePosition?aboutThePosition.comments:"")

    useEffect(() => {
        setAboutThePosition({company, position, positionType, comments, location})
    }, [company, position, positionType, comments, location])


    return (
        <Box className="flex col w100">
            <Box className="flex row" sx={{ alignItems: "center", my: 1 }}>
                <BusinessOutlined sx={{ color: "gray", mr: 1, my: 0.5 }} />
                <TextField
                    className="w100"
                    label="Company"
                    size="small"
                    variant="outlined"
                    value={company}
                    onChange={({currentTarget}) => {handlerChange(setCompany, currentTarget)}}
                    id="companyInput"
                />
            </Box>
            <Box className="flex row" sx={{ alignItems: "center", my: 1 }}>
                <Work sx={{ color: "action.active", mr: 1, my: 0.5 }} />
                <TextField
                    sx={{ p: 0 }}
                    className="w100"
                    label="Position Title"
                    size="small"
                    variant="outlined"
                    value={position}
                    onChange={({currentTarget}) => {handlerChange(setPosition, currentTarget)}}
                    id="positionInput"
                />
            </Box>
            <Box className="flex row" sx={{ alignItems: "center", my: 1, position:"relative"}}>
                <HowToReg sx={{ color: "action.active", mr: 1, my: 0.5 }} />
                <TextField
                    sx={{ p: 0}}
                    className="w100"
                    label="Position Type"
                    size="small"
                    variant="outlined"
                    value={positionType}
                    onChange={({currentTarget}) => {handlerChange(setPositionType, currentTarget)}}
                    id="positionTypeInput"
                />
                <Box sx={{position:"absolute", right:5}}>
                    <PopoverHelp body="Full-Time, Part-Time, Others">
                        <Help sx={{cursor:"help"}} color="gray"/>
                    </PopoverHelp>
                </Box>
            </Box>
            <Box className="flex row" sx={{ alignItems: "center", my: 1 }}>
                <LocationOn sx={{ color: "action.active", mr: 1, my: 0.5 }} />
                <TextField
                    className="w100"
                    label="Location"
                    size="small"
                    variant="outlined"
                    value={location}
                    onChange={({currentTarget}) => {handlerChange(setLocation, currentTarget)}}

                    id="locationInput"
                />
            </Box>
            <Box className="flex row" sx={{ alignItems: "center", my: 1 }}>
                <InsertComment sx={{ color: "action.active", mr: 1, my: 0.5 }} />
                <TextField
                    className="w100"
                    multiline
                    label="Comments"
                    variant="outlined"
                    value={comments}
                    onChange={({currentTarget}) => {handlerChange(setComments, currentTarget)}}
                    id="commentsInput"
                />
            </Box>
        </Box>
    );
}

import './page404.css'
import { Box, Button, Skeleton, Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { Home } from '@mui/icons-material';
import { useEffect } from 'react';
import { updateMetas } from '../../../services/serviceslocal';

export default function Page404() {

    useEffect(() => {
        updateMetas({title:"404 (Page not found)"})
    }, [])

    return (
        <Box className="flex col center w100" height="70vh">
            {/* <Skeleton   
                sx={{position:"absolute"}}
                width={"100%"} 
                height={"70vh"}
            /> */}
           <Typography variant="h1" className="section_text tj" color="dark" >
                404
            </Typography>

           <Typography className="section_text tj" color="dark" >
                Page not found
            </Typography>

            <NavLink to="/">
                <Button variant="outlined" startIcon={<Home/>} size="large" sx={{mt:5, borderRadius:"50px"}} translate="no">
                    Home ASAP
                </Button>
            </NavLink>
          
            {/* <SectionLocation/> */}
                
        </Box>
    );
}


import { useContext, useState } from 'react';
import { Avatar, Box, Button, FormControl, InputLabel, List, ListItem, ListItemAvatar, ListItemText, MenuItem, Select, Typography } from '@mui/material';
import './subscribers.css'
import Card from '../../../components/card/Card';
import Context from '../../../contexts/Context';
import SubBarSearch from '../../../components/subBarSearch/SubBarSearch';

import { useEffect } from 'react';
import { sendDataToAPI } from '../../../services/requestsToAPI';
import { AccountCircle } from '@mui/icons-material';

export default function Subscribers() {
    const {siteContents} = useContext(Context)
    const {data} = siteContents.find(({id}) => id === "miscellaneous")
    const {categories} =  data.find(({id}) => id === "newsletters")


    const [categorySelected, setCategorySelected] = useState('');
    const [suscribers, setSuscribers] = useState([]);
    const [suscribersText, setSuscribersText] = useState('');

    const handleChange = (event) => {
        setCategorySelected(event.target.value);
    };

    useEffect(() => {
      if(categorySelected){
        const getsubscribersByCategory = async () => {
            const response = await sendDataToAPI("/newsletters/subscribers", {category:categorySelected.category})
            setSuscribers(!response?[]:response.data)
            setSuscribersText(!response?'':response.data.map(item => item.email).toString().replaceAll(",", "\n"))
        }
        getsubscribersByCategory()
      }
    }, [categorySelected])
    

    return (

        <Box className="flex col w100 aicenter" sx={{pb:2}} id="subscribers">
            <SubBarSearch
                title="Subscribers"
                noSearch
            />
            <Box className="containerResources flex col aicenter w100 mw900" sx={{px:1}}>
                <Card className="flex row center">
                    <Box sx={{ minWidth: 120 }} className="w100">
                        <FormControl fullWidth>
                            <InputLabel id="select-label-category">Select Category</InputLabel>
                            <Select
                                labelId="select-label-category"
                                id="select-category"
                                value={categorySelected}
                                label="Select Category"
                                onChange={handleChange}
                            >
                                {categories.map((item) =>  <MenuItem key={item.category} value={item}>{item.label}</MenuItem>)}
                            </Select>
                        </FormControl>
                    </Box>
                </Card>
               
                {suscribers.length > 0 &&
                    <Card className="flex col center">
                        <Box className="w100 flex col">
                            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} className="Montserrat">
                                Subscribers for {categorySelected.label}
                            </Typography>
                            <List sx={{mt:1}} className="w100 flex col jccenter">
                                {suscribers.map((item, index) => {
                                    return(
                                        <ListItem className="files_itemList" key={item.email + index}>
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <AccountCircle />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText 
                                                sx={{overflowWrap: "anywhere"}} 
                                                primary={item.name}
                                                secondary={item.email}
                                            />
                                        </ListItem>
                                    )
                                })}
                            </List>
                        </Box>

                        <Button 
                            sx={{ml:"auto", mt:2, borderRadius:"50px"}}
                            onClick={() => navigator.clipboard.writeText(suscribersText)}
                            variant="contained"
                        >
                            Copy emails list
                        </Button>
                    </Card>

                }
            </Box>

        </Box>
    );
}
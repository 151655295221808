import { useContext, useEffect, useState } from 'react';
import { AccountCircle, Mail, RadioButtonUnchecked, TaskAlt } from '@mui/icons-material';
import { Box, Button, Typography, Chip, Input, DialogActions } from '@mui/material';
import ModalAlert from '../modalAlert/ModalAlert';
import './modalNewsletters.css'
import Context from '../../contexts/Context';
import { sendJsonToAPI } from '../../services/requestsToAPI';
import { eventAnalytics } from '../../services/firebaseConfig';


export default function ModalNewsletters({modalActive, setModalActive}) {
    
    const {siteContents} = useContext(Context)
    const {data} = siteContents.find(item => item.id === "miscellaneous")
    const {categories} = data.find(item => item.id === "newsletters")

    // const categories = [
    //     {category:"topTalenT", label:"New Top Talent"},
    //     {category:"position", label:"New Positions"},
    //     {category:"newsAsap", label:"News ASAP"}
    // ]

    const [name, setName] = useState('') 
    const [email, setEmail] = useState(false) 
    const [formValidated, setFormValidated] = useState(false)
    const [isSending, setISending] = useState(false)
    const [selectedInterests, setSelectedInterests] = useState([])

    const addInterest = (value) => {
        if(selectedInterests.includes(value)){
            setSelectedInterests(selectedInterests.filter(item => item !== value))
        }else{
            setSelectedInterests([...selectedInterests, value])
        }
    }

    useEffect(() => {
        let emailValidated = email && email.includes("@") && email.substring(email.length - 5, email.length - 2).includes(".")
        setFormValidated(name !== "" && emailValidated && selectedInterests.length > 0)
    }, [email, name, selectedInterests])


    const handleOnSubmit = async () => {
        setISending(true)
        await sendJsonToAPI("/newsletters/addSubscriber", {name:name, email:email, categories:selectedInterests})
        eventAnalytics("New Subscriber")
    }

    useEffect(() => {
        if(!modalActive){
        setEmail(false)
        setFormValidated(false)
        setISending(false)
        setSelectedInterests([])
        }
    }, [modalActive])

    return (
        <ModalAlert 
            closeExternal
            modalActive={modalActive} 
            setModalActive={setModalActive} 
            title={"Newsletters"} 
            dialogActions={!isSending &&
                <DialogActions>
                    <Button color="secondary" onClick={()=>setModalActive(false)}>
                        <Typography variant="body1" component="span" ml={1} fontSize={14}>
                            Cancel
                        </Typography>
                    </Button>
                    <Button 
                        disabled={!formValidated} 
                        onClick={() => handleOnSubmit()}> 
                        <Typography variant="body1" component="span" className="tbold" ml={1} fontSize={14}>
                            Subscribe
                        </Typography>
                    </Button>
                </DialogActions>
            }
            >
            {!isSending?
                <Box className="w100 flex col">
                    <Box className="newsletters-input flex w100 col center" sx={{mb:1}}>
                        <AccountCircle color="primary" className="newsletters-input-icon"/>
                        <Input
                            className="newsletters-input-input w100"
                            required
                            type="text"
                            disableUnderline
                            value={name}
                            autoComplete="name"
                            onInput={({target}) =>setName(target.value)}
                            placeholder="Full Name"
                        />
                    </Box>
                    <Box className="newsletters-input flex w100 col center" sx={{mb:1}}>
                        <Mail color="primary" className="newsletters-input-icon"/>
                        <Input
                            className="newsletters-input-input w100"
                            required
                            type="email"
                            disableUnderline
                            autoComplete="email"
                            onInput={({target}) =>setEmail(target.value)}
                            placeholder="Email"
                        />
                    </Box>

                    <Box sx={{mt:3}}>
                        <Typography variant="body2" component="span" fontSize={18}>
                            What topics are you interested in?
                        </Typography>
                    </Box>
                    <Box className="flex row wrap">
                        {categories.map(category => {
                            let isselected = selectedInterests.includes(category.category)
                            return(
                                <Chip 
                                    key={category.category}
                                    icon={isselected?<TaskAlt fontSize='small' color="primary"/>:<RadioButtonUnchecked fontSize='small'/>} 
                                    label={category.label} 
                                    sx={{mr:1, mt:1}} 
                                    color={isselected?"primary":"secondary"} 
                                    variant="outlined"
                                    onClick={() => addInterest(category.category)}
                                />
                            )})
                        }
                    </Box>
                </Box>
            :
                <Box className="flex center w100 wrap row">
                    <Typography variant="body1" component="span" className="tl tbold" fontSize={18}>
                        {email}
                    </Typography>
                    <Typography variant="body1" component="span" className="tl" ml={1} fontSize={18}>
                        You're now subscribed.
                    </Typography>
                <Box className="subscribeAnimation">
                    <img src="https://i.gifer.com/origin/03/03270abe66b1c66ef8832c57aa6da0c1.gif" alt="subscribed Animation"/>
                </Box>
                </Box>
            }
        </ModalAlert>
    );
}
import { Box, Typography, TextField, Autocomplete, Avatar } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import './postArticleBy.css'
import logoAsapSquare from '../../assets/images/logoAsap-square.png'
import TeamCard from '../teamCard/TeamCard';
import Context from '../../contexts/Context';

const employeeUndefined = {
    "Name":"ASAP",
    "Title":"Staffing Services",
    "MediumPhotoUrl":logoAsapSquare,
}

export default function PostArticleBy({postBy, setPostBy, fixed, py=2, px=2}) {
    const {team} = useContext (Context)
    
    const [arrayEmployees, setArrayEmployees] = useState([])
    
    useEffect(() => {
        setArrayEmployees(team)
    }, [team])


    const data = postBy
    const [employeeSelected, setEmployeeSelected] = useState()
    const [type, setType] = useState(postBy?postBy.type:"Written")
    
    const [nameEmployee, setNameEmployee] = useState(employeeUndefined.Name);
    const [inputValue, setInputValue] = useState('');
    
    useEffect(() => {
        if(arrayEmployees.length > 0){
            findEmployeeById(data.Id)
            setType(data.type)
        }
    }, [data, arrayEmployees]);

    const findEmployeeById = (idEmployee) => {
        const employee = arrayEmployees.find(element => element.Id === idEmployee);
        setEmployeeSelected(employee === undefined?employeeUndefined:employee) 
        setNameEmployee(employee === undefined?employeeUndefined.Name:employee.Name) 
    }
    
    const findEmployeeByName = (nameEmployee) => {
        const employee = arrayEmployees.find(element => element.Name === nameEmployee);
        setEmployeeSelected(employee === undefined?employeeUndefined:employee)    
        setNameEmployee(employee === undefined?employeeUndefined.Name:employee.Name) 
    }
     
    const changePostby = () => {
        !fixed && 
        setType(type === "Written"?"Searched":type === "Searched"?"Posted":"Written")
    }

    useEffect(() => {
        if(!fixed && employeeSelected){
            setPostBy({Id:employeeSelected.Id, type:type})
        }
    }, [fixed, type, employeeSelected])


    return (
        <Box py={py} px={px} className="postArticleBy flex col w100 mw1000">
            {type &&
                <Typography 
                    component="h3" 
                    textAlign="left"
                    color="dark"
                    onClick={() => changePostby()}
                    style={{cursor:!fixed?"pointer":"default"}}
                    // sx={{lineHeight: "initial", paddingLeft:1, marginLeft:"70px"}}
                >
                    {type} by:
                </Typography>
            }
            {employeeSelected &&
                <Box className="flex row" my={1} >
                    {fixed?
                        <TeamCard 
                            item={employeeSelected} 
                            min btnDisable={employeeSelected.Name === employeeUndefined.Name} 
                        />
                    :
                    <Box className="flex center row">
                            <Autocomplete
                                clearText="Clear"
                                disablePortal
                                id="find-employee"
                                options={arrayEmployees.map((employee => employee.Name))}
                                className="postArticleBy_autocomplete"
                                value={nameEmployee}
                                onChange={(event, newValue) => findEmployeeByName(newValue)}
                                inputValue={inputValue}
                                onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
                                renderInput={(params) => 
                                    <Box className="flex center row">
                                        <Box className="containerAvatar flex center">
                                            <div className="backGroundAvatar"></div>

                                                <Avatar 
                                                    className="avatar"
                                                    alt={employeeSelected?.Name ||  ""} 
                                                    src={employeeSelected?.MediumPhotoUrl || undefined} 
                                                />
                                        </Box>
                                        <Box className="flex col" sx={{paddingLeft:1, width:"100%"}} translate="no">
                                            <TextField
                                                sx={{ml:2}} 
                                                {...params}
                                                id="inputEmployee" 
                                                placeholder="Employee" 
                                                variant="standard"
                                                value={employeeSelected.Name}
                                            />
                                            <Typography 
                                                component="h3" 
                                                textAlign="left"
                                                color="dark"
                                                sx={{lineHeight: "initial", ml:2, mt:1}}
                                            >
                                                {employeeSelected?employeeSelected.Title:""}
                                            </Typography>
                                        </Box>
                                    </Box>
                                }
                            />
                    </Box>
                    }   
                </Box>
            }
        </Box>
    );
}


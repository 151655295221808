import { Box, Button } from '@mui/material';
import { useContext, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import './homeTalent.css'
import Context from '../../../contexts/Context';
import Banner from '../../../components/Banner/Banner';
import { Work } from '@mui/icons-material';
import SectionImage from '../../../components/sectionImage/SectionImage';
import CardArticleMinList from '../../../components/cardArticleMinList/CardArticleMinList';
import Section from '../../../components/section/Section';
import SectionElevationTalent from '../../../components/sectionElevationTalent/SectionElevationTalent';
import SectionFeaturedIcons from '../../../components/sectionFeaturedIcons/SectionFeaturedIcons';
import SectionTestimonials from '../../../components/sectionTestimonials/SectionTestimonials';
import { clone, orderArray, scrollTop, updateMetas } from '../../../services/serviceslocal';
import SectionOnlytext from '../../../components/sectionOnlytext/SectionOnlytext';
import SectionContactUs from '../../../components/sectionContactUs/SectionContactUs';
import HelmetComponent from '../../../components/helmetComponent/HelmetComponent';

export default function HomeTalent() {

    const {setTypeClient, siteContents} = useContext(Context)

    let {title, data} = clone(siteContents.find(item => item.id === "talent"))
    const {data: dataResources} = siteContents.find(item => item.id === "resources")
    const {data: dataContacUs} = siteContents.find(item => item.id === "contact-us")

    let resourcesSectionImage = clone(dataResources.find(item => item.id === "70cbd7-86e4-f2c1-6-5071f80a3oa5"))
    resourcesSectionImage.position = data.length + 1
    resourcesSectionImage.children = <CardArticleMinList body={resourcesSectionImage.body} />
    data = [...data, resourcesSectionImage]

    const contactForTalent = clone(dataContacUs.find(item => item.id === "contactForTalent"))
    contactForTalent.position = data.length + 1
    data = [...data, contactForTalent]

    useEffect(() => {
        setTypeClient("talent")
        updateMetas({title})
        scrollTop()
    }, [])
    

    return (
        <Box className=" flex col center w100">
            <HelmetComponent data={{title}}/>
            {
                orderArray(data, "position").map(item => {
                    let {id, type} = item
                    if((type === "sectionImage" || type === "sectionContact")){
                        let prevBackgroundColor = data.find(prevItem => prevItem.position === item.position - 1).backgroundColor
                        item.backgroundColor = prevBackgroundColor === "White"?"Gray":"White"
                    }
                    return(
                        type === "banner"?
                            <Banner key={id} data={item} socialMedia />
                        :type === "shortcutsForTalent"? 
                            <SectionElevationTalent key={id} data={item}/>
                        :type === "section" ?
                            <SectionOnlytext key={id} data={item} /> 
                        :type === "sectionImage" ?
                            <SectionImage key={id} data={item}/>
                        :type === "sectionContact" &&
                            <SectionContactUs key={id} data={item}/>
                    )
                })
            }

            <Box style={{position:"absolute", right:0, top:"0px"}}>
                <NavLink to="/company">
                    <Button 
                        onClick={()=>{setTypeClient("company")}}
                        variant="contained" 
                        size="large" 
                        sx={{mt:5, textTransform:"initial", borderRadius:"15px 0 0 15px"}}  
                        color="primary"
                        startIcon={<Work />}
                    >
                        I am an employer
                    </Button>
                </NavLink>
            </Box>


            {/* <SectionContactTalent type='White'/> */}
            <Section nmh>
                <SectionFeaturedIcons />
            </Section>
            
            <SectionTestimonials type="talent"/>
        </Box>
    );
}


import { Autocomplete, Badge, Box, Button, Checkbox, Chip, IconButton, TextField, Typography } from '@mui/material';
import { Backspace, CheckBox, CheckBoxOutlineBlank, DeleteSweep, LayersClear, Search, Tune} from '@mui/icons-material';
import './menuAsideJobs.css'
import { useState, useEffect, useContext} from 'react';
import { useNavigate } from 'react-router-dom';
import Context from '../../contexts/Context';
import { separeArray } from '../../services/serviceslocal';


export default function MenuAsideJobs({keyLocation, arrayJobs, jobsFilters, arrayParams}) {

    const {onPathname} = useContext(Context)
    let navigate = useNavigate();
    const {keywords, locations, titleNumber, industry, division} = arrayParams

    const [arrayParamsKeywords, setArrayParamsKeywords] = useState(keywords)
    const [arrayParamsLocations, setArrayParamsLocations] = useState(locations)
    const [arrayParamsTitleNumber, setArrayParamsTitleNumber] = useState(titleNumber)
    const [arrayParamsIndustry, setArrayParamsIndustry] = useState(industry)
    const [arrayParamsDivision, setArrayParamsDivision] = useState(division)
    
    const hasFilter =  keywords.length + locations.length + titleNumber.length + industry.length + division.length 
    useEffect(() => {
        closeMenuAside()
        setArrayParamsKeywords([...new Set(keywords)])
        setArrayParamsLocations([...new Set(locations)])
        setArrayParamsTitleNumber([...new Set(titleNumber)])
        setArrayParamsIndustry([...new Set(industry)])
        setArrayParamsDivision([...new Set(division)])
    }, [keyLocation])

    // const [keywords, setKeywords] = useState(arrayParamsKeywords?arrayParamsKeywords:[])
    // const [keylocations, setKeylocations] = useState(arrayParamslocations?arrayParamslocations:[])
    
    
    //jobsByJobNumber
    const jobsByJobNumber = [...new Set(arrayJobs.map((item) => item.TR1__Job_Number__c))].filter(item => item !== null)
    const jobsByName = [...new Set(arrayJobs.map((item) => item.Title_Website))].filter(item => item !== null)
    const arrayByTitleAndNumber = [...new Set([ ...jobsByJobNumber, ...jobsByName].sort())]
    
    //jobsByIndustry & jobsByDivision
    const jobsByIndustry = [...new Set(arrayJobs.map((item) => item.TR1__Industry__c))].filter(item => item !== null).sort()
    const jobsByDivision = [...new Set(arrayJobs.map((item) => item.TR1__Division__c))].filter(item => item !== null).sort()
    
    //arraylocations
    // const arrayCountry = [...new Set(arrayJobs.map((item) => item.Country__c))].filter(item => item !== null)
    // const arrayCity = [...new Set(arrayJobs.map((item) => item.TR1__City__c))].filter(item => item !== null)
    // const arrayStateArea = [...new Set(arrayJobs.map((item) => item.TR1__State_Area__c))].filter(item => item !== null)
    // const arrayJoinLocation = [...arrayCountry,...arrayCity, ...arrayStateArea].sort()
    // const arrayBylocations = [...new Set(arrayJoinLocation)];
    const arrayCountry = [...new Set(arrayJobs.map((item) => item.Country__c))].filter(item => item !== null).sort()
    const arrayCity = [...new Set(arrayJobs.map((item) => item.TR1__City__c))].filter(item => item !== null).sort()
    const arrayStateArea = [...new Set(arrayJobs.map((item) => item.TR1__State_Area__c))].filter(item => item !== null).sort()
    const arrayJoinLocation = [...arrayCountry,...arrayCity, ...arrayStateArea].sort()
    const arrayBylocations = [...new Set(separeArray(arrayJoinLocation).sort())];
    
    
    const getKeywordsByCategoryMultiple = (arrayKeys) => {
        let arrayTemp = []
        arrayKeys.forEach(key => {
            arrayTemp = [...arrayTemp, ...getKeywordsByCategory(key)]
        });
        return(arrayTemp)
    }

    const getKeywordsByCategory = (key) => { 
        let arrayTemp = []
        keywords.forEach(param => {
            const arrayJobsNoNull = jobsFilters.filter(item => item[key] !== null) 
            let result = arrayJobsNoNull.filter(item => item[key].toLowerCase().includes(param))
            if(result.length > 0){
                arrayTemp = [...arrayTemp, ...result]
            } 
        });
        return([...new Set(arrayTemp.map(item => item[key]))])   
    }

    // distribution keywords
    useEffect(() => {
      if(arrayParamsKeywords.length > 0){
        setArrayParamsTitleNumber(getKeywordsByCategoryMultiple(['TR1__Job_Number__c', 'Title_Website']))
        setArrayParamsIndustry(getKeywordsByCategory('TR1__Industry__c'))
        setArrayParamsDivision(getKeywordsByCategory('TR1__Division__c'))
      }
    }, [arrayParamsKeywords])
    
    
    const formatQueryString = (key, arrayParameters) => {
        let arrayKeys = arrayParameters.map(element => (`${key}=${element.toLowerCase()}`));
        return(arrayKeys.join('&'))     
    }

    const submitHandle= () => {     
        let path = onPathname === "/find-a-job" || onPathname === "/find-a-job/"?"jobs":onPathname     
        let newParamLocations = arrayParamsLocations.length > 0?`${formatQueryString("locations", arrayParamsLocations)}&`:""
        let newParamTitleNumber = arrayParamsTitleNumber.length > 0?`${formatQueryString("titleNumber", arrayParamsTitleNumber)}&`:""
        let newParamIndustry = arrayParamsIndustry.length > 0?`${formatQueryString("industry", arrayParamsIndustry)}&`:""
        let newParamDivision = arrayParamsDivision.length > 0?`${formatQueryString("division", arrayParamsDivision)}&`:""
        let string = `${path}?${newParamLocations}${newParamTitleNumber}${newParamIndustry}${newParamDivision}`
        string = string.substr(-1) === "&"?string.substring(0, string.length - 1):string
        navigate(string)
    }

    const closeMenuAside = () => {
        document.getElementsByClassName("menuAsideJobs")[0].classList.remove("menuAsideJobs_active");
    }
    
    return (
        <>
        <Box className="menuAsideJobs_btn flex row center" sx={{height:"56px"}}>
            <IconButton
                onClick={()=>{document.getElementsByClassName("menuAsideJobs")[0].classList.toggle("menuAsideJobs_active");}}            
            >
                
                <Badge color="primary" variant={hasFilter > 0?'dot':'standard'}>
                    <Tune sx={{marginRight:"auto"}} color="white" />
                </Badge>
            </IconButton>
        </Box>
           <Box className="menuAsideJobs" sx={{p:2, pt:0}}>
                <Box className="flex row center" sx={{height:"56px"}}>
                        <Typography variant="h5" component="h2" className="w100 tbold tl" color="text.white" >
                            Filters
                        </Typography>
                    <Tune sx={{marginRight:"auto"}} color="white" onClick={()=>{closeMenuAside()}} className="btnToggleFilters"/>
                </Box>
                <Box sx={{py:2}}>
                    <Autocomplete
                        multiple
                        freeSolo
                        id="inputKeywords"
                        className="menuAsideAutocomplete"
                        clearOnEscape
                        size="small"
                        value={arrayParamsTitleNumber}
                        options={arrayByTitleAndNumber}
                        onChange={(event, value) => setArrayParamsTitleNumber(value)}
                        // disableCloseOnSelect
                        color="primary"
                        placeholder='location'
                        getOptionLabel={(option) => option}
                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                                <Chip 
                                    size="small"
                                    variant="filled" 
                                    label={option} 
                                    {...getTagProps({ index })} 
                                    sx={{backgroundColor:"var(--white)"}}                                        
                                />
                            ))
                        }
                        renderInput={(params) => (
                            <TextField 
                                className="jobs_input-filter w100"
                                color="primary"
                                {...params} 
                                variant="standard"
                                label="Job title, number Job, keywords" 
                                // placeholder="Location" 
                            />
                        )}
                    />

                    <Autocomplete
                        multiple
                        id="inputLocations"
                        className="menuAsideAutocomplete"
                        value={arrayParamsLocations}
                        options={arrayBylocations}
                        // disableCloseOnSelect
                        color="primary"
                        sx={{mt:2}}
                        onChange={(event, value) => setArrayParamsLocations(value)}
                        getOptionLabel={(option) => option}
                        renderOption={(props, option, { selected }) => (
                            <li className="option" {...props}>
                                <Checkbox
                                    icon={<CheckBoxOutlineBlank fontSize="small" />}
                                    checkedIcon={<CheckBox fontSize="small" />}
                                    sx={{p:0, mr:1}}
                                    checked={selected}
                                />
                                {option}
                            </li>
                        )}
                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                                <Chip 
                                    size="small"
                                    variant="filled" 
                                    label={option} 
                                    {...getTagProps({ index })} 
                                    sx={{backgroundColor:"var(--white)"}}                                        
                                />
                            ))
                        }
                        renderInput={(params) => (
                            <TextField 
                                className="jobs_input-filter w100"
                                color="primary"
                                size="small"
                                {...params} 
                                variant="standard"
                                label="Location" 
                                // placeholder="Location" 
                            />
                        )}
                    />
                    <Autocomplete
                        multiple
                        id="inputIndustry"
                        className="menuAsideAutocomplete"
                        value={arrayParamsIndustry}
                        options={jobsByIndustry}
                        color="primary"
                        sx={{mt:2}}
                        onChange={(event, value) => setArrayParamsIndustry(value)}
                        getOptionLabel={(option) => option}
                        renderOption={(props, option, { selected }) => (
                            <li {...props}>
                                <Checkbox
                                    icon={<CheckBoxOutlineBlank fontSize="small" />}
                                    checkedIcon={<CheckBox fontSize="small" />}
                                    sx={{p:0, mr:1}}
                                    checked={selected}
                                />
                                {option}
                            </li>
                        )}
                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                                <Chip 
                                    size="small"
                                    variant="filled" 
                                    label={option} 
                                    {...getTagProps({ index })} 
                                    sx={{backgroundColor:"var(--white)"}}                                        
                                />
                            ))
                        }
                        renderInput={(params) => (
                            <TextField 
                                className="jobs_input-filter w100"
                                color="primary"
                                {...params} 
                                variant="standard"
                                label="Industry" 
                                // placeholder="Location" 
                            />
                        )}
                    />
                    <Autocomplete
                        multiple
                        id="inputDivision"
                        className="menuAsideAutocomplete"
                        value={arrayParamsDivision}
                        options={jobsByDivision}
                        // disableCloseOnSelect
                        color="primary"
                        sx={{mt:2}}
                        onChange={(event, value) => setArrayParamsDivision(value)}
                        getOptionLabel={(option) => option}
                        renderOption={(props, option, { selected }) => ( 
                            <li {...props}>
                                <Checkbox
                                    icon={<CheckBoxOutlineBlank fontSize="small" />}
                                    checkedIcon={<CheckBox fontSize="small" />}
                                    sx={{p:0, mr:1}}
                                    checked={selected}
                                />
                                {option}
                            </li>
                        )}
                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                                <Chip
                                    size="small"
                                    variant="filled" 
                                    label={option} 
                                    {...getTagProps({ index })} 
                                    sx={{backgroundColor:"var(--white)"}}                                        
                                />
                            ))
                        }
                        renderInput={(params) => (
                            <TextField 
                                className="jobs_input-filter w100"
                                color="primary"
                                {...params} 
                                variant="standard"
                                label="Division" 
                                // placeholder="Location" 
                            />
                        )}
                    />
                </Box>

                <Box className="wrap_menuAside_buttonSearch w100 flex center" mt={1}>

                <Button
                    color="white" 
                    size='smaill'
                    variant='text'
                    startIcon={<DeleteSweep/>}
                    sx={{borderRadius:"50px", color:"var(--white)"}}
                    onClick={()=>{navigate('/find-a-job/jobs')}} 
                >
                    {/* <Typography  sx={{textTransform:"none", fontSize:"14px"}} noWrap  component="span" >
                        Clear
                    </Typography> */}

                </Button>    

                    <Button
                        color="white" 
                        size='smaill'
                        variant='contained'
                        startIcon={<Search/>}
                        sx={{borderRadius:"50px", color:"var(--primary)", ml:"auto"}}
                        onClick={()=>{submitHandle()}} 
                    >
                        <Typography  sx={{textTransform:"none", fontSize:"14px"}} noWrap  component="span" >
                            Search
                        </Typography>

                    </Button>

                    {/* <Button variant='contained'   sx={{textTransform:"none", borderRadius:"50px"}}>Apply now</Button> */}
                    {/* <Box className="menuAside_buttonSearch">
                        <IconButton 
                            color="primary" 
                            size='large'
                            onClick={()=>{submitHandle()}}                    
                        >
                            <Search/>
                        </IconButton>
                    </Box>         */}
                </Box>
            </Box>
        </>
    );
}

export const preloadImages = async (siteContents) => {
  const itemsFiltered = siteContents.filter(item => (!["miscellaneous", 'terms'].includes(item.id)))
  const itemsFiltersImages = itemsFiltered.filter(item => item.data[0].media?.type === "image")
  const items = itemsFiltersImages.map(item => item.data[0].media.url)
  const containerimages = window.document.getElementById("preloadedImageContainer")
  
    for (const [index, urlImg] of items.entries()){
    const IMG = document.createElement('img');
    IMG.src = urlImg;
    IMG.style = 'display: none';
    containerimages?.appendChild(IMG);
      if(index === 0 || index === 1){
        await IMG.decode();
      }
  }
  return(true)
} 



import { Pagination, useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';
import { scrollToElementById } from '../../services/serviceslocal';
import './paginationCustomized.css'

export default function PaginationCustomized({array, setArray, scrollId, scrollUp, setCurrentInfo, currentPage, setCurrentPage}) {

    const max500 = useMediaQuery("(max-width: 500px)");


    const itemsPerPage = 8;
    const [maxPage, setMaxPage] = useState()
    const [arrayRequire, setArrayRequire] = useState([])
    // const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        if(array.length > 0){
            setArrayRequire(array)
            setMaxPage(Math.ceil(array.length / itemsPerPage))
        }
    }, [array])

       
    function currentData(arrayRequire, currentPage) {
        const begin = (currentPage - 1) * itemsPerPage;
        const end = begin + itemsPerPage;
        return arrayRequire.slice(begin, end);
    }

    useEffect(() => {
        if(arrayRequire.length > 0){
            setArray(currentData(arrayRequire, currentPage))
            if(setCurrentInfo){
                setCurrentInfo(getCurrentInfo(arrayRequire, currentPage))
            }
        }
    }, [currentPage, arrayRequire])


    // useEffect(() => {
    //     setCurrentPage(1)
    // }, [maxPage])
    

    const getCurrentInfo= (arrayRequire, currentPage) => {
        let begin = (currentPage - 1) * itemsPerPage;
        let end = begin + itemsPerPage;
        return(
            {
                begin: begin + 1,
                end: end >= arrayRequire.length?arrayRequire.length:end
            }
        )
    }
    
    // function jump(page) {
    //     const pageNumber = Math.max(1, page);
    //     setCurrentPage(currentPage => Math.min(pageNumber, maxPage));
    // }


    const handleChange = (e, p) => {
        setCurrentPage(p);
        if(scrollId){
            scrollToElementById(scrollId)
        }
        if(scrollUp){
            window.scrollTo({top: 0, behavior: 'smooth'});
        }
        // jump(p); 
    }  

    return (
        !maxPage?
        <></>
        :
        <Pagination
            className='pagination w100 flex center'
            count={maxPage}
            page={currentPage}
            size="large"
            color="primary"  
            onChange={handleChange} 
            sx={{lineHeight:"initial", py:2}} 
            siblingCount={max500?0:1}
        />  
    );
}
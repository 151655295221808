import { Skeleton } from '@mui/material';
import './skeletonList.css'

export default function SkeletonList({num=3, h, w="100%", variant="rectangular", bg="#6161611c"}) {

    var array = Array.apply(null, Array(num));
    
    return (
        <>
        {
            array.map((item, i ) => 
                    <Skeleton 
                        key={i} 
                        variant={variant} 
                        width={w} 
                        height={h} 
                        sx={{my:1, borderRadius:h === 18?1:2, bgcolor:bg}} 
                    />
               )
        }
        </>
    );
}
import { useState, useEffect, useRef } from "react";
import './listDragArticle.css'
import { Button, IconButton, Box } from "@mui/material";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { DragIndicator } from "@mui/icons-material";
import RichEditor from "../richEditor/RichEditor";
import ImageSimple from "../imageSimple/ImageSimple";
import Iframe from "../iframe/Iframe";

function ListDragArticle({textBody, setTextBody, fixed}) {
  // console.log(textBody)

  // const defaultList = ["A", "B", "C", "D", "E"];

  // React state to track order of items
  const [itemList, setItemList] = useState([]);

  useEffect(() => {
    
    if(textBody && itemList){
      if(textBody.length !== itemList.length){
        setItemList(textBody)
      }
    }
  }, [textBody, itemList])

  const handleDrop = (droppedItem) => {
    // Ignore drop outside droppable container
    if (!droppedItem.destination) return;
    var updatedList = [...itemList];
    // Remove dragged item
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
    // Add dropped item
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
    // Update State
    setItemList(updatedList);
  };

  useEffect(() => {
    setTextBody(itemList)
  }, [itemList])
  

  return (
    <Box className="listDrag w100">
      {itemList?.length > 0 &&
      <DragDropContext onDragEnd={handleDrop}>
        <Droppable isDropDisabled={fixed} droppableId="list-container w100">
          {(provided) => (
            <Box
              className="list-container w100"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {itemList.map((item, index) => (
                <Draggable isDragDisabled={fixed} key={item.id} draggableId={item.id} index={index}>
                  {(provided) => (
                    <Box
                      className="item-container"
                      ref={provided.innerRef}
                      {...provided.dragHandleProps}
                      {...provided.draggableProps}
                    >
                      {
                        item.type === "text"? 
                          <RichEditor
                              key={item.id} 
                              item={item} 
                              setTextBody={setTextBody}
                              textBody={textBody}
                              fixed={fixed}
                          />
                        :item.type === "image"? 
                          <ImageSimple
                              key={item.id} 
                              item={item} 
                              setTextBody={setTextBody}
                              textBody={textBody}
                              fixed={fixed}
                          />
                        :
                          <Iframe
                              key={item.id} 
                              item={item} 
                              setTextBody={setTextBody}
                              textBody={textBody}
                              fixed={fixed}
                          />
                      }
                    </Box>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </DragDropContext>
      }
    </Box>
  );
}

export default ListDragArticle;

import { useEffect, useState, useContext } from 'react'
import './sectionTestimonials.css'
import CardTestimony from './cardTestimony/CardTestimony'
import { Box, Button, Link, Typography, useMediaQuery } from '@mui/material'
import Context from '../../contexts/Context';
import reviewsJson from '../../static/reviewsJson.json'
import ButtonAction from '../buttonAction/ButtonAction';

const SectionTestimonials = ({type}) => {

    
    const portrait = useMediaQuery("(orientation: portrait)");


    const {siteContents} = useContext(Context)
    const {data} = siteContents.find(item => item.id === "miscellaneous")
    const {title_for_client, title_for_talent, medias, button} = data.find(item => item.id === "reviews")
    
    const [backgroundVisible, setBackgroundVisible] = useState(medias[1])
    const [arrayReviews, setArrayReviews] = useState([])
    const [changedBackground, setChangedBackground] = useState(true);

    useEffect(() => {
     if(type && reviewsJson){
        setArrayReviews(reviewsJson.filter(item => item.emisor === type))
     }
    }, [reviewsJson, type])


    const getPositionBackground = () =>{
        let random = Math.floor(Math.random()* Number(medias.length))
        setBackgroundVisible(medias[random])
        setChangedBackground((changedBackground) => !changedBackground);
    }

    useEffect(() => {
        const intervalId =
            setInterval(() => {getPositionBackground()}, 5000);
        return () => {
            clearInterval(intervalId);
        };
    }, [changedBackground]);

    return (
        <Box
            className="sectionTestimonials w100 flex col center"
            id="sectionTestimonials"
            style={{backgroundImage: `url(${backgroundVisible.url})`, transition:"2s", backgroundAttachment:portrait?"initial":"fixed"}}
            sx={{p:2, paddingY:4}}
            >
                 <Typography
                    variant="h3"
                    component="h1"
                    className="sectionTestimonials_title tbold w100 mw1000"
                    color="black"
                    sx={{zIndex:1}}>
                    {type === "client" && title_for_client}
                    {type === "talent" && title_for_talent}
                </Typography>
                <Box className="containerTestimonials w100 mw1000 flex row center"  sx={{my:2}}>
                    {
                        arrayReviews.slice(0, 3).map((item, index) =><CardTestimony key={index} item={item}/>)
                    }
                </Box>
                {button.active &&
                    <Box mt={4}>
                        <ButtonAction variant="contained" size="large" action={button.action} sx={{borderRadius:"50px"}}>
                            {button.title}
                        </ButtonAction>
                    </Box>
                }
        </Box>
    )
}

export default SectionTestimonials

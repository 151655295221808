import { createTheme } from '@mui/material/styles';

export const paletteDefault = {
    primary:'#DB2C24',
    secondary:'#ABABAB',
    pharlap:'#A48484',
    coralTree:'#AC7C74',
    gray:'#F1F1F1',
    white:'#ffffff',
    black:'#000000d4',
    invisibly :'red',
    dark :'#616161',
    linkedin :'#115AB0',
    campaign :'#590000',
}


export const themeEditor = () => {

    let root = document.documentElement;
    let palette = {}
    for (const property in paletteDefault) {
        palette[property] = {main:paletteDefault[property]}
        root.style.setProperty(`--${property}`, `${paletteDefault[property]}`)
    }
    
    palette.text = {primary: paletteDefault.dark, white: paletteDefault.white}
    palette.dark =  paletteDefault.dark
    
    const theme = createTheme({palette})

    return(theme)
} 

export const addDeveloperByLink = () => {
    const developerBy = document.getElementById('developerBy');
    if (!developerBy) {
        const itemsFooterEnd = document.getElementById('itemsfooterEnd');
      if (itemsFooterEnd) {
        const newLink = document.createElement('a');
        newLink.id = 'developerBy';
        newLink.href = 'https://hector-madariaga.web.app/';
        newLink.target = '_blank';
        newLink.rel = 'noopener noreferrer';
        newLink.style.cssText = 'cursor: pointer; white-space: nowrap;';

        const typography = document.createElement('span');
        typography.textContent = 'Developed by Hector Madariaga';
        typography.style.cssText = 'margin-left: 8px; margin-right: 8px; font-size: 0.875rem; font-family: "Roboto"';

        newLink.appendChild(typography);
        itemsFooterEnd.appendChild(newLink);
      }
    }
}
import { AddLink, Title } from '@mui/icons-material';
import { Box, Typography, Button, Chip, TextField, Link } from '@mui/material';
import { useState } from 'react';
import uuid from 'react-uuid'
import './sourcesResources.css'
export default function SourcesResources({sources, setSources, fixed}) {


    const [title, setTitle] = useState("")
    const [link, setLink] = useState("")

    const handleDelete = (item) => {
        setSources( prevState => {
            const result = prevState.filter(source => source !== item);
            return (result)
          })    
    }
    const handleAdd = () => {
        if(title && link){
            setSources([...sources, ...[{title:title, link:link}]])
            setTitle("")
            setLink("")
        }
    }

    if(fixed && sources.length === 0){
        return(<></>)
    }
    

    return (
        <Box p={2} className={`sourcesResources flex w100 mw1000 ${fixed?"row aicenter":"col"}`}>
            <Typography 
                component="h3" 
                textAlign="left"
                color="dark"
                className="flex aicenter"
                sx={{marginY:"2.5px", marginRight:"2.5px", height:"32px", marginBottom:"auto"}}
                
            >
                {fixed?"Sources:":"Add source"}
            </Typography>
            <Box direction="row" spacing={1} sx={{ flexWrap: 'wrap' }}>
                {sources.map((item) => {
                    item.id = uuid()
                    return( 
                        <Link
                            key={item.id}                        
                            target="_blank" 
                            style={{display:"contents"}}
                            href={fixed?item.link:undefined} 
                            rel="noreferrer" 
                            underline="none"

                        >
                            <Chip 
                                style={{cursor:fixed?"pointer":"default"}} 
                                label={item.title} 
                                title={item.link}
                                onDelete={fixed?undefined:() => handleDelete(item)} 
                                sx={{marginY:"2.5px", marginRight:"2.5px"}}
                            />
                        </Link>
                    )})
                }
            </Box>
            
            {!fixed &&
                <Box className="flex row" my={1}>
                    <Box className="flex center row">
                        <Title sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                        <TextField 
                            id="inputTileSource" 
                            placeholder="Title" 
                            variant="standard"
                            value={title}
                            onChange={({target}) => setTitle(target.value)}
                        />
                    </Box>
                    <Box className="flex center row w100">
                        <AddLink sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                        <TextField 
                            id="inputTileSource" 
                            placeholder="Add Link"
                            type="url"
                            variant="standard" 
                            fullWidth
                            value={link} 
                            onChange={({target}) => setLink(target.value)}
                        />
                    </Box>
                    <Button 
                        size="small" 
                        variant="text" 
                        color="primary"
                        onClick={handleAdd}
                    >
                        Add
                    </Button>
                </Box>
            }
        </Box>
    );
}


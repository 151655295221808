
import { useEffect, useState } from 'react';
import {Box, Card, CardContent, Button, Typography, TextField, FormControl, IconButton, ButtonBase} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import './log.css'
import conference from '../../../assets/images/conference.jpg'
import logoWhite from '../../../assets/images/logoAsapWhite.png'
import { auth } from '../../../services/firebaseConfig';
import { useLocation, useNavigate } from 'react-router-dom';
import ModalAlert from '../../../components/modalAlert/ModalAlert'
import { updateMetas } from '../../../services/serviceslocal';
import HelmetComponent from '../../../components/helmetComponent/HelmetComponent';

export default function Log() {
    
    const [helmetData, setHelmetData] = useState()
    useEffect(() => {
        updateMetas({title:"Login"})
        setHelmetData({title:"Login"})
    }, [])
    

    let {pathname, search} = useLocation();
    let params = new URLSearchParams(search)
    const action = params.get('action');
    let navigate = useNavigate();

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [showPassword, setShowPassword] = useState(false)
    const [typeAction, setTypeAction] = useState(action === null?"login":action)
    const [modalActive, setModalActive] = useState(false)
    const [errorMessage, setErrorMessage] = useState(false)
    const [modalErrorActive, setModalErrorActive] = useState(false)
    
    const logIn = async () =>{
        auth.signInWithEmailAndPassword(email, password)
        .then(userCredential => {
            // console.log(userCredential)
        })
        .catch(error => {
            setErrorMessage(error.message.replaceAll('Firebase: ', ''))
            setModalErrorActive(true)
        })
    }

    const resetPassword = () => {
        auth.sendPasswordResetEmail(email)
        .then(() => {
            setModalActive(true)
        })
        .catch((error) => {
        //   let errorCode = error.code;
        setErrorMessage(error.message.replaceAll('Firebase: ', ''))
        setModalErrorActive(true)
        });
    }


    const submitHandle = (e) => {
        e.preventDefault()
        if(typeAction === "login"){
            return(logIn())
        }else{
            return(resetPassword())
        }
    }
    
    const changeAction = () => {
        setTypeAction(typeAction === "login"?"reset_password":"login")
        navigate(`${pathname}?action=${typeAction === "login"?"reset_password":"login"}`)
        setEmail("")
        setPassword("")
    }
    

    return (
        <Box className="log flex row center">
            {helmetData && <HelmetComponent data={helmetData}/>}
            <ModalAlert
                title="Email sent" 
                modalActive={modalActive} 
                setModalActive={setModalActive}
                onClick={() => {navigate("/admin"); changeAction()}}
            >
                <Typography paragraph className="tl" variant="body2" component="h2" color="dark">
                    We have sent an email to {email}
                </Typography>
            </ModalAlert>

            {/* error */}
            <ModalAlert
                // title="Try resetting your password again" 
                modalActive={modalErrorActive} 
                setModalActive={setModalErrorActive}
                // onClick={() => {navigate("/admin"); changeAction()}}
            >
                <Typography paragraph className="tl" variant="body2" component="h2" color="dark">
                    {errorMessage}
                    <br/>
                    <br/>
                    Please check your email or contact the website administrator
                </Typography>
            </ModalAlert>

            <Box className="containerTitleLog flex col center" sx={{p:5}}>
                <Typography 
                    variant="h2" 
                    component="h1" 
                    className="tbold"
                    align="center"
                >
                    Login Sesion
                </Typography>

                <Typography 
                    component="h2" 
                    variant="h5" 
                    align="center"
                >
                    Please enter your login credentials below
                </Typography>

                <div className="imageHeader flex col center">
                    <img alt="Log" src={conference}/>
                </div>
            </Box>

            <Box className="containerFormLog flex col center" p={{ xs: 1, md: 1 }}>
                <Box sx={{my:2}}>
                    <img alt="Log" src={logoWhite} className="logoForm"/>
                </Box>
                
                <Card sx={{ maxWidth:400, p:3, borderRadius:"24px"}}>
                    <CardContent>
                        <form onSubmit={submitHandle}>
                            <FormControl sx={{ my: 1}} variant="standard" fullWidth>
                                <TextField 
                                    id="email" 
                                    label="Email"
                                    required
                                    variant="standard" 
                                    value={email}
                                    fullWidth
                                    onChange={({target})=>{setEmail(target.value)}}
                                />                     
                            </FormControl>
                            
                            {typeAction === "reset_password"?
                                <Typography paragraph className="tcenter" variant="body2" color="dark" mt={2}>
                                    Don't worry! Just fill in your email and we'll send you a link to reset your password.
                                </Typography>
                            :
                                <FormControl sx={{ my: 1}} variant="standard" fullWidth>
                                    <TextField 
                                        type={showPassword ? 'text' : 'password'}
                                        id="password" 
                                        label="Password" 
                                        required
                                        variant="standard" 
                                        value={password}
                                        fullWidth
                                        onChange={({target})=>{setPassword(target.value)}}
                                    />
                                    <Box className="actionInput">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setShowPassword(!showPassword)}
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </Box>
                                </FormControl> 
                            }
                            
                            <Box sx={{mt:5, mb:1}}>
                                <Button 
                                    variant="contained" 
                                    size="large" 
                                    fullWidth
                                    type="submit"
                                    >
                                    {typeAction === "reset_password"?"Send Password Reset Link":"Login"}
                                </Button>
                            </Box>
                        </form>

                        <Box className="flex center w100">
                            <ButtonBase 
                                onClick={changeAction}
                                variant='text'
                                size="large" 
                                color="secondary"
                                >
                                {typeAction === "reset_password"?"Log In":"Forgot Password?"}
                            </ButtonBase>
                        </Box>
                    </CardContent>                   
                </Card>
            </Box>
        </Box>
    );
}

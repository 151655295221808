import { db } from "./firebaseConfig";

// const url = "http://192.168.200.5:5010"
// const url = "https://asap-staffing-services.herokuapp.com"
//const url = "https://asap-staffing-services.onrender.com"
const url = "https://asap-api-a40y.onrender.com"

export const getData =async (type) => {
    const response = await fetch(`${url}/${type}`)
    .then((res) => (res.json()))
    // .catch((error) => {console.log(error); return([])});
    .catch(() => {return([])});
    return(response)
}


export const getDataFB = async (collection) => {
    let arrayTemp = []
    await db.collection(collection).get()
        .then(snapshot => {
            snapshot.forEach(doc => {
                arrayTemp.push(doc.data()) 
            });
            return(true);
        })
        .catch(err => {
            console.log('Error getting documents', err);
            return(false);
        });
    return(arrayTemp)
}
import { useState } from 'react';
import './siteContentsComponents.css'
import { Box, IconButton, Typography } from '@mui/material';
import Card from '../card/Card';
import ModalSiteContentsEditor from './ModalSiteContentsEditor';
import { Dashboard, Edit } from '@mui/icons-material';

export default function SectionServiceEditor({data, setData}) {

    const [modalActive, setModalActive] = useState(false)
    return (
        <Card className="flex col center">
           <Box className="flex row w100 aicenter">
                <Dashboard sx={{mr:1}} />
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} className="Montserrat">
                   {(data.title === "Company" || data.title === "Talent") && "Home"} {data.title}
                </Typography>
                <IconButton onClick={()=>setModalActive(true)}>
                    <Edit color="primary"/>
                </IconButton>
            </Box>

            <ModalSiteContentsEditor modalActive={modalActive} setModalActive={setModalActive} data={data} className="modalSiteContentsEditor"/>
        </Card>


                    
    );
}
import { useState, useEffect, forwardRef} from "react";
import {Button, Dialog, DialogActions , DialogContent , DialogTitle , List, ListItem, ListItemIcon, ListItemText, Slide, Switch, Typography } from "@mui/material";
import ModalTeamCardEditFormInput from "../modalTeamCardEditFormInput/ModalTeamCardEditFormInput";
import "./modalTeamCardEditForm.css";
import { LinkedIn, Mail, Phone } from "@mui/icons-material";

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalTeamCardEditForm({item, configContact, setConfigContact, modalActive, setModalActive}) {
    
    const handleClose = () => {
        setModalActive(false);
    };

    const [email, setEmail] = useState(item.Email);
    const [linkedin, setLinkedin] = useState(item.LinkedIn_Profile__c)
    const [phone, setPhone] = useState(item.Phone);
   
    // useEffect(() => {
    //     setContact({email, linkedin, phone})
    // }, [email, linkedin, phone])
    
    
    const cancel = () => {
        setEmail(item.email)
        setLinkedin(item.linkedin)
        setPhone(item.phone)
        handleClose()
    }
    
    const agree = () => {
        handleClose()
    } 
    const restoreDefault = () => {
        let defaultData = {data:"", active:false}
        setEmail(defaultData)
        setLinkedin(defaultData)
        setPhone(defaultData)
    } 
    

    return (
        <div>
            <Dialog
                open={modalActive}
                fullWidth={true}
                maxWidth="xs"
                TransitionComponent={Transition}
                className="modalTeamCardEditForm"
            >
                <DialogTitle>Contact</DialogTitle>
                <DialogContent>
                <List>
                    <ListItem disablePadding sx={{my:1}}>
                        <ListItemIcon>
                            <Mail color={item.Email?"primary":"secondary"}/>
                        </ListItemIcon>
                        <ListItemText primary={item.Email?item.Email:"Undefined in Salesforce"} className="listItemText"/>
                        <Switch
                            disabled={!item.Email}
                            checked={item.Email} 
                            size="small"
                            sx={{marginLeft:2}}
                            // onChange={({target}) => setState({...state, active:target.checked})}
                        />
                    </ListItem>
                    <ListItem disablePadding sx={{my:1}}>
                        <ListItemIcon>
                            <LinkedIn color={item.LinkedIn_Profile__c?"primary":"secondary"}/>
                        </ListItemIcon>
                        <ListItemText primary={item.LinkedIn_Profile__c?item.LinkedIn_Profile__c:"Undefined in Salesforce"} className="listItemText"/>
                        <Switch
                            disabled={!item.LinkedIn_Profile__c}
                            checked={item.LinkedIn_Profile__c} 
                            size="small"
                            sx={{marginLeft:2}}
                            // onChange={({target}) => setState({...state, active:target.checked})}
                        />
                    </ListItem>
                    <ListItem disablePadding sx={{my:1}}>
                        <ListItemIcon>
                            <Phone color={item.Phone?"primary":"secondary"}/>
                        </ListItemIcon>
                        <ListItemText primary={item.Phone?item.Phone:"Undefined in Salesforce"} className="listItemText"/>
                        <Switch
                            disabled={!item.Phone}
                            checked={item.Phone} 
                            size="small"
                            sx={{marginLeft:2}}
                            // onChange={({target}) => setState({...state, active:target.checked})}
                        />
                    </ListItem>
                </List>
                    {/* <ModalTeamCardEditFormInput 
                        type ="email"
                        state={email}
                        setState={setEmail}                                      
                    />
                    <ModalTeamCardEditFormInput 
                        type ="linkedin"
                        state={linkedin}
                        setState={setLinkedin}                                      
                    />
                    <ModalTeamCardEditFormInput 
                        type ="phone"
                        state={phone}
                        setState={setPhone}                                      
                    /> */}
                </DialogContent>
                <DialogActions>
                    <Button onClick={restoreDefault} color="secondary" sx={{marginRight:"auto"}}>Restore default</Button>
                    <Button onClick={cancel} color="secondary">Cancel</Button>
                    <Button onClick={agree}>I Agree</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
    }

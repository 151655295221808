import { useEffect, useState } from 'react';
import { Box} from '@mui/material';
import ModalAlert from '../modalAlert/ModalAlert';
import uuid from 'react-uuid'
import ListDragEditor from './ListDragEditor';
import FabButtonEditor from './FabButtonEditor';
import { sendDataToAPI, sendJsonToAPI } from '../../services/requestsToAPI';
import BackdropLoading from '../backdropLoading/BackdropLoading';

export default function ModalSiteContentsEditor({modalActive, setModalActive, data, className}) {


    const [isLoading, setIsLoading] = useState(false)

    const onClickFabButtonEditor = async (action) => {
        if(action === "Save changes"){
            // console.log(data)
            console.log(JSON.stringify(data)) 
            if(data.id === "services"){
                let dataPageService = {...data, data:data.data.filter(item => item.type !== "service")}
                uploadData(dataPageService)
                return
            }
            if(data.id === "terms"){
                let dataPageService = {...data, data:data.data.filter(item => item.type !== "term")}
                uploadData(dataPageService)
                return
            }
            uploadData(data)
        }
        if(action === "Add section"){
            return(setAddNewSection(data.id))
            // return(setPageSelected(prevState => ({...prevState, data: [...prevState.data, emptySection]})))
        }
        if(action === "back"){
            return(handleClick(false))
        }
        if(action === "Generate Code"){
            console.log(uuid())
        }
    }

    const uploadData = async (data) => {
        for (const item of data.data){
            if(item.media?.active && item.media.url.includes('base64')){
                setIsLoading("media file from site content")
                item.media.url = await sendDataToAPI("/siteContents/uploadImage", {id:item.id, base64:item.media.url, collection:data.type || data.id})
            }
            if(item.medias?.length > 0){
                for (const [index, mediaFromArray] of item.medias.entries()) {
                    if(mediaFromArray?.active && mediaFromArray.url.includes('base64')){
                        setIsLoading("media file from site content")
                        mediaFromArray.url = await sendDataToAPI("/siteContents/uploadImage", {id:`${item.id}/${index}`, base64:mediaFromArray.url, collection:data.id})
                    }
                }
            }
        }
        setIsLoading("site content")
        let action = data.type === "service"?"addService":data.type === "term"?"addTerm":"addData"
        let response = await sendJsonToAPI(`/siteContents/${action}`, data)
        if(response){
            setIsLoading(false)
            setTimeout(() => setModalActive(false), 2000)
        }
        // console.log(response)
    }

    const handleClick = (value) => {
        setModalActive(false)
    }
    
    const [addNewSection, setAddNewSection] = useState(false)
    const [serviceSelected, setServiceSelected] = useState(data)
    const [title, setTitle] = useState(data.title)


    // useEffect(() => {
    //  console.log(serviceSelected.title)
    // }, [serviceSelected])
    

    
return(
    <ModalAlert
        modalActive={modalActive} 
        setModalActive={setModalActive} 
        title={title} 
        textAction="Cancel" 
        fullScreen sx={{background:"var(--gray)", alignItems:"initial"}}
        className={className}
    >
        <Box className="siteContents flex col aicenter w100 mw900" sx={{px:1, my:2, height:"max-content"}}>
            {serviceSelected.data &&
                <ListDragEditor 
                    array={serviceSelected.data}
                    data={data}
                    pageSelected={serviceSelected} 
                    setPageSelected={setServiceSelected} 
                    addNewSection={addNewSection} 
                    setAddNewSection={setAddNewSection}
                    setTitlePageSelected={setTitle}
                />
            }
        </Box>
        <BackdropLoading openBackDrop={isLoading}/>
        <FabButtonEditor handleClick={onClickFabButtonEditor} data={serviceSelected} />
  </ModalAlert>
)
}
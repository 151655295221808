import { useState, useEffect, useContext } from 'react';
import { Avatar, Box } from '@mui/material';
import './socialMedia.css'
import { FacebookOutlined, Instagram, LinkedIn,  Twitter } from '@mui/icons-material';
import Context from '../contexts/Context';
import ButtonAction from '../components/buttonAction/ButtonAction';

export default function SocialMedia({footer, header, campaign}) {

    const {siteContents} = useContext (Context)
    const {data} = siteContents.find(item => item.id === "contact-us")
    const {buttons} = data.find(item => item.id === "contactSocialMedia")
    
    const [currentArray, setCurrentArray] = useState(buttons)

    useEffect(() => {
      if(campaign){
        const newArray = buttons.filter(item => item.icon !== "linkedin")
        console.log(newArray)
        setCurrentArray(newArray)
      }
    }, [campaign, buttons])
    
    const arrayIcons = {
        "facebook": <FacebookOutlined/>,
        "twitter": <Twitter/>,
        "instagram": <Instagram/>,
        "linkedin": <LinkedIn/>,
    }


    return (
        <Box className={`flex center row wrap ${campaign?"socialMedia-campaign":header?"socialMedia-header":footer?"socialMedia-footer":""}`} sx={{mx:"auto", mt:footer || header?"auto":3}}>
            {currentArray.map((item) =>{
                return(
                    <Box 
                        key={item.icon}
                        className="socialMedia-item bounce" 
                        style={{background:campaign?"none":header?"":"var(--primary)", borderRadius:"50%", width: "fit-content", height: "fit-content"}} 
                        sx={{mx:footer || header?0.5:1}}
                    >
                        <ButtonAction action={item.action} className="contents" >
                            <a href={item.action} target="_blank" rel="noopener noreferrer" >
                                <Avatar sx={{ width: campaign?40: 30, height: campaign?40: 30, background:"transparent", p:1}}  title={item.title} className={campaign?'social-campaign-avatar':''}>
                                    {arrayIcons[item.icon]}
                                </Avatar>
                            </a>
                        </ButtonAction>
                    </Box>
                )
            })}
        </Box>
    );
}


import { useState } from 'react';
import { Box, Checkbox, Input, InputAdornment, Typography } from '@mui/material';
import './siteContentsComponents.css'
import {PushPin } from '@mui/icons-material';
import { useEffect } from 'react';


export default function TitleEditor({data, required, isBannerService, dataGrandpa, setTitleGrandpa}) {
    
    const [title, setTitle] = useState(data?data.title:"")
    const [showTitle, setShowTitle] = useState(data?data.showTitle:true)

    useEffect(() => {
        if(isBannerService){
            dataGrandpa.title = title
            setTitleGrandpa(title)
        }
        data.title = title 
        data.showTitle = showTitle 
    }, [title, showTitle, data, isBannerService, dataGrandpa])
    
    
    return (
        <Box className="siteContents-item flex col  w100">
            <Box className="flex row w100 aicenter">
                <Typography variant="body2" component="span" sx={{mr:1}} className="Montserrat tbold">
                    Title
                </Typography>
            </Box>
            <Input
                type='text'
                className="siteContents-input"
                placeholder="Title"
                fullWidth
                disableUnderline
                value={title}
                onChange={(e)=> {setTitle(e.target.value)}}
                endAdornment={
                    required?"":
                    <InputAdornment position="end">
                        <Checkbox 
                            sx={{p:0}} 
                            icon={<PushPin fontSize='small'/>} 
                            checkedIcon={< PushPin fontSize='small'/>} 
                            checked={showTitle} onChange={(e, value)=>setShowTitle(value)} 
                        />
                    </InputAdornment>
                }
            />
        </Box>          
    );
}
import { useContext, useEffect, useState } from 'react';
import {Box, Button, Pagination, Typography, useMediaQuery } from '@mui/material';
import './jobs.css'
import BreadcrumbsCustomized from '../../../components/breadcrumbsCustomized/BreadcrumbsCustomized';
import CardJob from '../../../components/cardJob/CardJob';
import MenuAsideJobs from '../../../components/menuAsideJobs/MenuAsideJobs';
import { useLocation, useNavigate } from 'react-router-dom';
import { SearchOffOutlined } from '@mui/icons-material';
import Context from '../../../contexts/Context';
import { normalize, orderArrayDesc, scrollTop } from '../../../services/serviceslocal';

// import usePagination from "../../../components/usePagination";
import Card from '../../../components/card/Card';
import PaginationCustomized from '../../../components/paginationCustomized/PaginationCustomized';
import SkeletonList from '../../../components/skeletonList/SkeletonList';


export default function Jobs({arrayJobs, currentPage, setCurrentPage, setIdArrayPagination}) {

    const max500 = useMediaQuery("(max-width:500px)");
    // const  location = useLocation()
    const {onPathname} = useContext(Context)
    let navigate = useNavigate();

    let {key, pathname, search, hash} = useLocation();
    let params = new URLSearchParams(search)
    const keywords = params.getAll('keywords');
    const locations = params.getAll('locations');
    const titleNumber = params.getAll('titleNumber');
    const industry = params.getAll('industry');
    const division = params.getAll('division');

    const [jobsFilters , setJobsFilters] = useState([])
    const [jobsIsFilters , setJobsIsFilters] = useState(false)
    
    useEffect(() => {
        if(arrayJobs.length > 0){
            if(!search){
                setJobsIsFilters(false)
                setJobsFilters(orderArrayDesc(orderArrayDesc(arrayJobs,"TR1__Open_Date__c"), "Today_s_Priority__c"))
                // setJobsFilters(arrayJobs)
                return
            }
                getArrayJobsFilters()
        }
    }, [key, arrayJobs])
    
    
    const getArrayJobsFilters = () => {
        let arrayKeywords = keywords.length > 0?filterbyParams(keywords, ["TR1__Job_Number__c", "Title_Website", "TR1__Division__c", "TR1__Industry__c"]):[]
        let arrayLocations = locations.length > 0?filterbyParams(locations, ["Country__c", "TR1__City__c", "TR1__State_Area__c"]):[]
        let arrayTitleAndNumber = titleNumber.length > 0?filterbyParams(titleNumber, ["TR1__Job_Number__c", "Title_Website"]):[]
        let arrayIndustry = industry.length > 0?filterbyParams(industry, ["TR1__Industry__c"]):[]
        let arrayDIvision = division.length > 0?filterbyParams(division, ["TR1__Division__c"]):[]
        let arrayClean = [...new Set([...arrayKeywords, ...arrayLocations, ...arrayTitleAndNumber, ...arrayIndustry, ...arrayDIvision])]
        setJobsFilters(orderArrayDesc(orderArrayDesc(arrayClean,"TR1__Open_Date__c"), "Today_s_Priority__c"))
        // setJobsFilters(arrayClean)
        setJobsIsFilters(true)
    }

    useEffect(() => {
        if(jobsFilters.length > 0) {
            let string = `${jobsFilters.length}-${jobsFilters[0].TR1__Job_Number__c}-${jobsFilters.at(-1).TR1__Job_Number__c}`
            setIdArrayPagination(string.replaceAll(" ", ""))
        }
    }, [jobsFilters])
    

    const filterbyParams = (arrayParams, arrayKeys) => {
        let arrayTemp = []
        arrayKeys.forEach(key => {
            arrayTemp = [...arrayTemp, ...filterJobsbyCategory(key, arrayParams)]
        });
        return(arrayTemp)
    }

    const filterJobsbyCategory = (key, arrayParams) => {
        let arrayTemp = []
        arrayParams.forEach(param => {
            const arrayJobsNoNull = arrayJobs.filter(item => item[key] !== null)
            let result = arrayJobsNoNull.filter(job => normalize(job[key]).toLowerCase().includes(normalize(param)))
            if(result.length > 0){
                arrayTemp = [...arrayTemp, ...result]
            } 
        });
        return(arrayTemp)
    }

    const [arrayFromPagination, setArrayFromPagination] = useState([])
    const [infoPagination, setInfoPagination] = useState()

    useEffect(() => {
        if(jobsIsFilters && jobsFilters.length === 0){
            scrollTop('smooth')
            setArrayFromPagination([])
        }
    }, [jobsFilters, jobsIsFilters])

    return (
            <Box className=" flex col center w100 wrapJobs">
                <Box className="flex row containerJobs w100">
                    
                    <MenuAsideJobs 
                        keyLocation={key}
                        arrayJobs={arrayJobs} 
                        jobsFilters={jobsFilters}
                        arrayParams={{keywords, locations, titleNumber, industry, division}}
                    />

                    <Box className="jobs flex col w100 minh100">
                        <BreadcrumbsCustomized color="secondary"/>
                        
                        <Box className="jobs flex col w100 mw900"  sx={{p:2, mx:"auto", pt:0}}>    
                            {arrayJobs.length === 0 && 
                                <>
                                    <SkeletonList num={6} h={220} bg="#ffffff" />
                                    <SkeletonList num={1} h={71} bg="#ffffff" />
                                </>
                            }

                            {jobsIsFilters && arrayFromPagination.length > 0 && infoPagination &&
                                <Typography className="w100 tcenter" variant="subtitle1" sx={{color:"var(--white)"}}>
                                    {/* {arrayFromPagination <= 6?
                                        `${arrayFromPagination.length} results`
                                    :
                                        `${arrayFromPagination.length} jobs of ${jobsFilters.length} results`
                                    } */}
                                    {`${infoPagination.begin} - ${infoPagination.end} jobs of ${jobsFilters.length} results`}
                                </Typography>
                            }
                            
                            {arrayFromPagination.length > 0 &&
                                arrayFromPagination.map((item) => {
                                    return(
                                        <CardJob key={item.Id} item={item}/>
                                    )
                                })
                            }

                            {jobsFilters.length > 0 && 
                                <Box className="flex w100" sx={{mt:"auto"}}>
                                    <Card className="cardPagination col w100 center" pagination>
                                        <PaginationCustomized
                                            array={jobsFilters}
                                            setArray={setArrayFromPagination}
                                            setCurrentInfo={setInfoPagination}
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                            scrollUp
                                        /> 
                                    </Card>
                                </Box>
                            }
                            {jobsFilters.length === 0 && jobsIsFilters &&  
                                <Box className="jobs_container_no_find flex col center">
                                    <SearchOffOutlined className="noFind" color="white" sx={{width:"200px", height:"200px"}} />

                                    <Typography className="w100 tcenter" variant="subtitle1" sx={{my: 3, color:"var(--white)"}} >
                                        Can’t find any Jobs.
                                    </Typography>

                                    <Button variant="contained" onClick={() => {navigate(onPathname)}}>
                                        Clear your filters and try again 
                                    </Button>
                                </Box>
                            }
                        </Box>
                    </Box>
                </Box>                  
            </Box>
    );
}


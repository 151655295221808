import { useContext, useEffect, useState } from 'react';
import {Box, Button, Typography} from '@mui/material';
import './team.css'
import { ChevronRight, ExpandMore } from '@mui/icons-material';
import Section from '../section/Section';
import TeamCard from '../teamCard/TeamCard';
import SkeletonList from '../skeletonList/SkeletonList';
import Context from '../../contexts/Context';
import { scrollToElementById } from '../../services/serviceslocal';

export default function Team({cms, currentEmployee, isTeam, setIsOpenDepartament}) {
    
    const {team} = useContext(Context)
    const [arrayDepartment, setArrayDepartment] = useState([])
    const [teamVisible, setTeamVisible] = useState(false)

    useEffect(() => {
        if(team.length > 0){
            setArrayDepartment([...new Set(team.map((item) => {
                if(item.Department === null && item.Id === "00536000001anYcAAI"){
                    item.Department = "Managing Director"
                }
                return(item.Department)
                }
            ))])
        }
    }, [team])
    
    
    useEffect(() => {
        if(teamVisible){
            // let appLocal = document.getElementById("appLocal")
            let elementActive = document.getElementsByClassName("elementActive")[0]
            const y = elementActive.getBoundingClientRect().top + window.pageYOffset - 70;
            window.scrollTo({top: y, behavior: 'smooth'});
            // scrollToElementById(teamVisible?teamVisible.split(" ").join("-").toLowerCase():"")
        }

        if(isTeam){setIsOpenDepartament(teamVisible?true:false)}
     }, [teamVisible, isTeam])
     
    return (
        <Section>
            <Box className="team flex col center w100" sx={{overflow:"hidden"}}>

                {
                    arrayDepartment.length === 0 && <SkeletonList h={100} m={1} num={5}/>
                }

                {
                    arrayDepartment.length > 0 && arrayDepartment.map((deparment) =>{
                        let arrayFind  = team.filter(employee => employee.Department === deparment)
                        return(
                            <Box className={`w100 flex col deparment mx800`} key={deparment} sx={{my:cms?2:0.5}} id={deparment?deparment.split(" ").join("-").toLowerCase():""} >
                                <Button 
                                    sx={{px:2}} 
                                    startIcon={teamVisible === deparment?<ChevronRight color="primary" className="elementActive"/>:<ExpandMore color="primary"/>}
                                    color="secondary"   
                                    onClick={()=>{
                                        setTeamVisible(teamVisible === deparment?false:deparment)
                                    }}                             
                                >
                                    <Typography 
                                        variant="h5" 
                                        component="h2" 
                                        className="tbold tl w100" 
                                        color="dark"
                                    >
                                        {deparment}
                                    </Typography>
                                </Button>
                                <Box 
                                    sx={{px:1}}
                                    className={`flex row w100 containterEmployees ${teamVisible === deparment || cms?"containterEmployeesActive":""}`}
                                >
                                    {arrayFind.map((item) =>{
                                        return(
                                            cms? <TeamCard item={item} key={item.Name} cms={cms}/>
                                            :
                                            <TeamCard item={item} key={item.Name} cms={cms} currentEmployee={currentEmployee} isTeam={isTeam}/>
                                        )
                                    })
                                    }
                                </Box>
                            </Box>
                        )
                    })
                }
            </Box>
        </Section>
    );
}
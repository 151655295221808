import { Edit } from '@mui/icons-material';
import {Box, ButtonBase, Typography} from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ModalTeamCard from '../modalTeamCard/ModalTeamCard';
import ModalTeamCardEdit from '../modalTeamCardEdit/ModalTeamCardEdit';
import ModalTeamCardImage from '../modalTeamCardImage/ModalTeamCardImage';
import './teamCard.css'
import logoAsapSquare from '../../assets/images/asap-default.png'

export default function TeamCard({item, btnDisable, min, cms, currentEmployee, isTeam, openPhoto}) {
    
    // const [modalActive, setModalActive] = useState(isTeam && currentEmployee === item.name.toLowerCase()?true:false)
    const [modalActive, setModalActive] = useState(false)
    const [modalTeamCardImageActive, setModalTeamCardImageActive] = useState(false)
    // const [modalActive, setModalActive] = useState(false)
    const navigate = useNavigate()
    const {pathname} = useLocation()
    const [mediaLoading, setMediaLoading] = useState(true)

    let handleClick = () => {
        // console.log("click" + item.name)
        if(isTeam){
            return(navigate(`/about-us/meet-our-team/${item.Name.replaceAll("-", "---").replaceAll(" ", "-").toLowerCase()}`))
        }else{
            return(setModalActive(true))
        }
    }

    useEffect(() => {
     if(pathname === "/about-us/meet-our-team"){
        setModalActive(false)
     }
    }, [pathname])
    

    useEffect(() => {
        if(isTeam && currentEmployee === item){
            setModalActive(true)
        }
    }, [currentEmployee, item, isTeam])
    

    return (
        <Box 
            className={`teamCard_wrap flex row aicenter ${cms?"teamCard_wrap_cms":""} ${btnDisable?"teamCard_wrap_fixed":""} ${min?"teamCard_wrap_min":""}`} 
            p={{ xs: 2, sm: 1 }}
            px={{ xs: 2, sm: min?1:4 }}
            sx={{opacity:cms && !item.IsActive?0.9:1}}
        >
            <ButtonBase 
                className="teamCard w100 flex row aicenter" 
                color="primary"
                // onClick={() =>openPhoto?setModalTeamCardImageActive(true):btnDisable?setModalTeamCardImageActive(false):handleClick()}                
                onClick={() =>openPhoto?setModalTeamCardImageActive(false):btnDisable?setModalTeamCardImageActive(false):handleClick()}                
            >
                <Box className="flex col jccenter teamCard_containterAvatar">
                    <Box className="flex col center teamCard_Image">
                        {(!item || mediaLoading) && <img src={logoAsapSquare} alt={item.Name} className="mediaDefault"/>}
                        <img src={item.MediumPhotoUrl} alt={item.Name} className={`noSelect ${cms && !item.IsActive?"teamCard_containterAvatar_disable":""}`} onLoad={({isTrusted}) => setMediaLoading(!isTrusted)} onError={({isTrusted})=> setMediaLoading(isTrusted)}/>
                    </Box>
                    <Box className="backgroundAvatar"></Box>
                </Box>
                <Box className="teamCard_containerBody flex col" sx={{pl:2}} translate="no">
                    <Typography 
                        variant="h5" 
                        component="h2" 
                        className="tbold tl w100" 
                        color={cms && !item.IsActive?"dark":"primary"}
                        sx={{linHeight: "100%"}}
                    >
                        {item.Name} 
                    </Typography>
                    <Typography 
                        variant="body" 
                        component="span" 
                        className="tbold tl w100" 
                        color="dark"
                    >
                        {item.Title}
                    </Typography>
                    {cms && <Edit className="teamCard_containerBody_iconEdit" color="primary" fontSize="small"/>}
                </Box>
            </ButtonBase>
            {cms?
                <ModalTeamCardEdit 
                    cms={cms}
                    item={item} 
                    modalActive={modalActive} 
                    setModalActive={setModalActive}
                />
            :
                <ModalTeamCard 
                    currentEmployee={currentEmployee}
                    cms={cms}
                    item={item} 
                    modalActive={modalActive} 
                    setModalActive={setModalActive}
                />
            } 

            <ModalTeamCardImage image={item.MediumPhotoUrl} modalActive={modalTeamCardImageActive} setModalActive={setModalTeamCardImageActive} />
        </Box>
    );
}
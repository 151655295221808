import { Box, Typography } from '@mui/material';
import './banner.css'
import {Fade} from 'react-awesome-reveal';
import SocialMedia from '../../socialMedia/SocialMedia';
import ButtonAction from '../buttonAction/ButtonAction';
import asapDefault from '../../assets/images/asap-default.png'
import useScreenSize from '../resize/resize';

export default function Banner({data, socialMedia}) {
    const {height: heightHook, width: widthHook} = useScreenSize()
    const height = heightHook > widthHook? heightHook: 'auto' 
    const width = heightHook > widthHook? 'auto': widthHook

    const {title, subtitle, media, button, showTitle} = data

     return (
        <Box className="banner flex col center w100" id={data.id}>
            
            {socialMedia && <SocialMedia header/>}
            
            <Box className="banner-background flex col center">
                {!media.url && <img loading="eager" alt={"banner"} src={asapDefault}  width={width} height={height}/>}
                {media.type === "image" && <img loading="eager" alt={title?title:"banner"} src={media.url}  width={width} height={height} id="imageBanner"/>}
                {media.type === "video" && <video alt={title?title:"banner"} src={media.url} preload="metadata" width={width} height={height} autoPlay loop muted playsInline/>}
            </Box>

            <Box className="banner-text flex col center">
                {title && showTitle &&   
                    <Fade top>
                        <Typography variant="h2" component="h1" className="tbold tcenter">
                            {title}
                        </Typography>
                    </Fade>
                }
                <Fade bottom>
                    <Typography variant="h4" component="h2" gutterBottom className="tcenter">
                        {subtitle}
                    </Typography>
                </Fade>
            </Box>
            {button.active &&
                <Box mt={4}>
                    <ButtonAction variant="contained" size="large" action={button.action} sx={{borderRadius:"50px"}}>
                        {button.title}
                    </ButtonAction>
                </Box>
            }
        </Box>
    );
}


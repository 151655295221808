import { ButtonGroup, Typography, useMediaQuery } from '@mui/material';
import './sectionElevationTalent.css'
import { Box } from '@mui/system';
import { orderArray } from '../../services/serviceslocal';
import ButtonAction from '../buttonAction/ButtonAction';
import SectionElevation from '../sectionElevation/SectionElevation';

export default function SectionElevationTalent({data}) {

    const max740 = useMediaQuery("(max-width:740px)");
    
    return (
        <>
        <SectionElevation w="800" info >
            <ButtonGroup variant="text" aria-label="text button group" color="secondary">       
                {
                    orderArray(data.items, "position").map((item, index)=>{
                        return(
                            <ButtonAction key={index} 
                                className="containerBtn flex col center w100"  
                                sx={{padding:2, height:"auto", borderRadius:4 }}
                                action={item.button.action}                            
                            >
                                    <Typography variant="h6" className="tbold tcenter" sx={{paddingY:2 }} color="primary">
                                        {item.title}
                                    </Typography>
                                    <Typography paragraph className="tj" variant="body2" color="dark">
                                        {item.body}
                                    </Typography>
                                    <Box sx={{mt:3, background:"var(--primary)", p:"8px 22px", borderRadius:"50px", color:"var(--white)"}}>
                                        {item.button.title}
                                    </Box>
                            </ButtonAction>
                        )
                    })
                }
            </ButtonGroup>
        </SectionElevation>

        {max740 && 
            <Box className="sectionElevationTalentAction" sx={{mx:"auto", pt:5}}>
                <ButtonGroup variant="text">
                    {
                        orderArray(data.items, "position").map((item, index)=>{
                            return(
                                <ButtonAction key={index} action={item.button.action} size="large" sx={{borderRadius:"50px"}}>
                                    {item.button.title}
                                </ButtonAction>
                            )
                        })
                    }
                </ButtonGroup>
            </Box>
        }
        </>
    );
}
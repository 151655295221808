import { Box, Button, Chip } from '@mui/material';
import './jobsBySection.css'
import {Zoom} from 'react-awesome-reveal';

export default function JobsBySection({arrayJobs, fieldName, selectItem}) {
   
    const arrayFiltered = [...new Set(arrayJobs.map((item) => item[fieldName]))].filter(item => item !== null).sort()

    return (
        <Box className="jobsBySection w100 flex row" sx={{py:2}}>
            {arrayFiltered.map((item) =>{
                return(
                    <Button 
                        key={item}
                        className="itemSection_btn"
                        sx={{p:0, m:1}}  
                        color="secondary"                
                        onClick={() => selectItem(item)}
                    >
                        <Zoom>
                            <Chip label={item}  className="itemSection_chip" />
                        </Zoom>
                    </Button>
                )
                })
            }   
        </Box>
    );
}
import { Box, Paper } from '@mui/material';
import './sectionElevation.css'
import {Bounce, Flip, Slide, Zoom } from 'react-awesome-reveal';

export default function SectionElevation({children, w=1100, smw, relative, info}) {
    // mw="mw1000"
    return (
        // <Box className={`sectionElevation flex col center w100 ${mw}`}  sx={{padding:2, pb:relative?2:15"}}>
            // <Zoom>
                <Box className={`sectionElevation ${info?"sectionElevation_info":""} flex col center w100 ${smw?"smw":""}` }  sx={{padding:smw?0:2, pb:relative?2:15, width:w+"px" }}>
                    <Paper 
                        elevation={5} 
                        className={`sectionElevation_paper flex col center w100 ${relative?"sectionElevation_paper_relative":""}`}  
                        sx={{padding:2, borderRadius:4}}
                    >
                        {children}
                    </Paper>
                </Box>
            // </Zoom>
    );
}
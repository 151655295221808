import { useState, useEffect } from 'react';
import { Box, LinearProgress,} from '@mui/material';
import { Bounce } from 'react-awesome-reveal';
import logoasap from '../../assets/images/favicon.png'
import { getBrowser } from '../../services/serviceslocal';
import './preloader.css'

export default function Preloader({showPreloader = true, mt}){

    const [valueBuffer, setValueBuffer] = useState(0)
    const [value, setValue] = useState(0) 
    const [intervalId, setIntervalId] = useState(null);
    const [currentShow, setCurrentShow] = useState(true);

    const increaseValue = () => {
        let acount = 0;
        let acount2 = 0;
        const id = setInterval(() => {
        if (acount <= 100) {
            setValueBuffer(acount);
            acount++;
        } else if (acount2 <= 100) {
            setValue(acount2);
            acount2++;
        } else {
            clearInterval(id);
        }
        }, 15);

        setIntervalId(id);
    };

    const stopInterval = () => {
        clearInterval(intervalId);
    };

    useEffect(() => {
        increaseValue();

    }, []);

    useEffect(() => {
      if(!showPreloader){
        stopInterval()
        setTimeout(() => {
            setCurrentShow(showPreloader)
        }, 100);
      }
    }, [showPreloader])
    
    

    return (
        currentShow &&
        <Box className={`preloader flex center col ${mt?'mt':''}`}>
            {getBrowser() === "safari"?
                <img src={logoasap}  className="preloaderLogo" alt="ASAP Staffing Services"/>
            :
                <Bounce left>
                    <img src={logoasap}  className="preloaderLogo" alt="ASAP Staffing Services"/>
                </Bounce>
            }
                {/* <LinearProgress color="primary" variant="buffer" value={0} valueBuffer={0} sx={{my:1, minWidth:"100%"}} /> */}
                <LinearProgress color="primary" variant="buffer" value={value} valueBuffer={valueBuffer} sx={{my:1, width:"300px", maxWidth:"80%"}} />
        </Box>
    );
}
import { useContext, useState } from 'react';
import { Box, Button, IconButton, Typography, useMediaQuery } from '@mui/material';
import { useParams } from 'react-router';
import './account.css'
import Card from '../../../components/card/Card';
import Context from '../../../contexts/Context';
import { Logout } from '@mui/icons-material';
import { auth } from '../../../services/firebaseConfig';
import SubBarSearch from '../../../components/subBarSearch/SubBarSearch';

export default function Account() {
    const {user} = useContext(Context)
    const max500 = useMediaQuery("(max-width: 500px)");

    const logOut = () => {
        auth
        .signOut()
    }
    return (

        <Box className="articleVisor flex col w100 aicenter" sx={{pb:2}} id="article-visor">
            <SubBarSearch
                title="My Account"
                noSearch
            />
            <Box className="containerResources flex col aicenter w100 mw900" sx={{px:1}}>
                <Card className="flex row center">
                    <Box className="flex row w100 aicenter">
                        <Typography variant="body1" component="span" className="tcenter" color="gray" sx={{pt:1, mr:"auto"}} >
                            {user?user.email:""}
                        </Typography>

                        {!max500 &&
                            <Typography variant="body1" component="span" className="tcenter" color="gray" >
                                Log Out
                            </Typography>
                        }

                        <Box>
                            <IconButton
                            onClick={() => logOut()}
                            >
                                <Logout/>
                            </IconButton>
                        </Box>
                    </Box>
                </Card>
            </Box>
        </Box>

        // <Box className="resources flex col aicenter w100"style={{background:"var(--white)"}}>
        //      <Box className="containerResources flex col aicenter w100 mw900" sx={{px:1}}>
        //         <Card className="flex row center">

        //             <Typography variant="body1" component="span" className="tcenter" color="gray" sx={{pt:1}} >
        //                 {user?user.email:""}
        //             </Typography>
        //             <Box>
        //                 <IconButton
        //                 onClick={() => logOut()}
        //                 >
        //                     <Logout/>
        //                 </IconButton>
        //             </Box>
        //         </Card>

        //      </Box>
        // </Box>
    );
}

import { useEffect, useRef, useState } from 'react';
import { ClickAwayListener, Grow, Paper, Popper, MenuItem, MenuList, Stack, Button, Typography, useMediaQuery, Avatar } from '@mui/material';
import { AccountCircle, ChevronRight, KeyboardArrowDown, KeyboardArrowRight } from '@mui/icons-material';
import { NavLink, useNavigate } from 'react-router-dom';
import './navBarSubMenuUser.css'
import { useContext } from 'react';
import Context from '../../contexts/Context';
import ModalSesion from '../modalSesion/ModalSesion';
import { auth } from '../../services/firebaseConfig';

export default function NavBarSubMenuUser({color, closeMenu}) {

    const {user, team} = useContext(Context)    
    const anchorRef = useRef(null);
    const md = useMediaQuery("(max-width:800px)");
    
    const navigate = useNavigate()
    const [open, setOpen] = useState(false);
    const [currentUser, setCurrentUser] = useState();
    const [enterSubMenu, setEnterSubMenu] = useState(false);

    const [modalActive, setModalActive] = useState(false)

    useEffect(() => {
        if(!user){
            return(setCurrentUser())
        }
        if(user && team.length > 0){
            let find = team.find(item => item.Email === user.email)
            if(find){
                setCurrentUser(find)
            }
    }
    }, [user, team])

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
        return;
        }

        setOpen(false);
        closeMenu()
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
        event.preventDefault();
        setOpen(false);
        } else if (event.key === 'Escape') {
        setOpen(false);
        }
    }

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open])

    const handleClickNavLink = (e) => {
        if(open || !user){
            e.preventDefault()
        }
        closeMenu(); 
        setOpen(false)
    }

    const logOut = () => {
        auth.signOut()
    }
    
    return (
        <>
            <NavLink to='/cms' onClick={handleClickNavLink}>
                <Stack 
                    direction="row" 
                    spacing={2} 
                    sx={{zIndex:1}} 
                    onMouseLeave={() => {!enterSubMenu && setOpen(false)}}
                >
                    <Button
                        sx={{minWidth:"initial"}}
                        onContextMenu={(e) => e.preventDefault()}
                        ref={anchorRef}
                        fullWidth
                        disableFocusRipple={true}
                        color={color}
                        onClick={handleClickNavLink}
                        className="navBar-subMenu"
                        endIcon={
                            md || !user ?false
                            :!open?<KeyboardArrowDown onMouseEnter={() => setOpen(true)} onClick={() => setOpen(true)}/>
                            :<KeyboardArrowRight/>
                        }
                    >
                        <Avatar 
                            sx={{ width: user?32:24, height: user?32:24, background:currentUser || !user?"var(--white)":"var(--secondary)"}}
                            alt={currentUser?.Name}
                            src={currentUser?.MediumPhotoUrl}
                            onClick={() => {user?navigate("/cms"):setModalActive(true)}}
                            className={user?"noSelect":""}
                        >
                            {!user && <AccountCircle color="secondary"/>}
                            {user && !currentUser &&
                                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} className="Montserrat" color="white">
                                    {user.email.charAt(0).toUpperCase()}
                                </Typography>
                            }
                        </Avatar>
                    </Button>
                    { user &&
                        <Popper
                            open={md?false:open}
                            anchorEl={anchorRef.current}
                            role={undefined}
                            placement="bottom-start"
                            transition
                            disablePortal
                            onMouseEnter={() => {setEnterSubMenu(true)}}
                            onMouseLeave={() => {setOpen(false);setEnterSubMenu(false)}}
                        >
                        {({ TransitionProps, placement }) => (
                            <Grow
                                className="grow"
                                {...TransitionProps}
                                style={{
                                    transformOrigin:
                                    placement === 'bottom-start' ? 'left top' : 'left bottom',
                                }}
                            >
                                <Paper>
                                    <ClickAwayListener onClickAway={handleClose}>
                                        <MenuList
                                            autoFocusItem={open}
                                            onKeyDown={handleListKeyDown}
                                            sx={{py:0}}
                                        >            
                                            <MenuItem 
                                                onClick={(e) => {handleClose(e); logOut()}}
                                                className="Montserrat"
                                            > 
                                                <ChevronRight color="primary"/>
                                                Log Out
                                            </MenuItem>
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                        </Popper>
                    }
                </Stack>
            </NavLink>
            <ModalSesion modalActive={modalActive} setModalActive={setModalActive}/>
        </>
    );
}

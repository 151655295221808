import { useState, useEffect, } from "react";
import './siteContentsComponents.css'
import { Box } from "@mui/material";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import BannerEditor from "./BannerEditor";
import SectionElevationCompanyEditor from "./SectionElevationCompanyEditor";
import SectionEditor from "./SectionEditor";
import { orderArray } from "../../services/serviceslocal";
import uuid from 'react-uuid'
import SectionElevationTalentEditor from "./SectionElevationTalentEditor";
import SectionServiceEditor from "./SectionServiceEditor";
import BannerServiceEditor from "./BannerServiceEditor";
import SectionContactEditor from "./SectionContactEditor";
import SectionReviewsEditor from "./SectionReviewsEditor";
import SectionFeaturedIconsEditor from "./SectionFeaturedIconsEditor";
import SectionNewslettersEditor from "./SectionNewslettersEditor";
import TermsEditor from "./TermsEditor";
function ListDragEditor({array, fixed=false, setPageSelected, addNewSection, setAddNewSection, data, setTitlePageSelected}) {

    const [itemList, setItemList] = useState(orderArray(array, "position"));
    
    const emptySection = {
        "id":uuid(),
        "position": array.length + 1,
        "type": "section",
        "showTitle":true,
        "title": `Section ${array.length + 1}`,
        "body": "",
        "button": {
            "active": false,
            "title": "",
            "action": ""
        },
        "backgroundColor": "Auto",
        "media": {
            "active": false,
            "type": "",
            "url": ""
        }
    }

    const emptySectionContact = {
        "id":uuid(),
        "position": array.length + 1,
        "type": "sectionContact",
        "showTitle":true,
        "title": `Section ${array.length + 1}`,
        "subtitle": "",
        "buttons": [
            {
            "active": false,
            "title": "",
            "action": "",
            "icon":"",
            "position":1
            }
        ],
        "backgroundColor": "Auto",
        "media": {
            "active": false,
            "type": "",
            "url": ""
        }
    }

    useEffect(() => {
      if(addNewSection){
        setAddNewSection(false)
        if(addNewSection === "contact-us"){
            setItemList(prevState => ([...prevState, emptySectionContact]))
            return
        }
        setItemList(prevState => ([...prevState, emptySection]))
      }
    }, [addNewSection])
    
    // useEffect(() => {
    //     if(array.length !== itemList.length){setItemList(array)}
    // }, [array, itemList])

    const handleDrop = (droppedItem) => {
        if (!droppedItem.destination) return;
        var updatedList = [...itemList];
        const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
        updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
        setItemList(updatedList);   
    };
    
    useEffect(() => {
        let newArray = [] 
        itemList.forEach((thisSection, index) => {
            thisSection.position = index + 1
            newArray.push(thisSection)
        })
        data.data = newArray
        setPageSelected(prevState => ({...prevState, data: newArray}))
    }, [data, itemList])


    return (
        <Box className="listDragEditor w100">
            <DragDropContext onDragEnd={handleDrop}>
                <Droppable isDropDisabled={fixed} droppableId="listDragEditor">
                {(provided) => (
                    <Box className="listDragEditor-list-container w100" {...provided.droppableProps} ref={provided.innerRef}>
                        {itemList.map((item, index) => {
                            let isDragDisabled = item.type === "banner" || item.type === "bannerSimple"
                            return(   
                                <Draggable isDragDisabled={isDragDisabled} key={item.id} draggableId={item.id} index={index}>
                                    {(provided) => (
                                        <Box
                                            className="listDragEditor-item-container"
                                            ref={provided.innerRef}
                                            {...provided.dragHandleProps}
                                            {...provided.draggableProps}
                                        >
                                            <Box key={item.id} className="siteContents-containerSections flex col aicenter w100 mw900" sx={{px:2}}>
                                                {(item.type === "banner" || item.type === "bannerSimple") && <BannerEditor data={item} simple={item.type === "bannerSimple"}/>}
                                                {item.type === "shortcutsToTopServices" && <SectionElevationCompanyEditor data={item}/>}
                                                {item.type === "shortcutsForTalent" && <SectionElevationTalentEditor data={item}/>}
                                                {(item.type === "section" || item.type === "sectionImage") && <SectionEditor data={item} setData={setItemList}/>}
                                                {item.type === "service" && <SectionServiceEditor data={item}/>}
                                                {(item.type === "bannerService" || item.type === "sectionFindAJob") && <BannerServiceEditor data={item} isBannerService={item.type === "bannerService"} dataParent={data} setTitleParent={setTitlePageSelected}/>}
                                                {item.type === "term" && <SectionServiceEditor data={item}/>}
                                                {(item.type === "termSection") && <TermsEditor data={item} setData={setItemList} dataParent={data} setTitleParent={setTitlePageSelected}/>}
                                                {item.type === "sectionContact" && <SectionContactEditor data={item} setData={setItemList}/>}
                                                {item.type === "reviewsSection" && <SectionReviewsEditor data={item} setData={setItemList}/>}
                                                {item.type === "featuredIconsSection" && <SectionFeaturedIconsEditor data={item} setData={setItemList}/>}
                                                {(item.type === "newslettersSection") && <SectionNewslettersEditor data={item} setData={setItemList}/>}
                                            </Box> 
                                        </Box>
                                    )}
                                </Draggable>
                            )
                        })}
                    {provided.placeholder}
                    </Box>
                )}
                </Droppable>
            </DragDropContext>
        </Box>
    );
}

export default ListDragEditor;

import { useContext } from 'react';
import { Box, Typography } from '@mui/material';
import './interviewTips.css'
import HeaderImage from '../../../components/headerImage/HeaderImage';
import BreadcrumbsCustomized from '../../../components/breadcrumbsCustomized/BreadcrumbsCustomized';
import Context from '../../../contexts/Context';
import SectionContactUs from '../../../components/sectionContactUs/SectionContactUs';
import Section from '../../../components/section/Section';
import { clone } from '../../../services/serviceslocal';

export default function InterviewTips() {

    const {siteContents} = useContext (Context)

    const {data} = siteContents.find(item => item.id === "terms")
    const termsAndConditions = data.find(item => item.id === "interview-tips").data[0]
    const {data: dataContacUs} = siteContents.find(item => item.id === "contact-us")
    const contactForTalent = clone(dataContacUs.find(item => item.id === "contactForTalent"))
    contactForTalent.backgroundColor = "Gray"

    return (
        <Box className=" flex col center w100 minh100">
            <BreadcrumbsCustomized title={termsAndConditions.title}/>
            <HeaderImage
                animate
                fixed
                image={termsAndConditions.media}                
            >
                <Typography variant="h2" component="h1" className="tbold tcenter">
                    {termsAndConditions.title}
                </Typography>
            </HeaderImage>

            <Section>
                <Typography className="sectionOnlytext_body section_text tj" color="dark"  dangerouslySetInnerHTML={ {__html: termsAndConditions.body}}/>
            </Section>

            <SectionContactUs data={contactForTalent}/>
        </Box>
    );
}

import { useState, useEffect, useContext } from 'react';
import { Box, ButtonGroup, Typography, useMediaQuery } from '@mui/material';
import { DoubleArrow } from '@mui/icons-material';
import './sectionElevationCompany.css'
import Context from '../../contexts/Context';
import ButtonAction from '../buttonAction/ButtonAction';
import SectionElevation from '../sectionElevation/SectionElevation';

export default function SectionElevationCompany({data, info}) {
   
    const {siteContents} = useContext (Context)
    
    const siteContentsServices = siteContents.find(item => item.id === "services")
    const services = siteContentsServices.data.filter(service => service.type === "service")

    const max930 = useMediaQuery("(max-width:930px)");
    const [arrayServices, setArrayServices] = useState([])

    useEffect(() => {
        setArrayServices([])

            let array = !max930?data.services:data.services.slice(0, 2)
            array.forEach(id => {
                const found = services.find(service => service.id === id)
                setArrayServices(prevstate => ([...prevstate, found])); 
                return
            });
    }, [data, max930])

    return (
        <SectionElevation info>
            <ButtonGroup variant="text" aria-label="text button group" color="secondary">       
                {
                    arrayServices.map((service)=>{
                        return(
                            <ButtonAction 
                                key={service.id} 
                                color="secondary" 
                                className="containerBtn flex col center w100"  
                                sx={{padding:2, height:"auto", borderRadius:4 }}
                                action={service.data[0].button.action}     
                            >
                                    <Typography variant="h6" component="h2" className="tbold tcenter" sx={{paddingY:2 }} color="primary">
                                        {service.title}
                                    </Typography>
                                    <Typography paragraph className="tj" variant="body2" color="dark" dangerouslySetInnerHTML={{__html: service.data[0].body}}>
                                    </Typography>
                                    <Box sx={{mt:"auto"}}>
                                        <DoubleArrow color="primary"/>
                                    </Box>
                            </ButtonAction>
                        )
                    })
                }
            </ButtonGroup>
        </SectionElevation>
    );
}
import { useState } from 'react';
import {Box, SpeedDial, SpeedDialAction, SpeedDialIcon} from '@mui/material';
import './fabButton.css'
import { Article, Share, FileDownloadDone} from '@mui/icons-material';


export default function FabButton({handleClick}) {

    const actions = [
        { icon: <FileDownloadDone />, name: 'Create Article' },
        { icon: <Article />, name: 'Preview' },
        // { icon: <Share />, name: 'Share' },
      ];

      const [open, setOpen] = useState(false);
      const handleOpen = () => setOpen(true);
      const handleClose = () => setOpen(false);

    return (
        <Box sx={{ height: 320, transform: 'translateZ(0px)', flexGrow: 1 }} className="btnAdddArticle">
            <SpeedDial
                ariaLabel="SpeedDial uncontrolled open example"
                sx={{ position: 'absolute', bottom: 16, right: 16 }}
                icon={<SpeedDialIcon />}
                onClose={handleClose}
                onOpen={handleOpen}
                open={open}
            >
                {actions.map((action) => (
                <SpeedDialAction
                    key={action.name}
                    icon={action.icon}
                    tooltipTitle={action.name}
                    onClick={() =>{handleClick(action.name);handleClose()}}
                />
                ))}
            </SpeedDial>
            </Box>
    );
}
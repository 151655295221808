import { Box, Typography } from '@mui/material';
import './service.css'
import SectionImage from '../../../components/sectionImage/SectionImage';
import HeaderImage from '../../../components/headerImage/HeaderImage';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState, useContext } from 'react';
import BreadcrumbsCustomized from '../../../components/breadcrumbsCustomized/BreadcrumbsCustomized';
import Context from '../../../contexts/Context';
import { clone, orderArray } from '../../../services/serviceslocal';
import SectionOnlytext from '../../../components/sectionOnlytext/SectionOnlytext';
import SectionContactUs from '../../../components/sectionContactUs/SectionContactUs';

export default function Service() {

    let navigate = useNavigate ();
    const {service} = useParams()
    const [currentService, setCurrenteService] = useState([])
    const {siteContents} = useContext (Context)

    let {data} = clone(siteContents.find(item => item.id === "services"))
    let arrayServices = data.filter(item => item.type === "service")
    const {data: dataContacUs} = siteContents.find(item => item.id === "contact-us")
    const contactForCompany = clone(dataContacUs.find(item => item.id === "contactForCompany"))

    useEffect(() => {
        const param = service.toLowerCase()
        let found = arrayServices.find(item => item.title.toLowerCase().replaceAll(' ', '-') === param)
        if(found){
            contactForCompany.position = found.data.length + 1
            setCurrenteService([...found.data, contactForCompany])
        }else{
            navigate('/services')
        }
    }, [service])
    

    return (
        <Box className="flex col center w100 minh100">

            <BreadcrumbsCustomized title={currentService.length > 0 && currentService[0].title} description={currentService[0]?.body.replace( /(<([^>]+)>)/ig, '')}/>
            {
                currentService.length > 0 && orderArray(currentService, "position").map(item => {
                    let {id, type} = item
                    if((type === "sectionImage" || type === "sectionContact")){
                        let prevBackgroundColor = currentService.find(prevItem => prevItem.position === item.position - 1).backgroundColor
                        item.backgroundColor = prevBackgroundColor === "White"?"Gray":"White"
                    }
                    return(
                        type === "bannerService"?
                            <HeaderImage key={id}
                                animate
                                fixed
                                image={item.media}                
                                >
                                <Typography variant="h2" component="h1" className="tbold tcenter">
                                    {item.title}
                                </Typography>
                            </HeaderImage>
                        :type === "section"?
                            <SectionOnlytext key={id} data={item} /> 
                        :type === "sectionImage"?
                            <SectionImage key={id} data={item} titleLink/>
                        :type === "sectionContact" &&
                            <SectionContactUs key={id} data={item}/>
                    )
                })
            }   
        
        </Box>
    );
}


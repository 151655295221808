
import { Box, IconButton, Link, Typography, Dialog, DialogActions, DialogContent, Slide } from "@mui/material";
import "./modalTeamCard.css";
import { forwardRef, useEffect, useState } from "react";
import TeamCard from "../teamCard/TeamCard";
import { Close, LinkedIn, Mail, Phone } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";


const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalTeamCard({ item, modalActive, setModalActive, currentEmployee }) {
    
    let navigate = useNavigate()

    const handleClose = () => {
        if(currentEmployee){
           navigate("/about-us/meet-our-team") 
        }
        setModalActive(false);
    };

    const onCall = (phone) => {
        if(phone.startsWith("+")){
            return(window.location = `tel:${phone}`)
        }
        window.location = `tel:+1 ${phone}`
    }

    const formatedPhone = (phone) => {
        if(phone.startsWith("+")){
            return(`tel:${phone}`)
        }
        return(`tel:+1 ${phone}`)
    }

    return (
        <div>
            <Dialog
                open={modalActive}
                TransitionComponent={Transition}
                className="modalTeamCard"
                fullWidth={true}
                maxWidth="md"
            >
                <DialogActions className="flex col" >
                    <IconButton 
                        sx={{marginLeft:"auto"}}
                        onClick={handleClose}
                    >
                        <Close/>
                    </IconButton>
                </DialogActions>
                <DialogActions sx={{p:0}} className="modalTeamCard_teamCard_out">
                    <TeamCard item={item} btnDisable openPhoto/>
                </DialogActions>
                {item.AboutMe &&
                    <DialogContent className="flex col" >
                        <Box className="w100 flex center col" sx={{minWidth: "50vw" }}>
                            <Box className="modalTeamCard_teamCard_into">
                                <TeamCard  item={item} btnDisable />
                            </Box>
                            <Box className="mw900" sx={{p:2, mt:2}} >
                                <Typography className="section_text tj" color="dark">
                                    {item.AboutMe}
                                </Typography>
                            </Box>
                        </Box>
                    </DialogContent >
                }
                <DialogActions>
                    
                    {item.Email &&
                        <Link href={`mailto:${item.Email}`} underline="none" target="_blank">
                            <IconButton color="primary" size="large" >
                                <Mail/>
                            </IconButton>
                        </Link>
                    }
                    {item.LinkedIn_Profile__c &&
                        <Link href={item.LinkedIn_Profile__c} underline="none" target="_blank">
                            <IconButton color="primary" size="large" >
                                <LinkedIn/>
                            </IconButton>
                        </Link>
                    }
                    {item.Phone &&
                        // <Link href={`tel:${item.Phone}`} underline="none">
                        <Link href={formatedPhone(item.Phone)} underline="none" title={formatedPhone(item.Phone)}>
                            <IconButton color="primary" size="large" 
                            // onClick={() => onCall(item.Phone)} title=""
                            >
                                <Phone/>
                            </IconButton>
                        </Link>
                    }
                </DialogActions>
            </Dialog>
        </div>
    );
    }

import { useContext, useEffect, useRef, useState } from 'react';
import { Box, Checkbox, Input, Typography } from '@mui/material';
import './siteContentsComponents.css'
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';

export default function ButtonEditorFromArray({dataButtom}) {
    const [hasButton, setHasButton] = useState(dataButtom?dataButtom.active:false)
    const [titleButton, setTitleButton] = useState(dataButtom?dataButtom.title:"")
    const [actionButton, setActionButton] = useState(dataButtom?dataButtom.action:"")
    
    useEffect(() => {
        dataButtom.active = hasButton
        dataButtom.title = titleButton
        dataButtom.action = actionButton
    }, [dataButtom, hasButton, titleButton, actionButton  ])
    

    return (
        <Box className="siteContents-item flex col w100 aicenter">
            <Box className="flex row w100 aicenter">
                <Typography variant="body2" component="span" sx={{mr:1}} className="Montserrat tbold">
                    Button
                </Typography>
                <Checkbox sx={{p:0}} icon={<CheckBoxOutlineBlank fontSize='small'/>} checkedIcon={< CheckBox fontSize='small'/>} checked={hasButton} onChange={(e, value)=>setHasButton(value)} />
            </Box>
            {hasButton && 
                <Box className="flex row w100 aicenter">
                    <Input
                        type='text'
                        className="siteContents-input"
                        placeholder="Title Button"
                        fullWidth
                        sx={{mr:0.5}}
                        disableUnderline
                        value={titleButton}
                        onChange={(e)=> {setTitleButton(e.target.value)}}
                    />
                    <Input
                        type='text'
                        className="siteContents-input"
                        placeholder="Url"
                        fullWidth
                        disableUnderline
                        title="text"
                        sx={{mr:0.5}}
                        value={actionButton}
                        onChange={(e)=> {setActionButton(e.target.value)}}
                    />
                </Box>
            }
        </Box>       
    );
}
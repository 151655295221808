import { useContext } from 'react';
import { Box, Typography } from '@mui/material';
import './recruitingProcess.css'
import HeaderImage from '../../../components/headerImage/HeaderImage';
import BreadcrumbsCustomized from '../../../components/breadcrumbsCustomized/BreadcrumbsCustomized';
import Context from '../../../contexts/Context';
import SectionContactUs from '../../../components/sectionContactUs/SectionContactUs';
import Section from '../../../components/section/Section';
import ButtonAction from '../../../components/buttonAction/ButtonAction';
import { clone } from '../../../services/serviceslocal';

export default function RecruitingProcess() {

    const {siteContents} = useContext (Context)

    const {data} = siteContents.find(item => item.id === "terms")
    const recruitingProcess = data.find(item => item.id === "recruiting-process").data[0]
    const {data: dataContacUs} = siteContents.find(item => item.id === "contact-us")
    const contactForCompany = clone(dataContacUs.find(item => item.id === "contactForCompany"))
    contactForCompany.backgroundColor = "Gray"

    return (
        <Box className=" flex col center w100 minh100">
            <BreadcrumbsCustomized title={recruitingProcess.title}/>
            <HeaderImage
                animate
                fixed
                image={recruitingProcess.media}                
            >
                <Typography variant="h2" component="h1" className="tbold tcenter">
                    {recruitingProcess.title}
                </Typography>
            </HeaderImage>

            <Section>
                <Typography 
                    variant="h5" 
                    component="h2"  
                    color="black"
                    className="tcenter" 
                    sx={{cursor:"default", my:3}}
                >
                    {`${recruitingProcess.subtitle || ''}`}
                </Typography>

                {recruitingProcess.button?.active &&
                    <Box sx={{m:"auto", mb:3}}>
                        <ButtonAction variant="text" size="large" sx={{mt:2, borderRadius:"50px"}} action={recruitingProcess.button.action}>
                            {recruitingProcess.button.title}
                        </ButtonAction>
                    </Box>
                }
                <Typography className="sectionOnlytext_body section_text tj" color="dark"  dangerouslySetInnerHTML={ {__html: recruitingProcess.body}}/>
            </Section>

            <SectionContactUs data={contactForCompany}/>
        </Box>
    );
}

import { useContext, useEffect, useRef, useState } from 'react';
import { Box, Button, Chip, Link, Typography, useMediaQuery } from '@mui/material';
import './job.css'
import HeaderImage from '../../../components/headerImage/HeaderImage';
import BreadcrumbsCustomized from '../../../components/breadcrumbsCustomized/BreadcrumbsCustomized';
import Context from '../../../contexts/Context';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Card from '../../../components/card/Card';
import { LinkedIn, Paid, Place, ShareLocation, ShareSharp } from '@mui/icons-material';
import PostArticleBy from '../../../components/postArticleBy/PostArticleBy';
import { getDeleteStylesChildrens, getJNfromUrlOld, jobNumberToString, separeArray, titleUrlJobToString, urlToString } from '../../../services/serviceslocal';
import ButtonAction from '../../../components/buttonAction/ButtonAction';
// import Preloader from '../../../components/preloader/Preloader';


export default function Job({arrayJobs}) {
    

    let {search} = useLocation();
    let url = document.URL;
    let params = new URLSearchParams(search)
    const title = params.get('title');
    const id = params.get('id');
    const {job} = useParams() 

    const max500 = useMediaQuery("(max-width:500px)");
    let navigate = useNavigate();
    const {siteContents} = useContext (Context)
    let {data} = siteContents.find(item => item.id === "find-a-job")
    const bannerSimple = data.find(item => item.type === "bannerSimple") 

    const [item, setItem] = useState()
    const bodyJobRef = useRef()
    
    useEffect(() => {
        if(arrayJobs.length > 0){
            if(getJNfromUrlOld(url)){
                const JNfromUrl = getJNfromUrlOld(url)
                const findByJN  = arrayJobs.find(item => item.TR1__Job_Number__c.toLowerCase() === jobNumberToString(JNfromUrl))
                if(findByJN){return(setItem(findByJN))}
            }else if(id){
                const IdfromUrl = getJNfromUrlOld(id)
                const findById =arrayJobs.find(item => item.TR1__Job_Number__c.toLowerCase() === jobNumberToString(IdfromUrl))
                if(findById){return(setItem(findById))}
            }else if(title){
                const find1 = arrayJobs.find(item => item.Title_Website.toLowerCase() === titleUrlJobToString(title))
                if(find1){return(setItem(find1))}
                else{
                    const find2 = arrayJobs.find(item => item.Title_Website.toLowerCase() === urlToString(title))
                    if(find2){return(setItem(find2))}
                }
            }
        navigate("/find-a-job/jobs")
        }
    }, [title, id, arrayJobs, job, url])

    // const responsive = useMediaQuery("(max-width:600px)");
    
    useEffect(() => {
        if(bodyJobRef && item){
            getDeleteStylesChildrens(bodyJobRef.current)
    //         bodyJobRef.current.innerHTML = item.TR1__Client_Description__c
            // body.replaceAll(`"`, `'`)
        }
    }, [bodyJobRef, item]);
    
        // alert(window.navigator.userAgent)
        const share = () =>{
            navigator.share({
                title: `${item.Title_Website} - ${item.TR1__Job_Number__c.replaceAll(' ', '')} | ASAP Staffing Services Miami`, 
                url: window.location.href, 
                text: `${item.Title_Website} - ${item.TR1__Job_Number__c.replaceAll(' ', '')} | ASAP Staffing Services Miami`, 
                // text: `${item.TR1__Client_Description__c?.replace( /(<([^>]+)>)/ig, '')}`, 
            })
        }

    return (
        !item? 
        <>
            {/* <Preloader showPreloader={!item}/> */}
        </> 
        :
        <Box className="job flex col center w100 minh100">
            <BreadcrumbsCustomized title={`${item.Title_Website} - ${item.TR1__Job_Number__c}`} description={item.TR1__Client_Description__c?.replace( /(<([^>]+)>)/ig, '')}/>
             <HeaderImage 
                // br={false}
                background="gray"
                
                animate
                fixed
                image={bannerSimple.media}                 
                >
                <Typography variant="h2" component="h1" className="job-bannerText tbold tcenter">
                    {item.Title_Website}
                </Typography>
                <Typography variant="h4" component="h4" className="job-bannerText2 tbold tcenter">
                    {item.TR1__Job_Number__c}
                </Typography>
                   
                <Box className="flex row w100 wrap center ">
                    {/* {
                        separeArray([item.TR1__State_Area__c, item.TR1__City__c, item.Country__c]).sort().map(element => {
                            return(
                                <Button 
                                    key={element}
                                    sx={{p:0, mr:0.5, my:0.5, borderRadius:"50px"}}  
                                    color="secondary"                
                                >
                                    <Chip size="small"  icon={<Place />} label={`${element}`} className="chip_Job"/>
                                </Button>
                            )
                        })
                    } */}
                    {
                        separeArray([item.TR1__State_Area__c]).sort().map(element => {
                            return(
                                <Button 
                                    key={element}
                                    sx={{p:0, mr:0.5, my:0.5, borderRadius:"50px"}}  
                                    color="secondary"                
                                >
                                    <Chip size="small"  icon={<Place />} label={`${element}`} className="chip_Job"/>
                                </Button>
                            )
                        })
                
                    }
                    {
                        separeArray([item.TR1__City__c]).sort().map(element => {
                            return(
                                <Button 
                                    key={element}
                                    sx={{p:0, mr:0.5, my:0.5, borderRadius:"50px"}}  
                                    color="secondary"                
                                >
                                    <Chip size="small"  icon={<Place />} label={`${element}`} className="chip_Job"/>
                                </Button>
                            )
                        })
                
                    }
                    {
                        separeArray([item.Country__c]).sort().map(element => {
                            return(
                                <Button 
                                    key={element}
                                    sx={{p:0, mr:0.5, my:0.5, borderRadius:"50px"}}  
                                    color="secondary"                
                                >
                                    <Chip size="small"  icon={<Place />} label={`${element}`} className="chip_Job"/>
                                </Button>
                            )
                        })
                
                    }
                    {item.Type_of_work__c && 
                        <Button 
                            sx={{p:0, mr:0.5, my:0.5, borderRadius:"50px"}}  
                            color="secondary"                
                        >
                            <Chip size="small"  icon={<ShareLocation />} label={item.Type_of_work__c} className="chip_Job"/>
                        </Button>
                    }
                </Box>
                {item.Post_Salary__c &&
                    <Box className="flex row w100 wrap center">
                        <Button 
                            sx={{p:0, mr:0.5, my:0.5, borderRadius:"50px"}}  
                            color="secondary"                
                        >
                            <Chip size="small" icon={<Paid />} label={item.TR1__Salary_Low__c  + " USD"} className="chip_Job"/>
                        </Button>

                        <Button 
                            sx={{p:0, mr:0.5, my:0.5, borderRadius:"50px"}}  
                            color="secondary"                
                        >
                            <Chip  size="small"icon={<Paid />} label={item.TR1__Salary_High__c  + " USD"} className="chip_Job"/>
                        </Button>
                    </Box>
                }
            </HeaderImage>
            

                <Box className="flex row wrapJob w100 jccenter"  sx={{py:1, pb:"30px"}}>
                    <Box className={`preWrapJob flex col center w100`}>
                        <Box className="containerJob bodyContent flex col aicenter w100 mw900" sx={{px:1}}>
                            <Box ref={bodyJobRef} className="contents">
                                {item.TR1__Client_Description__c &&
                                    <Card className="col w100">
                                        <Box className="bodyJob Roboto" dangerouslySetInnerHTML={{__html: item.TR1__Client_Description__c}}  onLoad={() => console.log("ALOJA") }/>
                                    </Card>
                                }
                                {item.TR1__Experience_Requirements__c &&
                                    <Card className="col w100">
                                        <Box className="w100">
                                            <Typography variant="h6" component="h5" className="Montserrat tbold">
                                                Experience Requirements
                                            </Typography>
                                            <Box className="bodyJob Roboto" dangerouslySetInnerHTML={{__html: item.TR1__Experience_Requirements__c}}/>
                                        </Box>
                                    </Card>
                                }
                                {item.TR1__Education_Requirements__c &&
                                    <Card className="col w100">
                                        <Box className="w100">
                                            <Typography variant="h6" component="h5" className="Montserrat tbold">
                                                Education Requirements
                                            </Typography>
                                            <Box className="bodyJob Roboto" dangerouslySetInnerHTML={{__html: item.TR1__Education_Requirements__c}}/>
                                        </Box>
                                    </Card>
                                }
                                {item.TR1__Responsibilities__c &&
                                    <Card className="col w100">
                                    <Box className="w100">
                                        <Typography variant="h6" component="h5" className="Montserrat tbold">
                                            Responsibilities
                                        </Typography>
                                        <Box className="bodyJob Roboto" dangerouslySetInnerHTML={{__html: item.TR1__Responsibilities__c}}/>
                                    </Box>
                                </Card>
                                }
                            </Box>
   
                            <Card className="actionsJob flex w100">
                                    <Typography variant="h3" component="span" className="w100 tbold tl actionsJob-text" sx={{pb:1}}>{item.Title_Website} - {item.TR1__Job_Number__c}</Typography>
                                <Box className="actionsJob-wrapButtons flex row w100">
                                    {item.LinkedIn_Post_URL__c &&
                                        <Link href={item.LinkedIn_Post_URL__c} underline="none" target="_blank">
                                            <Button variant='contained'  sx={{my:0.2, minWidth:"max-content", textTransform:"none", borderRadius:"50px", color:"var(--white)"}} color='linkedin' startIcon={<LinkedIn/>} >View on LinkedIn</Button>
                                        </Link>
                                    }
                                    <ButtonAction 
                                        titleJob={item.Title_Website}
                                        numberJob={item.TR1__Job_Number__c}
                                        idJob={item.Id}
                                        action="open:form-talent"
                                        variant='contained'
                                        sx={{my:0.2, ml:"auto",  minWidth:"max-content", textTransform:"none", borderRadius:"50px"}}
                                        translate="no"
                                    >
                                        Apply ASAP
                                    </ButtonAction>
                                </Box>
                            </Card>

                            <Card className="actionsShare col" pagination>
                                <Button
                                    onClick={() =>share()}
                                    variant='text'
                                    fullWidth
                                    sx={{my:1, textTransform:"none", borderRadius:"50px"}} 
                                    startIcon={<ShareSharp/>}
                                >
                                    Share
                                </Button>
                            </Card>

                            <Card className="row w100">
                                <PostArticleBy
                                    py={0} 
                                    px={max500?0:2}
                                    className="postArticleBy-job"
                                    postBy={{Id:item.TR1__Sourcing_Recruiter__c, type:"Posted"}}
                                    fixed={true}
                                />
                            </Card>

                        </Box>
                    </Box>

                    <Box className="asideJob">
                        <Box className="asideJob-sticky">
                        <Card className="cardAsideJob col w100">
                            <Typography variant="span" component="h2" className="tbold tcenter actionsJob-text" sx={{pb:1}}>{item.Title_Website}</Typography>
                            <Typography variant="span" component="h2" className="tbold tj actionsJob-text" sx={{pb:1}}>{item.TR1__Job_Number__c}</Typography>
                            {item.LinkedIn_Post_URL__c &&
                                <Link href={item.LinkedIn_Post_URL__c} underline="none" target="_blank">
                                    <Button variant='contained' fullWidth sx={{minWidth:"100%", my:1, textTransform:"none", borderRadius:"50px", color:"var(--white)"}} color='linkedin' startIcon={<LinkedIn/>} >View on LinkedIn</Button>
                                </Link>
                            }
                            <ButtonAction 
                                titleJob={item.Title_Website}
                                numberJob={item.TR1__Job_Number__c}
                                idJob={item.Id} 
                                action="open:form-talent" 
                                variant='contained' 
                                fullWidth sx={{minWidth:"100%", my:1, textTransform:"none", borderRadius:"50px", ml:"auto"}}
                                translate="no"
                            >
                                Apply ASAP
                            </ButtonAction>
                        </Card>
                        <Card className="cardAsideJob-share col" pagination>
                            <Button
                                onClick={() =>share()}
                                variant='text'
                                fullWidth
                                sx={{my:1, textTransform:"none", borderRadius:"50px"}} 
                                startIcon={<ShareSharp/>}
                            >
                                Share
                            </Button>
                        </Card>
                        </Box>
                    </Box>
                </Box>
            {/* <SectionSocialMedia/> */}
        </Box>            
    );
}


import { Box, Tab, Tabs } from '@mui/material';
import { useEffect, useState } from 'react';
import './sectionElevationFindAJob.css'
import {Accessibility, Dashboard, Place, Work} from '@mui/icons-material';

export default function SectionElevationFindAJob({onCategory, dataSectionJob, currentCategory}) {
    const icons ={
        customJobs:<Work />,
        jobsByIndustry:<Dashboard />,
        jobsByLocation:<Place />,
        jobsByDivision:<Accessibility />
    }

    const [value, setValue] = useState(currentCategory?currentCategory:0);

    const handleChange = (event, newValue) => {
        // console.log(newValue)
      setValue(newValue);
    };

    useEffect(() => {
        onCategory(value)
    }, [value, onCategory])

    return (
        <Box className="sectionElevationFindAJob flex row center w100" sx={{my:3}}>
            <Tabs
                value={value}
                onChange={handleChange}
                aria-label="Jobs Categories"
                scrollButtons="auto"
                variant="scrollable"
            >
                {
                    dataSectionJob.map((item, index) => {
                        return(
                            <Tab key={item.title} icon={icons[item.id]} className="sectionElevationFindAJob_tab" disableripple="true" value={index} label={<span>{item.title}</span>} />
                        )
                    })
                }

            </Tabs>
      </Box>
    );
}
import { useEffect, useRef, useState } from 'react';
import { Box, Typography } from '@mui/material';
import './sectionImage.css'
import ButtonAction from '../buttonAction/ButtonAction';
import { DoubleArrow } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import asapDefault from '../../assets/images/asap-default.png'

export default function SectionImage({data, classTitle="tl", gutterBottom=true, titleLink}) {
   
    const {id, backgroundColor, body, button, media, showTitle, title} = data 
    const bodySectionRef = useRef(null)
    
    const [mediaLoading, setMediaLoading] = useState(true)
    const navigate = useNavigate()

    useEffect(() => {
        if(bodySectionRef){
            if(id === "70cbd7-86e4-f2c1-6-5071f80a3oa5"){
                bodySectionRef.current.innerHTML = ""
                return
            }
            bodySectionRef.current.innerHTML = body
        }
    }, [bodySectionRef, body, id])
    return (
        <Box className={`sectionImage section${backgroundColor==="Auto"?"White":backgroundColor} flex col center w100 bodyContent`} id={title?title.split(" ").join("-").toLowerCase():""}>
            <Box className={`sectionImage flex col center w100 mw1200`}>
                
                <Box className={`containerImage flex col jccenter w100 noSelect ${mediaLoading?"containerImage_mediaLoaded":""}`}>
                    <Box className="containerImage_image flex center">
                        {(mediaLoading || !media) && <img className="mediaDefault" src={asapDefault} alt={title?title:"asap default"}  width="640" height="360"/>}
                        {media.type === "image" && 
                            <img loading='lazy' src={media.url} alt={title?title:"sectionImage"}  width="640" height="360" onLoad={({isTrusted}) => setMediaLoading(!isTrusted)} onError={({isTrusted})=> setMediaLoading(isTrusted)}/>
                        }                
                        {media.type === "video" && 
                            <video poster={asapDefault}  src={media.url} alt={title?title:"sectionImage"} width="640" height="360" autoPlay loop muted playsInline onLoadedData={({isTrusted}) => setMediaLoading(!isTrusted)} onError={({isTrusted})=> setMediaLoading(isTrusted)}/>
                        }
                    </Box>
                    <div className="containerImage_background"></div>
                </Box>

                <Box className="sectionImage_body flex col w100 mw1000 " sx={{paddingY:3}}>
                    <Box className="flex col sectionImage_body_wrap"  sx={{padding:2}}>
                        {title && showTitle &&
                            <Typography 
                                variant="h3" 
                                gutterBottom={gutterBottom} 
                                component="h1" 
                                className={`sectionImage_body_title tbold ${classTitle}`} 
                                color="black" 
                                sx={{cursor:titleLink?"pointer":"default"}}
                                onClick={() => titleLink && navigate(button.action)}
                            >
                                {title}
                            </Typography>
                        }

                        <Typography ref={bodySectionRef} className="section_text tj" color="dark">
                            {body}
                        </Typography>
                      
                        {data.children && data.children}
                        {button.active &&
                            <Box sx={{m:"auto", mt:2}}>
                                {data.type === "bannerService" &&
                                    <ButtonAction action={button.action} sx={{marginTop:3, borderRadius:"50px"}}>
                                        <Box className="flex row center">
                                            {button.title}
                                            <DoubleArrow color="primary" sx={{ml:button.title?1:"initial"}} />
                                        </Box>
                                    </ButtonAction>
                                }
                                {data.type !== "bannerService" &&
                                    <ButtonAction variant="contained" size="large" sx={{mt:2, borderRadius:"50px"}} action={button.action}>
                                        {button.title}
                                    </ButtonAction>
                                }
                            </Box>
                        }
                    </Box>
                </Box>            
               
            </Box>
        </Box>
    );
}
import { AccountCircle, Work } from '@mui/icons-material';
import { Box, Button, ButtonGroup, useMediaQuery, Typography, Link } from '@mui/material';
import { NavLink } from 'react-router-dom';
import ButtonAction from '../buttonAction/ButtonAction';
import ModalAlert from '../modalAlert/ModalAlert';
import './modalSesion.css'

export default function ModalSesion({modalActive, setModalActive}) {

    const matches = useMediaQuery("(min-width:600px)");

    const data = [
        {title:"Asap Employee", action:"/admin", icon:<AccountCircle color="primary" fontSize="large" sx={{m:0.5}}/>},
        {title:"Temporary Candidate", action:"https://asapstaffing.force.com/Signin", icon:<Work color="primary" fontSize="large" sx={{m:0.5}}/>},
    ]

    const onClicked = (value) => {
        setModalActive(!value)
    }

return(
    <ModalAlert modalActive={modalActive} setModalActive={setModalActive} title="Log in">
        <ButtonGroup orientation={`${matches ? `horizontal` : `vertical`}`} variant="text" aria-label="text button group" color="secondary" fullWidth>       
            {
                data.map((item)=>{
                    return(
                        <ButtonAction 
                            key={item.title} 
                            color="secondary" 
                            className="containerBtn flex col center w100"  
                            sx={{padding:2, height:"auto", borderRadius:4 }}
                            action={item.action}
                            onClicked={onClicked}  
                        >
                            <Box sx={{mt:"auto"}}>
                                {item.icon}
                            </Box>
                            <Typography paragraph className="tcenter" variant="body2" component="h2" color="dark">
                                {item.title}
                            </Typography>
                        </ButtonAction>
                    )
                })
            }
        </ButtonGroup>
    </ModalAlert>
)
}
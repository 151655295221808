import { useContext, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import './siteContents.css'
import Context from '../../../contexts/Context';
import SubBarSearch from '../../../components/subBarSearch/SubBarSearch';
import ModalTypeSection from '../../../components/siteContentsComponents/ModalTypeSection';
import ListDragEditor from '../../../components/siteContentsComponents/ListDragEditor';
import SectionServiceEditor from '../../../components/siteContentsComponents/SectionServiceEditor';
import ContextSiteContents from '../../../contexts/ContextSiteContents';
import {arrayNewOrder} from '../../../services/serviceslocal' 

export default function SiteContents() {

    const {siteContents, arrayServices} = useContext(Context)
    const [dataSiteContent, setDataSiteContent] = useState(siteContents)
    const [pageSelected, setPageSelected] = useState(false)
    const [addNewSection, setAddNewSection] = useState(false)
    
    const [modalActive, setModalActive] = useState(false)

    useEffect(() => {
        setDataSiteContent(prevState => {
            let newArray = [] 
            prevState.forEach((page) => {
                if(page.title === pageSelected.title){
                    return(newArray.push(pageSelected))
                }
                newArray.push(page)
            })
            return(newArray)
        })
    }, [pageSelected])
    
    
    // actionsButtons
    const actionsDefaults = [
        {title:"Open form for Client", href:"open:form-client", group:"Actions"},
        {title:"Open form for Talent", href:"open:form-talent", group:"Actions"},
        {title:"Open form Newsletters", href:"open:form-newsletters", group:"Actions"},
        {title:"Open modal map location", href:"open:map-location", group:"Actions"},
        {title:"Call to +13059611183", href:"call:+13059611183", group:"Actions"},
        {title:"Send Mail contact Asap", href:"mail:contact@asapstaffingservices.com", group:"Actions"},
    ]
    const actionsSiteContents = siteContents.filter(item => item.href !== undefined).map((page) => {
        if(page.id === "talent" || page.id === "company"){
            return({title: `Link to Home for ${page.title}`, href:page.href, group:"Links"})
        }
        return({title: `Link to ${page.title}`, href:page.href, group:"Links"})
    })
    const actionsServices = arrayServices.map(({title}) => ({title: `Link to Service ${title}`, href:`/services/${title.toLowerCase().replaceAll(' ', '-')}`, group:"Links"}))

    const generalActions = [...actionsDefaults, ...actionsSiteContents, ...actionsServices]

    return (

        <ContextSiteContents.Provider value={{generalActions}}>       
            <Box className="siteContents flex col w100 aicenter" sx={{pb:2}} id="article-visor">
                <SubBarSearch
                    title="Site Contents"
                    noSearch
                />
                <Box className="flex col aicenter w100 mw900" sx={{px:1}}>
                
                    {arrayNewOrder(dataSiteContent, "position").map(page => {
                        return(
                            <Box key={page.id} sx={{display:"contents"}}>
                                <SectionServiceEditor data={page}/>
                                {pageSelected && pageSelected.title === page.title &&
                                    <ListDragEditor 
                                        array={pageSelected.data}
                                        data={pageSelected}
                                        setPageSelected={setPageSelected} 
                                        addNewSection={addNewSection} 
                                        setAddNewSection={setAddNewSection}/>
                                }
                            </Box>
                        )
                    })}
                    
                
                </Box>
                {pageSelected && <ModalTypeSection modalActive={modalActive} setModalActive={setModalActive} data={pageSelected} />}
            </Box>
        </ContextSiteContents.Provider>
    );
}
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter } from "react-router-dom";
import TagManager from 'react-gtm-module'
import { HelmetProvider } from 'react-helmet-async';

const tagManagerArgs = {
    gtmId: 'G-WKYW124PJF'
}
TagManager.initialize(tagManagerArgs)

ReactDOM.render(
  <React.Fragment>
    <HelmetProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </HelmetProvider>
  </React.Fragment>,
  document.getElementById('root')
);


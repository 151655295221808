
import { useEffect, useState } from 'react';
import {Box, Card, CardContent, Button, Typography, TextField, FormControl, IconButton, ButtonBase, CardHeader} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import './resetPassword.css'
import logoWhite from '../../../assets/images/logoAsapWhite.png'
import { auth } from '../../../services/firebaseConfig';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import ModalAlert from '../../../components/modalAlert/ModalAlert'
import { updateMetas } from '../../../services/serviceslocal';

export default function ResetPassword() {

    
    useEffect(() => {
        updateMetas({title:"Reset Your Password"})
      }, [])

    let {search} = useLocation();
    let params = new URLSearchParams(search)
    const code = params.get('oobCode');
    // const mode = params.get('mode');
    // const apiKey = params.get('apiKey');
    // const lang = params.get('lang');
    const navigate = useNavigate()

    const [email, setEmail] = useState(false)
    const [actionCode, setActionCode] = useState(false)
    const [password, setPassword] = useState("")
    const [showPassword, setShowPassword] = useState(false)
    const [passwordConfirm, setPasswordConfirm] = useState("")
    const [showPasswordConfirm, setShowPasswordConfirm] = useState(false)
    const [errorMessage, setErrorMessage] = useState(false)
    const [modalActive, setModalActive] = useState(false)
    const [modalSuccessActive, setModalSuccessActive] = useState(false)


    // console.log(code)
    // console.log(mode)
    // console.log(apiKey)
    // console.log(lang)

    useEffect(() => {
        auth.verifyPasswordResetCode(code)
        .then(function(email) {
          setEmail(email)
          setActionCode(code)
        })
        .catch(function(error) {
            setErrorMessage(error.message.replaceAll('Firebase: ', ''))
            setModalActive(true)
        })
    }, [code])
    

    const handleSubmit = async (e) =>{
        e.preventDefault()
        auth.confirmPasswordReset(actionCode, password)
        .then(async (resp) => {
            setModalSuccessActive(true)
        })
        .catch((error) => {
            setErrorMessage(error.message.replaceAll('Firebase: ', ''))
            setModalActive(true)
        })
    }

    return (
        <Box className="resetPassword flex col center">
            <ModalAlert 
                title="Try resetting your password again" 
                modalActive={modalActive} 
                setModalActive={setModalActive}
                onClick={() => navigate("/admin")}
            >
                <Typography paragraph className="tl" variant="body2" component="h2" color="dark">
                    {errorMessage}
                </Typography>
            </ModalAlert>

            {/* success */}
            <ModalAlert 
                title="Password Changed!" 
                modalActive={modalSuccessActive} 
                setModalActive={setModalSuccessActive}
                onClick={() => navigate("/admin")}
            >
                <Typography paragraph className="tl" variant="body2" component="h2" color="dark">
                    Your password has been changed successfully.
                </Typography>
            </ModalAlert>


            <Box sx={{my:2}}>
                <img alt="Log" src={logoWhite} className="logoForm"/>
            </Box>
            
            {!errorMessage && 
                <Card sx={{ maxWidth:400, p:3}}>
                    <CardContent>
                        <Box>
                            <Typography 
                                component="h2" 
                                variant="h5" 
                                align="center"
                                color="primary"
                                gutterBottom
                            >
                                Reset your password
                            </Typography>
                            {email && 
                                <Typography 
                                    component="h2" 
                                    variant="body1" 
                                    align="center"
                                    color="dark"
                                    gutterBottom
                                >
                                    for {email}
                                </Typography>
                            }
                            </Box>
                        <form onSubmit={handleSubmit}>
                            <FormControl sx={{ my: 1}} variant="standard" fullWidth>
                                <TextField 
                                    type={showPassword ? 'text' : 'password'}
                                    id="password" 
                                    label="Password" 
                                    required
                                    variant="standard" 
                                    value={password}
                                    fullWidth
                                    onChange={({target})=>{setPassword(target.value)}}
                                />
                                <Box className="actionInput">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setShowPassword(!showPassword)}
                                        
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </Box>
                            </FormControl> 
                            
                            <FormControl sx={{ my: 1}} variant="standard" fullWidth>
                                <TextField 
                                    type={showPasswordConfirm ? 'text' : 'password'}
                                    id="passwordConfirm" 
                                    label="Confirm Password" 
                                    required
                                    variant="standard" 
                                    value={passwordConfirm}
                                    fullWidth
                                    onChange={({target})=>{setPasswordConfirm(target.value)}}
                                />
                                <Box className="actionInput">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setShowPasswordConfirm(!showPasswordConfirm)}
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </Box>
                            </FormControl> 
                            
                            <Box sx={{mt:5, mb:1}}>
                                <Button 
                                    variant="contained" 
                                    size="large" 
                                    fullWidth
                                    type="submit"
                                    >
                                    Reset Password
                                </Button>
                            </Box>
                        </form>

                        <Box className="flex center w100">
                            <NavLink to={`/admin`}>
                            <ButtonBase 
                                variant='text'
                                size="large" 
                                color="secondary"
                                >
                                Cancel
                            </ButtonBase>
                            </NavLink>
                        </Box>
                    </CardContent>                   
                </Card>
            }
        </Box>
    );
}

import { Box} from '@mui/material';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Context from '../../../contexts/Context';
import HomeCompany from '../../company/homeCompany/HomeCompany';
import HomeTalent from '../../talent/homeTalent/HomeTalent';
import './home.css'


export default function Home() {

    const {typeClient, setTypeClient} = useContext(Context)
    const navigate = useNavigate()

    useEffect(() => {
      navigate(`/${typeClient}`)
    }, [typeClient])
    
    return (
        <Box className=" flex col center w100">
            {typeClient === "company" && <HomeCompany/>}
            {typeClient === "talent" && <HomeTalent/>}
            
        </Box>
    );
}


import { useEffect, useState } from "react";
import { Box, Checkbox, FormControlLabel, TextField } from "@mui/material";
import { AccountCircle, Email, Message, Phone, } from "@mui/icons-material";


export default function FormContactInformation({ handlerChange, contactInformation, setContactInformation, checkActive, setCheckActive}) {
        
    const [name, setName] = useState(contactInformation?contactInformation.name:"")
    const [email, setEmail] = useState(contactInformation?contactInformation.email:"")
    const [phone, setPhone] = useState(contactInformation?contactInformation.phone:"")
    const [message, setMessage] = useState(contactInformation?contactInformation.message:"")


    useEffect(() => {
        setContactInformation({name, email, phone, message})
    }, [name, email, phone, message])

    return (
        <Box className="flex col w100">
            <Box className="flex row" sx={{ alignItems: "center", my: 1 }}>
                <AccountCircle sx={{ color: "gray", mr: 1, my: 0.5 }} />
                <TextField          
                    className="w100"
                    label="Full name"
                    size="small"
                    value={name}
                    onChange={({currentTarget}) => {handlerChange(setName, currentTarget)}}
                    id="nameInput"
                />
            </Box>
            <Box className="flex row" sx={{ alignItems: "center", my: 1 }}>
                <Email sx={{ color: "action.active", mr: 1, my: 0.5 }} />
                <TextField
                    sx={{ p: 0 }}
                    className="w100"
                    label="Email address"
                    size="small"
                    variant="outlined"
                    value={email}
                    onChange={({currentTarget}) => {handlerChange(setEmail, currentTarget)}}
                    id="emailInput"
                />
            </Box>
            <Box className="flex row" sx={{ alignItems: "center", my: 1 }}>
                <Phone sx={{ color: "action.active", mr: 1, my: 0.5 }} />
                <TextField
                    className="w100"
                    label="Phone Number"
                    size="small"
                    variant="outlined"
                    value={phone}
                    onChange={({currentTarget}) => {handlerChange(setPhone, currentTarget)}}
                    id="phoneInput"
                />
            </Box>
            <Box className="flex row" sx={{ alignItems: "center", my: 1 }}>
                <Message sx={{ color: "action.active", mr: 1, my: 0.5 }} />
                <TextField
                    className="w100"
                    multiline
                    label="Message"
                    variant="outlined"
                    value={message}
                    onChange={({currentTarget}) => {handlerChange(setMessage, currentTarget)}}
                    id="messageInput"
                />
            </Box>

            <FormControlLabel
                sx={{ mr: 0, ml: "auto" }}
                control={
                    <Checkbox
                        checked={checkActive}
                        onChange={(e) => {
                            setCheckActive(e.target.checked);
                        }}
                    />
                }
                label="Looking for Top Talent?"
            />
        </Box>  
     );
}

import {ButtonBase, Paper} from '@mui/material';
import './card.css'

export default function Card({ children, className="", button, responsive, onClick, pagination}) {
    return (
        <Paper 
            elevation={3} 
            sx={{p:button || responsive || pagination?"initial":3, mt:responsive?2:pagination?1:1, display: { xs: 'block', sm: 'flex' }}} 
            className={`w100 card flex col center ${className}`}
        >
                {button?
                    <ButtonBase 
                        onClick={onClick}
                        className={`w100 ${responsive?"col":"initial"}`} 
                        sx={{p:responsive || pagination?"initial":3}}
                    >
                        {children}
                    </ButtonBase>
                :
                children
            }
        </Paper>
    );
}
import { NavigateNext } from '@mui/icons-material';
import { Breadcrumbs, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import Context from '../../contexts/Context';
import { scrollTop, updateMetas, updateTitleWeb } from '../../services/serviceslocal';
import './breadcrumbsCustomized.css'
import HelmetComponent from '../helmetComponent/HelmetComponent';


export default function BreadcrumbsCustomized({title, noScroll, color="inherite", description, image}) {
    const {typeClient, siteContents} = useContext (Context)
    const sectionJobsURL = [
        {title:"Jobs", href:"/find-a-job/jobs", path:"jobs"},
    ]
    const sectionsURL = siteContents.map(({title, href, id:path}) => ({title, href, path}))
    
    const sections = [...sectionJobsURL,  ...sectionsURL]
    const {pathname} = useLocation()
    const [arrayParams, setArrayParams] = useState([])
    const [helmetData, setHelmetData] = useState()
    
    useEffect(() => {
        setArrayParams(pathname.toLowerCase().split('/').filter((x) => x))
        if(noScroll){
            return
        }
        scrollTop()
    }, [pathname, noScroll]);


    useEffect(() => {
        let url = window.location.href
        if(title){
            updateMetas({title:title, url:url, description:description, image})
            setHelmetData({title:title, url:url, description:description, image})
        }else{
            if(arrayParams.length > 0){
                let item = sections.find(item =>  item.path ===  arrayParams.at(-1))
                    if(item){
                        updateMetas({title:item.title, url:url, description:description, image})
                        setHelmetData({title:item.title, url:url, description:description, image})                  
                    }
            }
        }
    }, [title, arrayParams])

      return (
          <Breadcrumbs className="breadcrumbsCustomized" aria-label="breadcrumb" sx={{mr:"auto", p:2}} separator={<NavigateNext fontSize="small" color="secondary"/>} >
            {helmetData && <HelmetComponent data={helmetData}/>}
            <NavLink  to={`/${typeClient === "company"?"company":"talent"}`}  className="breadcrumbsCustomized_text flex row aicenter">
                <Typography  className="breadcrumbsCustomized_text" color={color} translate="no">
                    Home
                </Typography>
            </NavLink>
            {sections.length > 0 && arrayParams.length > 0 &&
                arrayParams.map((param) => {
                    let item = sections.find(item =>  item.path ===  param)
                    if(!item){
                        item = {title:title?title:param.split("-").join(" "), href:{pathname}}
                    }
                    return(
                        <NavLink to={item.href}
                            className={"capitalize"}
                            key={item.title}
                        >
                            <Typography 
                                className="breadcrumbsCustomized_text"
                                color={arrayParams.at(-1) === param?"primary":color}
                            >
                                {item.title}
                            </Typography>
                        </NavLink> 
                    )
                })
            }
            {/* {
                arrayParams.map((param, index) => {
                    let elements = (getRangeParams(arrayParams , index + 1))
                    let href = `/${elements.join('/')}`
                    return(
                        <NavLink to={href}
                            className={"capitalize"}
                            key={param}
                        >
                            <Typography 
                                className="breadcrumbsCustomized_text"
                                color={arrayParams.at(-1) === param?"primary":"inherite"}
                            >
                                {arrayParams.at(-1) === param && title?title:urlToString(param)}
                            </Typography>
                        </NavLink> 
                    )
                })
            } */}
        </Breadcrumbs>
    );
}


import { useContext, useEffect, useState } from 'react';
import './aboutUsTeam.css'
import { Box, Typography } from '@mui/material';
import HeaderImage from '../../../components/headerImage/HeaderImage';
import BreadcrumbsCustomized from '../../../components/breadcrumbsCustomized/BreadcrumbsCustomized';
import Team from '../../../components/team/Team'
import Context from '../../../contexts/Context';
import { useNavigate, useParams } from 'react-router-dom';
import { clone, orderArray } from '../../../services/serviceslocal';
import SectionOnlytext from '../../../components/sectionOnlytext/SectionOnlytext';
import SectionImage from '../../../components/sectionImage/SectionImage';
import SectionContactUs from '../../../components/sectionContactUs/SectionContactUs';

export default function AboutUsTeam() {
    const {team, siteContents} = useContext (Context)
    let {data} = clone(siteContents.find(item => item.id === "meet-our-team"))
    let bannerSimple = data.find(item => item.type === "bannerSimple")
    const {data: dataContacUs} = siteContents.find(item => item.id === "contact-us")

    const teamData = {
        type: "teamData",
        position: data.length + 1,
        backgroundColor:"White"
    }
    data = [...data, teamData]

    const contactSocialMedia = clone(dataContacUs.find(item => item.id === "contactSocialMedia"))
    contactSocialMedia.position = data.length + 1
    data = [...data, contactSocialMedia]

    const {employee} = useParams()
    const [currentEmployee, setCurrentEmployee] = useState(employee)
    const [isOpenDepartament, setIsOpenDepartament] = useState(false)
    const [arrayEmployees, setArrayEmployees] = useState([])
    let navigate = useNavigate();

    useEffect(() => {
        setArrayEmployees(team)
    }, [team])
    
    useEffect(() => {
      if(employee && arrayEmployees.length > 0){
        // console.log(employee.replaceAll("-", " ").replaceAll("---", "-"))
        const found = arrayEmployees.find(element => element.Name.toLowerCase() === employee.replaceAll("-", " ").replaceAll("   ", "-").toLowerCase());
        // console.log(found)
        if(found === undefined){
            return(navigate("/about-us/meet-our-team"))
        }else{
            setCurrentEmployee(found)
        }
        // setCurrentEmployee(employee?.replaceAll("-", " ").toLowerCase())
      }else{
        setCurrentEmployee(undefined)
      }
    }, [employee, arrayEmployees])

    return (
        <Box className="aboutUs flex col center w100 minh100">
            <BreadcrumbsCustomized noScroll={isOpenDepartament} title={currentEmployee && currentEmployee.Name} description={currentEmployee?.AboutMe}/>
            {
                orderArray(data, "position").map(item => {
                    let {id, type} = item
                    if((type === "sectionImage" || type === "sectionContact")){
                        let prevBackgroundColor = data.find(prevItem => prevItem.position === item.position - 1).backgroundColor
                        item.backgroundColor = prevBackgroundColor === "White"?"Gray":"White"
                    }
                    return(
                        type === "bannerSimple"?
                            <HeaderImage key={id}
                                animate
                                fixed
                                image={bannerSimple.media}                
                            >
                                <Typography variant="h2" component="h1" className="tbold tcenter">
                                    {bannerSimple.title}
                                </Typography>
                            </HeaderImage>
                        :type === "teamData"?
                            <Team key="teamData" currentEmployee={currentEmployee} setIsOpenDepartament={setIsOpenDepartament} arrayEmployees={arrayEmployees} isTeam/>
                        :type === "section"?
                            <SectionOnlytext key={id} data={item} /> 
                        :type === "sectionImage" ?
                            <SectionImage key={id} data={item} titleLink/>
                        :type === "sectionContact" &&
                            <SectionContactUs key={id} data={item}/>
                    )
                })
            }
           
        </Box>
    );
}


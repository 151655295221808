import { useContext, useEffect, useState } from 'react';
import { Box, Button, Fab, Typography, useMediaQuery } from '@mui/material';
import CardArticle from '../../../components/cardArticle/CardArticle';
import CardArticleResponsive from '../../../components/cardArticleResponsive/CardArticleResponsive';
import Card from '../../../components/card/Card';
import AddIcon from '@mui/icons-material/Add';
import SubBarSearch from '../../../components/subBarSearch/SubBarSearch';
import './articleVisor.css'
import { useNavigate, useLocation} from 'react-router';
import BarSearch from '../../../components/barSearch/BarSearch';
import {sortDescendant } from '../../../services/serviceslocal';
import Context from '../../../contexts/Context';
import SkeletonList from '../../../components/skeletonList/SkeletonList';
import PaginationCustomized from '../../../components/paginationCustomized/PaginationCustomized';
import { SearchOffOutlined } from '@mui/icons-material';
import Preloader from '../../../components/preloader/Preloader';

export default function ArticleVisor({subBarSearch, cms, barSearch, currentPage, setCurrentPage, setIdArrayPagination}) {
    const max500 = useMediaQuery("(max-width: 500px)");

    let navigate = useNavigate();
    let {pathname} = useLocation();
    let {key, search} = useLocation();
    let params = new URLSearchParams(search)
    const title = params.getAll('title');

    const {allResources} = useContext(Context)
    const [arrayByType, setArrayByType] = useState([])

    useEffect(() => {
        if(allResources.length > 0){
            // setArrayArticles(cms?allResources:[...new Set(allResources.filter((item) => item.active === true))])
            
            setArrayByType(sortDescendant(cms?allResources:allResources.filter((item) => item.active === true), "fullDate"))
        }
    }, [allResources, cms])
    
    const [arrayArticlesFilters , setArrayAticlesFilters] = useState([])
    const [arrayFromPagination, setArrayFromPagination] = useState([])
    const [isFilters , setIsFilters] = useState(false)

    useEffect(() => {
        if(arrayByType.length > 0){
            if(title.length > 0){
                return(filter(title, arrayByType)) 
            }
            setIsFilters(false)
            setArrayAticlesFilters(arrayByType)
        }
        }, [key, arrayByType])
        

    const filter = (arrayParams, array) => {
        let arrayTemp = []
        arrayParams.forEach(param => {
            let result = array.filter(item => item.title.toLowerCase().includes(param))
            if(result.length > 0){
                arrayTemp = [...arrayTemp, ...result]
            } 
        });
        setIsFilters(true)
        setArrayAticlesFilters([...new Set([...arrayTemp])])
    }

    useEffect(() => {
        if(isFilters && arrayArticlesFilters.length === 0){
            setArrayFromPagination([])
        }
    }, [arrayArticlesFilters, isFilters])


    const formatQueryString = (key, arrayParameters) => {
        let arrayKeys = arrayParameters.map(element => (`${key}=${element.toLowerCase()}`));
        return(arrayKeys.join('&'))     
    }

    const getParameters = (araryParams) => {
        navigate(`${pathname}?${formatQueryString("title", araryParams)}`)
    }

    useEffect(() => {
        if(arrayArticlesFilters.length > 0) {
            let string = `${arrayArticlesFilters.length}-${arrayArticlesFilters[0].id}-${arrayArticlesFilters.at(-1).id}`
            setIdArrayPagination(string.replaceAll(" ", ""))
        }
    }, [arrayArticlesFilters])
      

    return (
        <Box className="articleVisor flex col w100 aicenter" sx={{pb:2}} id="article-visor">
            <Preloader showPreloader={!cms && allResources.length === 0} />
            {subBarSearch &&
                <SubBarSearch 
                    title="Resources"
                    placeholder="Search article"
                    getParameters={getParameters} 
                    arrayParam={title}                   
                />
            }
             <Box className="containerResources flex col aicenter w100 mw900" sx={{px:1}}>
             
                {barSearch &&
                    <BarSearch 
                        placeholder="Search article"
                        getParameters={getParameters}
                        arrayParam={title}
                    />
                }
                {isFilters && arrayArticlesFilters.length > 0 && 
                    <Typography className="w100 tcenter" variant="subtitle1" color="dark"  sx={{pt:1}} >
                        Items found: {arrayFromPagination.length}
                    </Typography>
                }

                {allResources.length === 0 &&
                    <SkeletonList h={188} />
                } 
                {
                    sortDescendant(arrayFromPagination, "fullDate").map((article) => {
                        return(
                            <Box key={article.title} sx={{display:"contents"}}> 
                                {max500?
                                    <CardArticleResponsive article={article} cms={cms}/>
                                :
                                    <CardArticle  article={article} cms={cms}/>
                                }                           
                            </Box>

                        )
                    })
                }
                
                {arrayArticlesFilters.length > 0 &&
                    <Box className="flex w100" sx={{mt:"auto"}}>
                        <Card className="cardPagination col w100 center" pagination>
                            <PaginationCustomized
                                array={arrayArticlesFilters}
                                setArray={setArrayFromPagination}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                scrollId="article-visor"
                            /> 
                        </Card>
                    </Box>
                }
                {isFilters && arrayArticlesFilters.length === 0 &&  
                    <Box className="jobs_container_no_find flex col center">
                        <SearchOffOutlined className="noFind" color="secondary" sx={{width:"200px", height:"200px"}} />

                        <Typography className="w100 tcenter" variant="subtitle1" color="dark" sx={{my: 3}} >
                            Can’t find any Article.
                        </Typography>

                        <Button variant="outlined" onClick={() => {navigate(pathname)}}>
                            Clear your filters and try again 
                        </Button>
                    </Box>
                }
            </Box>
           
            {cms &&
                <Fab 
                    onClick={()=>{navigate("article-editor")}}
                    color="primary" 
                    aria-label="add" 
                    className="fab" 
                    title="Add New Article"
                >
                    <AddIcon/>
                </Fab>
            }
        </Box>
    );
}
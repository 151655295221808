import { useEffect, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { Box, FormControl, IconButton,Input,InputAdornment, InputLabel, TextField } from '@mui/material';
import './barSearch.css'
import { DeleteSweep } from '@mui/icons-material';


export default function BarSearch({placeholder, getParameters, arrayParam}) {

    const [value, setValue] = useState("")
    
    const handleSearch = (e) => {
        e.preventDefault()
        const noNull = value.split(" ").filter(item => item !== "")
        const arrayParameters = [...new Set([...noNull])]
        getParameters(arrayParameters)
    }
    const handleReset = () => {
        setValue("")
        getParameters([])
    }

    useEffect(() => {
      if(arrayParam.length > 0){
        setValue([...new Set([...arrayParam])].join(' '))
      }else{
        setValue("")
      }
    }, [arrayParam])

    return (
        <Box className="flex w100" sx={{mt:2}}>
            <Box className="barSearch">
                <form onSubmit={handleSearch}>
                    <Input
                        type='text'
                        className="barSearchInput"
                        value={value}
                        placeholder={placeholder}
                        fullWidth
                        disableUnderline
                        onChange={(e)=> {setValue(e.target.value)}}
                        sx={{pl:value?0:"10px"}}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton onClick={handleSearch} color="primary">
                                    <SearchIcon />
                                </IconButton>
                            </InputAdornment>
                        }
                        startAdornment={value &&
                            <InputAdornment position="start">
                                <IconButton onClick={() => handleReset()}>
                                    <DeleteSweep />
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                 </form>

            </Box>
        </Box>
    );
}

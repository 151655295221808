import { useContext, useState } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import './reports.css'
import Card from '../../../components/card/Card';
import Context from '../../../contexts/Context';
import { DataUsage, Download, PlaylistAddCheckRounded, SupervisedUserCircle, Sync } from '@mui/icons-material';
import SubBarSearch from '../../../components/subBarSearch/SubBarSearch';
import { getData } from '../../../services/getData';
import BackdropLoading from '../../../components/backdropLoading/BackdropLoading';
import { getDateString } from '../../../services/serviceslocal';

export default function Reports() {
    const {lastDateSyncUsers, lastDateSyncJobs, team, allJobs} = useContext(Context)

    const [isLoading, setIsLoading] = useState(false)

    function downloadObjectAsJson(exportObj, exportName){
        var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(exportObj, null, 4));
        var downloadAnchorNode = document.createElement('a');
        downloadAnchorNode.setAttribute("href",     dataStr);
        downloadAnchorNode.setAttribute("download", exportName + ".json");
        document.body.appendChild(downloadAnchorNode); // required for firefox
        downloadAnchorNode.click();
        downloadAnchorNode.remove();
        setIsLoading(false)
    }

    const categories = [
        {name:"Local", type:"local"},
        {name:"Firebase", type:"fb"},
        {name:"SalesForce", type:"sf"}
    ]

    const items = [
        {id:"users", title:"Download File Employees", icon:<SupervisedUserCircle sx={{mr:1}}/>, sf:"salesforce/users", fb:"fb/users", fullDate:lastDateSyncUsers, local:team},
        {id:"jobs", title:"Download File Jobs", icon:<PlaylistAddCheckRounded sx={{mr:1}} />, sf:"salesforce/jobs", fb:"fb/jobs", fullDate:lastDateSyncJobs, local:allJobs},
        // {id:"data", title:"Download Files(Jobs and Employees)", icon:<DataUsage sx={{mr:1}} />, path:"salesforce/sync/all"}
    ]

    return (

        <Box className="articleVisor flex col w100 aicenter" sx={{pb:2}} id="article-visor">
            <SubBarSearch
                title="My Account"
                noSearch
            />
            {categories.map(category =>{
                return(
                    <Card key={category.type} className="flex col aicenter w100 mw900" sx={{px:1}}>
                        <Typography variant="h5" component="span" className="tcenter Montserrat" color="dark" sx={{mr:"auto"}} >
                            Current {category.name}
                        </Typography>
                        {items.map(item => {
                            return(
                                <Card key={item.id} className="flex row center">
                                    <Box className="flex row w100 center">
                                        {item.icon}
                                        <Box className="flex col aicenter w100">
                                            <Typography variant="body1" component="span" className="tcenter" color="dark" sx={{mr:"auto"}} >
                                                {item.title}
                                            </Typography>
                                            {item.id !== "data" &&
                                                <Typography variant="body2" component="span" className="tl" color="gray" sx={{mr:"auto"}} fontSize="12px" >
                                                    Last sync date:  {item.fullDate}
                                                </Typography>
                                            }
                                        </Box>
                                        <Box>
                                            <IconButton 
                                                onClick={async () => {
                                                    setIsLoading(true)
                                                    if(category.type === "local"){
                                                        downloadObjectAsJson(item.local, `${item.id} - ${getDateString()}`)
                                                    }else{
                                                        downloadObjectAsJson( await getData(item[category.type]), `${item.id} - ${getDateString()}`)
                                                    }
                                                }}>
                                                <Download color="primary"/>
                                            </IconButton>
                                        </Box>
                                    </Box>
                                </Card>
                            )
                        })}
                    </Card>
                )
            })
            }
           
            <BackdropLoading openBackDrop={isLoading} sync/>
        </Box>
    );
}
import React, { useState, useEffect, useRef } from "react";
import { ContentState, convertFromHTML, convertFromRaw, Editor, EditorState, RichUtils } from "draft-js";
import "draft-js/dist/Draft.css";
import './richEditorSiteContents.css'
import { Button } from "@mui/material";
import { Box } from "@mui/system";

import { FormatListBulleted, FormatListNumbered, Title, FormatBold, FormatItalic } from '@mui/icons-material';

function RichEditorSiteContents({data, fixed=false, onlyText}) {
    let {body = ""} = data

    const emptyContentState = convertFromRaw({
        entityMap: {},
        blocks: [
          {
            text: "",
            key: "foo",
            type: "unstyled",
            entityRanges: [],
          },
        ],
      });

    const [editorState, setEditorState] = useState(EditorState.createWithContent(emptyContentState));

    useEffect(() => {
        // setEditorState(EditorState.createWithContent(ContentState.createFromText(body)));
        if(body){
            setEditorState(EditorState.createWithContent(
                ContentState.createFromBlockArray(convertFromHTML(body)))
            );
        }
      }, []);
    
      const editor = useRef(null);

    useEffect(() => {
        if(editor){
            data.body = editor.current.editor.innerHTML.replaceAll(`"`, `'`)
            // console.log(editor.current.editor.innerHTML.replaceAll(`"`, `'`))
            // // console.log(editor.current)
            // // console.log(editor.current.editorContainer.innerText)
            // let childrensEditor = editor.current.editorContainer.getElementsByClassName("public-DraftEditor-content")[0].children[0].children
            // // console.log(childrensEditor)
            // let arrayChildrenEditor = Array.from(childrensEditor)

            // let data = arrayChildrenEditor.map(child => {
            //     let type = child.localName
            //     let data = child.innerHTML
            //     return({type, data})
            // })
            // console.log(data)
        }
    }, [editorState, editor, data])



    const StyleButton = (props) => {
        let onClickButton = (e) => {
            e.preventDefault();
            props.onToggle(props.style);
        };
        return <Button sx={{lineHeight: 0}} id={props.id} className="richEditor-btn" variant="text" color="secondary" onMouseDown={onClickButton} >{props.label}</Button>;
    };

    //BlockStyleControls Component
    const BLOCK_TYPES_full = [
        { id:"unstyled-item", label: <Title sx={{ fontSize: "17px" }}/>, style: "unstyled***" },
        { label: "H1", style: "header-one" },
        // { label: "H2", style: "header-two" },
        // { label: "H3", style: "header-three" },
        // { label: "H4", style: "header-four" },
        // { label: "H5", style: "header-five" },
        // { label: "H6", style: "header-six" },
        { id:"unordered-list-item", label: <FormatListBulleted sx={{ fontSize: "16px" }}/>, style: "unordered-list-item" },
        { id:"ordered-list-item", label: <FormatListNumbered sx={{ fontSize: "16px" }}/>, style: "ordered-list-item" },
        // { label: <Code/>, style: "code-block" },
    ];
    const BLOCK_TYPES_onlyText = [
        { id:"unstyled-item", label: <Title sx={{ fontSize: "17px" }}/>, style: "unstyled***" }
    ];

    const arrayBlockStyle = onlyText?BLOCK_TYPES_onlyText:BLOCK_TYPES_full

    const BlockStyleControls = (props) => {
        return (
        <Box className="flex">
            {arrayBlockStyle.map((type) => (
            <StyleButton
                id={type.id}
                key={type.style}
                label={type.label}
                onToggle={props.onToggle}
                style={type.style}
            />
            ))}
        </Box>
        );
    };

    //InlineStyleControls Component
    const INLINE_STYLES = [
        { label: <FormatBold sx={{ fontSize: "17px" }}/>, style: "BOLD" },
        { label: <FormatItalic sx={{ fontSize: "17px" }}/>, style: "ITALIC" },
        // { label: <FormatUnderlined sx={{ fontSize: "17px", marginTop:"auto"}}/>, style: "UNDERLINE" },
        // { label: <Code sx={{ fontSize: "17px" }}/>, style: "CODE" },
    ];
    const InlineStyleControls = (props) => {
        return (
        <Box className="flex">
            {INLINE_STYLES.map((type) => (
            <StyleButton
                key={type.style}
                label={type.label}
                onToggle={props.onToggle}
                style={type.style}
            />
            ))}
        </Box>
        );
    };

    const onInlineClick = (e) => {
        let nextState = RichUtils.toggleInlineStyle(editorState, e);
        setEditorState(nextState);
    };

    const onBlockClick = (e) => {
        let nextState = RichUtils.toggleBlockType(editorState, e);
        setEditorState(nextState);
    };


    return (
        <Box className={`richEditorSiteContents w100 ${!fixed?"richEditorSiteContents-edit":""} bodyContent`}>
            {!fixed &&
                <Box className="richEditorSiteContents-constrols-wrapper flex w100 row aicenter" sx={{py:0.5}}>
                    <Box className="richEditorSiteContents-constrols flex w100 row">
                        <BlockStyleControls onToggle={onBlockClick} />
                        <InlineStyleControls onToggle={onInlineClick} />
                    </Box>
                </Box>
            }
            <div className="richEditorSiteContents-input">
                <Editor
                    // readOnly={fixed}
                    ref={editor}
                    editorState={editorState}
                    onChange={(editorState) => {setEditorState(editorState)}}            
                />
            </div>
        </Box>
    );
}

export default RichEditorSiteContents;

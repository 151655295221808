import './cardTestimony.css'
import { Box, Typography } from '@mui/material'
import { FormatQuote } from '@mui/icons-material'
import Card from '../../card/Card'
import { Zoom} from 'react-awesome-reveal';

const CardTestimony = ({item}) => {
    

    return (
        <Zoom>
            <Box className="cardTestimony_wrapper flex center" m={{xs: 0.5, sm: 1}}>
                <Card button responsive className="cardTestimony_card">
                    <Box className="cardTestimony flex col center w100"
                    px={{xs: 2, sm: 3}} py={{xs: 1, sm: 2}} 
                    //    sx={{px:3, py:2}}
                    >
                        <Box className="w100 flex row aicenter">
                            <Typography className="tl tbold w100" variant='h2' component='h2' color="primary" sx={{pr:2}}>
                                {item.title}
                            </Typography>
                            <Box className="cardTestimony-quote flex center" padding={{xs: 1, sm: 2}} >
                                <FormatQuote color="white" fontSize='medium'/>
                            </Box>
                        </Box>
                        
                        <Typography className="w100 tj" color="dark" my={{xs: 0.5, sm: 2}}>
                            {item.message}
                        </Typography>  
                        <Typography className="w100 tr" color="dark" variant='body2' component='h3' sx={{pr:2}}>
                            {/* {item.emisor} */}
                        </Typography> 
                    </Box>
                </Card> 
            </Box>    
        </Zoom>                
    )
}

export default CardTestimony

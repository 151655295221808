import { Box, Button, Chip } from '@mui/material';
import './jobsByLocation.css'
import {Zoom} from 'react-awesome-reveal';
import { separeArray } from '../../services/serviceslocal';

export default function JobsByLocation({arrayJobs, selectItem}) {
       
    const arrayCountry = [...new Set(arrayJobs.map((item) => item.Country__c))].filter(item => item !== null).sort()
    const arrayCity = [...new Set(arrayJobs.map((item) => item.TR1__City__c))].filter(item => item !== null).sort()
    const arrayStateArea = [...new Set(arrayJobs.map((item) => item.TR1__State_Area__c))].filter(item => item !== null).sort()
    const arrayJoinLocation = [...arrayCountry,...arrayCity, ...arrayStateArea].sort()
    const arraylocations = [...new Set(separeArray(arrayJoinLocation).sort())];

    return (
        <Box className="jobsByLocation w100 flex row" sx={{py:2}}>
            
        {arraylocations.map((location) =>{
            return(
                <Button 
                    key={location}
                    className="itemLocation_btn"
                    sx={{p:0, m:1,}}  
                    color="secondary"   
                    onClick={() => selectItem(location)}             
                >
                    <Zoom>
                        <Chip label={location}  className="itemLocation_chip"/>
                    </Zoom>
                </Button>
            )
            })
        }   
        </Box>
    );
}
import { useContext, useEffect, useState } from 'react';
import { Autocomplete, Box, Checkbox, Chip, Input, TextField, Typography } from '@mui/material';
import './siteContentsComponents.css'
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import ContextSiteContents from '../../contexts/ContextSiteContents';

export default function ButtonEditor({data}) {
    const [hasButton, setHasButton] = useState(data?data.button.active:false)
    const [titleButton, setTitleButton] = useState(data?data.button.title:"")
    const [actionButton, setActionButton] = useState(data?data.button.action:"")
    const {generalActions} = useContext(ContextSiteContents)

    useEffect(() => {
        data.button.active = hasButton
        data.button.title = titleButton
        data.button.action = actionButton
    }, [ data, hasButton, titleButton, actionButton])

    
    return (
        <Box className="siteContents-item flex col w100 aicenter">
            <Box className="flex row w100 aicenter">
                <Typography variant="body2" component="span" sx={{mr:1}} className="Montserrat tbold">
                    Button
                </Typography>
                <Checkbox sx={{p:0}} icon={<CheckBoxOutlineBlank fontSize='small'/>} checkedIcon={< CheckBox fontSize='small'/>} checked={hasButton} onChange={(e, value)=>setHasButton(value)} />
            </Box>
            {hasButton && 
                <Box className="flex row w100 aicenter wrap">
                    <Input
                        type='text'
                        className="siteContents-input siteContents-input-buttonTitle"
                        placeholder="Title Button"
                        fullWidth
                        sx={{mr:0.5, my:0.5}}
                        disableUnderline
                        value={titleButton}
                        onChange={(e)=> {setTitleButton(e.target.value)}}
                    />

                    {generalActions && 
                        <Autocomplete
                            className="siteContents-input siteContents-input-buttonAction"
                            value={actionButton}
                            options={generalActions.map(({title}) => title)}
                            onInputChange={(event, value) => {
                                let find = generalActions.find(item => item.title === value)
                                setActionButton(find?.href || value)
                            }}
                            freeSolo
                            fullWidth
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => {
                                    return(
                                        <Chip variant="filled" label={option} {...getTagProps({ index })} />
                                    )
                                }
                                )
                            }
                            renderInput={(params) => (
                                <TextField
                                    className="w100"
                                    {...params}
                                    variant="standard"
                                    placeholder="Add url, link or action default"
                                />
                            )}
                        />
                    }
                </Box>
            }
        </Box>       
    );
}
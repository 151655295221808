import { useContext, useEffect, useState } from 'react';
import {Box, Typography, Container, Grid} from '@mui/material';
import './footer.css'
import { Mail, Phone, Place } from '@mui/icons-material';
import logoAsap from '../../assets/images/logoAsapWhite.png'
import SocialMedia from '../../socialMedia/SocialMedia';
import ModalMap from '../modalMap/ModalMap'
import { sortDescendant, stringToUrl } from '../../services/serviceslocal';
import {  NavLink, useNavigate } from 'react-router-dom';
import Context from '../../contexts/Context';
import SkeletonList from '../skeletonList/SkeletonList';
import Newsletters from '../newsletters/Newsletters';

export default function Footer() {

    const {allResources, siteContents} = useContext(Context);
    const {data: dataContacUs} = siteContents.find(item => item.id === "contact-us")
    const {subtitle: location} = dataContacUs.find(item => item.id ===  "contactLocation")
    const buttonCall = dataContacUs.find(item => item.id ===  "contactForCompany").buttons[0]
    const [arrayArticles, setArrayArticles] = useState([])
    const navigate = useNavigate()

    useEffect(() => {
      if(allResources.length !== 0){
        setArrayArticles([...new Set(allResources.filter((item) => item.active === true))])
      }
    }, [allResources])

    // location
    const [modalActiveLocation, setModalActiveLocation] = useState(false)
    const onLocation = () => {
        setModalActiveLocation(true)
    }
    // phone
    const onCall = () => {
        let phone = buttonCall.action.replace("call:","");
            window.location = `tel:${phone}`
    }

    return (

        <footer>
            <Box className="section flex col center w100" mw="mw1200" sx={{background:"var(--primary)", overflow:"hidden"}} color="text.white">
                <Container maxWidth="lg" >
                    <Grid container sx={{py:5}} className="footer_body">
                        <Grid item md={3} sm={6} xs={12} p={2}>
                            <Box sx={{p:2, pt:0}} className="flex center">
                                <img alt="ASAP Staffing Services" src={logoAsap} width={156} height={108} style={{width:"70%", maxWidth:"200px", height:"auto"}} />
                            </Box>
                            <SocialMedia footer/>
                        </Grid>
                       
                        <Grid item md={3} sm={6} xs={12} p={2}>
                            <Box borderBottom={1} sx={{mb:1}}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    // sx={{ flexGrow: 1 }}
                                >
                                    Last Articles 
                                </Typography>
                            </Box>
                            {arrayArticles.length === 0 && 
                                <SkeletonList m={1} num={4} bg="#ffffff61" h={18}/>
                            }
                            {arrayArticles.length !== 0 &&  sortDescendant(arrayArticles, "fullDate").slice(0, 4).map((item, index) => {
                                return(
                                    <Box key={item.title}>
                                        <NavLink to={`/resources/${stringToUrl(item.title)}`}>
                                            <Typography
                                                sx={{mb:1}}
                                                variant="body2"
                                                component="p"
                                                className='tl'
                                            >
                                                {item.title}
                                            </Typography>  
                                        </NavLink>
                                    </Box>
                                )
                                })
                            }
                        </Grid>
                       
                        <Grid item md={3} sm={6} xs={12} p={2}>
                            <Newsletters/>
                        </Grid>
                      
                        <Grid item md={3} sm={6} xs={12} p={2}>
                            <Box borderBottom={1} sx={{mb:1}}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    translate="no"
                                    // sx={{ flexGrow: 1 }}
                                >
                                    ASAP USA 
                                </Typography>
                            </Box>
                            <Box>
                                <Box 
                                    className="pointer flex row aicenter" 
                                    sx={{mb:1}} 
                                    onClick={() => {onCall()}}
                                >
                                    <Phone sx={{mr:1}} />
                                    <Typography
                                        variant="body2"
                                        component="span"
                                    >
                                       {buttonCall.title}
                                    </Typography>
                                </Box>
                                <ModalMap modalActive={modalActiveLocation} setModalActive={setModalActiveLocation}/>
                                <Box 
                                    className="pointer flex row aicenter" 
                                    sx={{mb:1}} 
                                    onClick={() => {onLocation()}}
                                >
                                    <Place sx={{mr:1}}/>
                                    <Typography
                                        variant="body2"
                                        component="span"
                                    >
                                        {location}
                                    </Typography>
                                </Box>
                                <Box 
                                    className="pointer flex row aicenter" 
                                    sx={{mb:1}} 
                                    onClick={() => {window.location = "mailto:contact@asapstaffingservices.com"}}
                                >
                                    <Mail sx={{mr:1}}/>
                                    <Typography
                                        variant="body2"
                                        component="span"
                                    >
                                        contact@asapstaffingservices.com
                                    </Typography>
                                </Box>
                                <Box>

                                    <Box borderBottom={1} sx={{mb:1}}>
                                        <Typography
                                            variant="h5"
                                            component="h2"
                                            translate="no"
                                            sx={{mt:4}}
                                            onClick={() => {window.open(`https://www.asap.com.ve/`, '_blank')}}
                                            className="pointer"
                                        >
                                            ASAP VEN 
                                        </Typography>
                                    </Box>
                                    {/* <Box>
                                        <Box 
                                            className="pointer flex row aicenter" 
                                            sx={{mb:1}}
                                            onClick={() => {window.location = `tel:+58 414-3323631`}}
                                        >
                                            <Phone sx={{mr:1}} />
                                            <Typography
                                                variant="body2"
                                                component="span"
                                            >
                                                +58 414-3323631
                                            </Typography>
                                        </Box>
                                        <Box 
                                            className="pointer flex row aicenter" 
                                            sx={{mb:1}} 
                                            onClick={() => {window.open(`https://www.asap.com.ve/`, '_blank')}}
                                        >
                                            <DevicesRounded sx={{mr:1}} />
                                            <Typography
                                                variant="body2"
                                                component="span"
                                            >
                                                asap.com.ve
                                            </Typography>
                                        </Box>
                                        <Box 
                                            className="pointer flex row aicenter" 
                                            sx={{mb:1}} 
                                            onClick={() => {window.location = "mailto:contacto@asap.com.ve"}}
                                        >
                                            <Mail sx={{mr:1}}/>
                                            <Typography
                                                variant="body2"
                                                component="span"
                                            >
                                                contacto@asap.com.ve
                                            </Typography>
                                        </Box>
                                    </Box> */}
                                </Box>

                            </Box>
                        </Grid>
                    </Grid>
                    <Box id='itemsfooterEnd' className="flex center wrap" textAlign="center" pt={1} pb={1}>
                        <Typography
                            variant="body2"
                            component="span"
                            sx={{mx:1, cursor:"pointer", whiteSpace:"nowrap"}}
                        >
                            {`© 2022 - ${new Date().getFullYear()} ASAP Services LLC`}
                        </Typography>
                        <span>|</span>
                        <NavLink to={`/terms-of-service`}>
                            <Typography
                                variant="body2"
                                component="span"
                                sx={{mx:1, cursor:"pointer", whiteSpace:"nowrap"}}
                            >
                                Terms of Service
                            </Typography>  
                        </NavLink>            
                        <span>|</span>
                        <a id="developerBy" href='https://hector-madariaga.web.app/' target="_blank" rel="noopener noreferrer" >
                            <Typography
                                variant="body2"
                                component="span"
                                sx={{mx:1, cursor:"pointer", whiteSpace:"nowrap"}}
                            >
                                Developed by Hector Madariaga
                            </Typography>  
                        </a>            
                    </Box>
                </Container>
            </Box>
      </footer>
    );
}
import { useContext, useState } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import './syncData.css'
import Card from '../../../components/card/Card';
import Context from '../../../contexts/Context';
import { DataUsage, PlaylistAddCheckRounded, SupervisedUserCircle, Sync } from '@mui/icons-material';
import SubBarSearch from '../../../components/subBarSearch/SubBarSearch';
import { getData } from '../../../services/getData';
import BackdropLoading from '../../../components/backdropLoading/BackdropLoading';
import { remplaceTitle } from '../../../services/serviceslocal';

export default function SyncData() {
    const {lastDateSyncUsers, setLastDateSyncUsers, lastDateSyncJobs, setLastDateSyncJobs, setTeam, setAllJobs} = useContext(Context)

    const [isLoading, setIsLoading] = useState(false)

    const syncData = async (id, path) => {
        setIsLoading(id)
        let {jobs = false, users = false} =  await getData(path)
        if(users){
            setTeam(users[0].data)
            setLastDateSyncUsers(users[0].lastSyncDate)
        }
        if(jobs){
            setAllJobs(remplaceTitle(jobs[0]?.data.filter(item => item.TR1__Client_Description__c !== null)))
            setLastDateSyncJobs(jobs[0].lastSyncDate)
        }
        setIsLoading(false)
    }

    const items = [
        {id:"users", title:"Sync Employees", icon:<SupervisedUserCircle sx={{mr:1}}/>, path:"salesforce/sync/users", fullDate:lastDateSyncUsers},
        {id:"jobs", title:"Sync Jobs", icon:<PlaylistAddCheckRounded sx={{mr:1}} />, path:"salesforce/sync/jobs", fullDate:lastDateSyncJobs},
        {id:"data", title:"Sync All (Jobs and Employees)", icon:<DataUsage sx={{mr:1}} />, path:"salesforce/sync/all"}
    ]

    return (

        <Box className="articleVisor flex col w100 aicenter" sx={{pb:2}} id="article-visor">
            <SubBarSearch
                title="My Account"
                noSearch
            />
            <Box className="containerResources flex col aicenter w100 mw900" sx={{px:1}}>
                {items.map(item => {
                    return(
                        <Card key={item.id} className="flex row center">
                            <Box className="flex row w100 center">
                                {item.icon}
                                <Box className="flex col aicenter w100">
                                    <Typography variant="body1" component="span" className="tcenter" color="dark" sx={{mr:"auto"}} >
                                        {item.title}
                                    </Typography>
                                    {item.id !== "data" &&
                                        <Typography variant="body2" component="span" className="tl" color="gray" sx={{mr:"auto"}} fontSize="12px" >
                                            Last sync date:  {item.fullDate}
                                        </Typography>
                                    }
                                </Box>
                                <Box>
                                    <IconButton onClick={async () => syncData(item.id, item.path)}>
                                        <Sync color="primary"/>
                                    </IconButton>
                                </Box>
                            </Box>
                        </Card>
                    )
                })}
            </Box>
            <BackdropLoading openBackDrop={isLoading} sync/>
        </Box>
    );
}
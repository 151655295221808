import {Box, Button, ButtonBase, Grid, Paper, Typography} from '@mui/material';
import { NavLink } from 'react-router-dom';
import './cardArticleMinList.css'
import sources from '../../assets/images/sources.jpg'
import { dateStringToObject, sortDescendant, stringToUrl } from '../../services/serviceslocal';
import { useContext, useEffect, useRef, useState } from 'react';
import Context from '../../contexts/Context';
import { DoubleArrowRounded } from '@mui/icons-material';
import SkeletonList from '../skeletonList/SkeletonList';

export default function CardArticleMinList({body}) {

    const bodyMinArticleRef = useRef(null)
    const {allResources} = useContext(Context);
    const [mediaLoading, setMediaLoading] = useState(true)

    const [arrayArticles, setArrayArticles] = useState([])
    useEffect(() => {
      if(allResources.length !== 0){
        setArrayArticles([...new Set(allResources.filter((item) => item.active === true))])
      }
    }, [allResources])
    
    useEffect(() => {
        if(bodyMinArticleRef){
            bodyMinArticleRef.current.innerHTML = body
        }
    }, [bodyMinArticleRef, body])

    return (
        <Box className="flex col aicenter w100" style={{ maxWidth:500}}>

            <Typography ref={bodyMinArticleRef} className="section_text tj" color="dark" sx={{mb:2}}>
                {body}
            </Typography>

            {arrayArticles.length === 0 && 
                <SkeletonList h={100} m={1}/>
            }
            
            {
                arrayArticles.length !== 0 && 
                sortDescendant(arrayArticles, "fullDate").slice(0, 3).map((item, index) => {
                    let {media = false, title, fullDate} = item
                    const {date, month} = dateStringToObject(fullDate)
                    return(
                        <Paper key={index} elevation={0} className="cardArticleMin" >
                            <NavLink to={`/resources/${stringToUrl(title)}`}>
                                <ButtonBase>
                                    <Grid container className="cardArticleMin_container">
                                        <Grid item xs={4}>
                                            <Box className="cardArticleMin_containerImage flex col center noSelect" >
                                                {(mediaLoading || !media) && <img className="mediaDefault" src={sources} alt={title} style={{zIndex:1}} />}
                                                {media.type ==="image" && <img src={media.url || sources} alt={title}  onLoad={({isTrusted}) => setMediaLoading(!isTrusted)} onError={({isTrusted})=> setMediaLoading(isTrusted)}/>}
                                                {media.type ==="video" && <video src={media.url} alt={title} loop autoPlay muted poster={sources} playsInline onLoadedData={({isTrusted}) => setMediaLoading(!isTrusted)} onError={({isTrusted})=> setMediaLoading(isTrusted)}/>}
                                                <Box className="flex col center cardArticleMin_date">
                                                    <Typography variant="body2" color="text.white" className="tbold"> {date} {month} </Typography>
                                                </Box>
                                            </Box>
                                            
                                        </Grid>
                                        <Grid item xs={8} className="flex col center" sx={{p:2}}>
                                            <Typography className="cardArticleMin_title w100 tl" color="black" variant="body1">
                                                {title}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </ButtonBase>
                            </NavLink>
                        </Paper>
                    )
                })
            }
            <NavLink to="/resources">
                <Button className="Montserrat" variant='text' sx={{ml:"auto"}} endIcon={<DoubleArrowRounded />}>
                    Read more articles
                </Button>
            </NavLink>
        </Box>
    );
}
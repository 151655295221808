import { useContext, useRef, useState } from 'react';
import { Avatar, Box, Button, Divider, FormControl, IconButton, Input, InputAdornment, InputLabel, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, MenuItem, Select, Switch, Typography } from '@mui/material';
import './crypto.css'
import Card from '../../../components/card/Card';
import Context from '../../../contexts/Context';
import SubBarSearch from '../../../components/subBarSearch/SubBarSearch';
import { useEffect } from 'react';
import { sendDataToAPI, sendJsonToAPI } from '../../../services/requestsToAPI';
import { Add, ContentCopy, Delete, DeleteSweep, Folder } from '@mui/icons-material';
import { fileToBase64, getDateString } from '../../../services/serviceslocal';
import uuid from 'react-uuid'
import BackdropLoading from '../../../components/backdropLoading/BackdropLoading';
import { getData } from '../../../services/getData';
import ModalAlert from '../../../components/modalAlert/ModalAlert';

export default function Crypto() {
    const {user} = useContext(Context)
    const [token, setToken] = useState('')
    const [text, setText] = useState('')
    const [textEncrypted, setTextEncrypted] = useState('')
    const [switchActive, setSwitchActive] = useState(false)


    useEffect(() => {
        setText('')
        setTextEncrypted('')
    }, [])
    
    const encrypt = async () => {
        // console.log()
        let key = token 
        if(!key){
            let res = await sendJsonToAPI("/crypto/token", {collection:"token"})
            key = res[0].token
            setToken(key)
        }
        // console.log({text:text, token:key})
        let response = await sendJsonToAPI(`/crypto/${!switchActive?"encrypt":"decrypt"}`, {text:text, token:key} )
        // console.log(response)
        setTextEncrypted(response)
    }


    return (

        <Box className="flex col w100 aicenter" sx={{pb:2}} id="crypto">
            <SubBarSearch
                title="Files"
                noSearch
            />
            <Box className="containerResources flex col aicenter w100 mw900" sx={{px:1}}>
                <Card className="flex col center wrap">

                    <Switch 
                        className="card-switch" 
                        label="checkACardrticle" 
                        size="small"
                        checked={switchActive} 
                        onChange={() => setSwitchActive(!switchActive)}
                        sx={{mr:"auto", mb:2}}
                    />

                    <Box className="w100 flex col center">
                        <Input
                            type='text'
                            className="siteContents-input"
                            placeholder={!switchActive?"Encrypt":"Decrypt"}
                            size='lg'
                            fullWidth
                            disableUnderline
                            value={text}
                            onChange={(e)=> {setText(e.target.value)}}
                            endAdornment={
                                <InputAdornment position="end">
                                    <DeleteSweep/>
                                </InputAdornment>
                            }
                        />
                            <Button 
                                sx={{ml:"auto", mt:3, borderRadius:"50px", pl:"24px", minWidth:"150px"}}
                                onClick={()=>encrypt()}
                                disabled={text === ''}
                                variant="contained"
                            >
                                {!switchActive?"Encrypt":"Decrypt"}
                            </Button>
                    </Box>
                </Card>

                {textEncrypted && 
                    <Card className="flex col center">
                        <Box className="w100 flex col">
                            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} className="Montserrat">
                                Text  {!switchActive?"Encrypt":"Decrypt"}
                            </Typography>
                            <Box sx={{mt:1}} className="w100 flex col jccenter">
                                <Typography  variant="body1" component="span" className="tl w100" color="dark" sx={{ml:2}} >
                                    {textEncrypted}
                                </Typography>
                                <IconButton onClick={() => navigator.clipboard.writeText(textEncrypted)} aria-label="Copy Url" sx={{ml:"auto"}}><ContentCopy/> </IconButton>
                            </Box>
                        </Box>
                    </Card>
                }

            </Box>

        </Box>
    );
}
import axios from 'axios'

// const urlSingle = "http://192.168.200.5:5010"
// const urlSingle = "https://asap-staffing-services.herokuapp.com"
//const urlSingle = "https://asap-staffing-services.onrender.com"
const urlSingle = "https://asap-api-a40y.onrender.com"

//formating message 
export const sendDataToAPI = async (param, data) => {
    let bodyFormData = new FormData();
    for (const property in data) {
        bodyFormData.append(`${property}`, data[property]);
    }
    const resData = await sendMailToAPI(param, bodyFormData)
    return(resData)
    //disable send Menssage
    // return("message send")
}

//send message to API
export const sendMailToAPI = async (param, bodyFormData) => {
    let url = `${urlSingle}${param}`;
    let resData = await axios({
        method: "post",
        url: url,
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
            return(response.data);
        })
        .catch(function (response) {
          return(response);
        });
    return(resData)
}

export const sendJsonToAPI = async (param, data) => {
  let url = `${urlSingle}${param}`;
  // const json = JSON.stringify({ data});
  const res = await axios.post(url, data, {
    headers: {'Content-Type': 'application/json'}
    })
    .then(function (response) {
        return(response.data);
    })
    .catch(function (response) {
      return(response);
    });
  return(res)
}

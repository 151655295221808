import { Box, Typography } from '@mui/material';
import './siteContentsComponents.css'
import Card from '../card/Card';
import MediaEditorFromArray from './MediaEditorFromArray';

export default function SectionFeaturedIconsEditor({data}) {
    
    return (
        <Card className="flex row center">
            <Box className="siteContents-cardSection flex w100 aicenter">
                <Box className="flex row w100 aicenter">                      
                    <Typography variant="h6" component="span" className="Montserrat w100">
                        {data.title}
                    </Typography>
                </Box>
                <Box className="flex col center w100">
                    {data.medias.map((item, index) => <MediaEditorFromArray key={index} dataParent={data} dataMedia={item} sxMedia/>)}
                </Box>
            </Box>
        </Card>        
    );
}
import { useContext, useState } from 'react';
import {Box, CardActions, CardContent, CardMedia, IconButton, Stack, Switch, Typography} from '@mui/material';
import './cardArticleResponsive.css'
import { Delete, Edit, Visibility } from '@mui/icons-material';
import Card from '../card/Card';
import Context from '../../contexts/Context';
import { dateStringToObject, stringToUrl } from '../../services/serviceslocal';
import sources from '../../assets/images/sources.jpg'
import { sendJsonToAPI } from '../../services/requestsToAPI';
import ModalAlert from '../modalAlert/ModalAlert';
import { getData } from '../../services/getData';

export default function CardArticleResponsive({article, cms}) {

    const {navigateTo, setAllResources} = useContext(Context);
    // const {date, title, image, body, postedBy, arraySourceLink} = article 
    const {id, active, title, media=false, fullDate} = article 
    const [mediaLoading, setMediaLoading] = useState(true)

    const {date, month, year} = dateStringToObject(fullDate)

    const [articleActive, setArticleActive] = useState(active)
    const [modalDeleteActive, setModalDeleteActive] = useState(false)

    const handleChecked = async ({target}) => {
        let response = await sendJsonToAPI("/resources/activeArticle", {id: id, active: target.checked})
        setArticleActive(response)
    }
    
    return (
        <Card className="cardArticleResponsive col w100" sx={{mt:2}} responsive button={!cms} onClick={() =>navigateTo(stringToUrl(title))}>
            {(mediaLoading || !media) && 
                <Box className="mediaDefault flex center noSelect">
                    <img src={sources} alt={title} />
                </Box>
            }
            {(!media || media.type === "image") &&
                <CardMedia
                    component="img"
                    alt={title}
                    height="140"
                    image={media.url || sources}
                    onLoad={({isTrusted}) => setMediaLoading(!isTrusted)}
                    onError={({isTrusted})=> setMediaLoading(isTrusted)}
                />
            }
            {media.type === "video" &&
                <Box sx={{h:"140px"}} className="cardArticleResponsive-video flex center">
                    <video src={media.url} alt={title} className="imageArticle_image" loop autoPlay muted poster={sources} playsInline  onLoadedData={({isTrusted}) => setMediaLoading(!isTrusted)} onError={({isTrusted})=> setMediaLoading(isTrusted)}/>
                </Box>
            }
            <CardContent className="w100" sx={{position:"relative"}}>
                <Box className="flex col center container_date">
                    <Typography variant="body2" color="text.white" className="tbold"> {month} {date} {year} </Typography>
                </Box>
                {/* <Typography variant="body2" color="primary" className="tbold tl w100"> 5 Oct </Typography> */}
               <Typography gutterBottom variant="h5" component="h2" className="tl w100">
                    {title}
                </Typography>
            </CardContent>
            {cms && 
                <CardActions className="w100">
                    <Switch 
                        className="card-switch" 
                        label="activo" 
                        size="small"
                        sx={{marginRight:"auto"}}
                        checked={articleActive} 
                        onChange={handleChecked}
                    />
                    <Box className="actionCard flex center">
                        <Stack
                            direction='row'
                            spacing={{ xs: 1, md: 1 }}
                        >
                            <IconButton onClick={() =>setModalDeleteActive(true)}><Delete /></IconButton>
                            <IconButton onClick={() =>navigateTo("article/" + stringToUrl(title))}><Visibility/></IconButton>
                            <IconButton onClick={() =>navigateTo("article-editor/" + stringToUrl(title))}><Edit/></IconButton>
                        </Stack>
                    </Box>
                    <ModalAlert 
                        modalActive={modalDeleteActive} 
                        setModalActive={setModalDeleteActive}
                        title="Delete Article"
                        textCancel="Cancel"
                        textAction="Yes, Delete"
                        maxWidth="sm"
                        onClick={async() => {
                            await sendJsonToAPI("/resources/deleteArticle", {id:article.id})
                            getData("resources").then(resources => setAllResources(resources));
                        }}
                    >
                        <Typography> Are you sure you want to delete: "{title}"?</Typography>
                    </ModalAlert>
                </CardActions>
            }

        </Card>
    );
}
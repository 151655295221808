import { useEffect, useState } from 'react';
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import { DeleteSweep, Menu } from '@mui/icons-material';
import { Box, IconButton, Input, InputAdornment } from '@mui/material';

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
        width: '20ch',
        },
    },
}));

export default function SubBarSearch({title, placeholder, getParameters, arrayParam, noSearch}) {

    const [value, setValue] = useState("")


    useEffect(() => {
        if(arrayParam && arrayParam.length === 0){
          setValue("")
        }
      }, [arrayParam])
    
    useEffect(() => {
        if(!noSearch){
            const noNull = value.split(" ").filter(item => item !== "")
            const arrayParameters = [...new Set([...noNull])]
            getParameters(arrayParameters)
        }
    }, [value, noSearch])


    const handleSearch = (e) => {
        e.preventDefault()
        const noNull = value.split(" ").filter(item => item !== "")
        const arrayParameters = [...new Set([...noNull])]
        getParameters(arrayParameters)
    }
    const handleReset = () => {
        setValue("")
        getParameters([])
    }

    

    return (
        <AppBar position="static">
            <Toolbar>
                <IconButton
                    onClick={()=>{document.getElementsByClassName("menuAside")[0].classList.add("menuAside_active");}} 
                    className="menuAside_btn"
                    sx={{ml:"auto"}}
                    color="white"
                >
                    <Menu/>
                </IconButton>     
            <Typography
                variant="h6"
                noWrap
                component="div"
                // sx={{ display: { xs: 'none', sm: 'block' } }}
                sx={{ flexGrow: 1 }}
            >
                {title}
            </Typography>
            {!noSearch &&
                <Box className="barSearch">
                    <form onSubmit={handleSearch}>
                        <Input
                            type='text'
                            className="barSearchInput"
                            value={value}
                            placeholder={placeholder}
                            fullWidth
                            disableUnderline
                            onChange={(e)=> {setValue(e.target.value)}}
                            sx={{pl:value?0:"10px"}}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton onClick={handleSearch} color="primary">
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                            }
                            startAdornment={value &&
                                <InputAdornment position="start">
                                    <IconButton 
                                    onClick={() => handleReset()}
                                    >
                                        <DeleteSweep />
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </form>

                </Box>
            }
            {/* <Search>
                <SearchIconWrapper>
                <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                placeholder={placeholder}
                inputProps={{ 'aria-label': 'search' }}
                onChange={(e)=> {setValue(e.target.value)}}
                />
            </Search> */}
            </Toolbar>
        </AppBar>
    );
}
